import { AuthLoginResponse, LoginData, RenewTokenData } from '../types/User';
import { ApiResponse, axiosAuth, axiosCatch } from '../../utils/AxiosHelper';

export const reqAuthLogin = async (loginData: LoginData): Promise<ApiResponse<AuthLoginResponse>> => {
  return await axiosAuth().post('/auth/login', loginData).catch(axiosCatch);
};

export const reqAuthRenewToken = async (renewTokenData: RenewTokenData) => {
  return await axiosAuth().post('/auth/refresh-tokens', renewTokenData).catch(axiosCatch);
};
