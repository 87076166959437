import {
  COURSE_ADD_SUBJECT_CACHE_SUCCESS, COURSE_CHANGE_VERSION, COURSE_DELETE,
  COURSE_STATE
} from '../actions/types';
import { ECourseType, ILessonGroupItem, ILessonItem, ISubjectItem } from '../types/Mentor';

export interface ICourseState {
  locale: string;
  loading: boolean;
  acLoad: boolean;
  error: boolean,
  actionLoad?: boolean,
  basicList: any;
  necessaryList: any,
  allList: any;
  subject?: ISubjectItem | null,
  group?: ILessonGroupItem | null,
  lesson?: ILessonItem
  courseDetail: any;
  lessonGroup: any;
}

const initialState = {
  locale: 'vi',
  loading: false,
  acLoad: false,
  error: false,
  basicList: [],
  necessaryList: [],
  subject: null,
  group: null,
  courseDetail: null,
  lessonGroup: null,
  allList: []
};
export const courseReducer = (state = initialState, action: any): ICourseState => {
  switch (action.type) {
    case COURSE_ADD_SUBJECT_CACHE_SUCCESS: {
      // @ts-ignore
      const detail = { ...state.courseDetail, listSubject: [...state.courseDetail.listSubject, action.payload] };
      return { ...state, courseDetail: detail };
    }
    case COURSE_CHANGE_VERSION: {
      const { data, type, nodeIndex } = action.payload;
      const courseList = type === ECourseType.basic ? 'basicList' : 'necessaryList';
      let newList: any = [...state[courseList]];
      newList[nodeIndex] = data;
      return { ...state, [courseList]: newList };
    }
    case COURSE_STATE:
      return { ...state, ...action.payload, error: false };
    default:
      return { ...state };
  }
};
export default courseReducer;
