import { Button, Form, Input, Modal, Table, Typography, Select } from 'antd';
import Header from '../../components/Header';
import InjectMessage from '../../../../utils/InjectMessage';
import { useCallback, useEffect, useState } from 'react';
import { COURSE, MANAGE_MENTOR } from '../../../../redux/actions/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers';
import Icon, { IconName } from '../../components/Icon';

const Mentor = () => {
  const dispatch = useDispatch();
  const detailMentor = useSelector((app: AppState) => app.manageMentor.detailMentor);
  const listMentor = useSelector((app: AppState) => app.manageMentor.listMentor);
  const [type, setType] = useState('create');
  const [form] = Form.useForm();
  const [isSupport, setIsSupport] = useState(true)
  const [isEditSubject, setIsEditSubject] = useState(false)
  const { loading } = useSelector((app: AppState) => app.auth);
  const [listSubject, setListSubject] = useState<any>([]);
  const { allList } = useSelector((app: AppState) => app.course);
  useEffect(() => {
    form.setFieldsValue({ mentorPermission: "mentor-support" })
    dispatch(COURSE.GET_LIST());
  }, [])
  useEffect(() => {
    setListSubject(allList.map((item: any) => {
      return {
        value: item.id,
        label: item.title
      }
    }))
  }, [allList])
  const handleChangeSubject = (value: any) => {
    // (value == 1) ? setIsSupport(true) : setIsSupport(false)
    setIsEditSubject(true)
    // form.setFieldsValue({ ...detailMentor, subject: value.toString() })

  };
  useEffect(() => {
    dispatch(MANAGE_MENTOR.GET_ACCOUNT(null));
  }, []);

  const handleChange = (value: any) => {
    (value == "mentor-support") ? setIsSupport(true) : setIsSupport(false)
  };
  useEffect(() => {
    if (type == 'update') {
      if (detailMentor && Object.keys(detailMentor).length !== 0 && detailMentor.constructor === Object) {
        const isMentorSupport = detailMentor.mentorPermission === "mentor-support";
        setIsSupport(isMentorSupport);
        const matchingItems = listSubject.filter((item: any) => {
          return detailMentor.courseSupports.includes(item.label);
        });
        form.setFieldsValue({ ...detailMentor, manHour: detailMentor.manHour.toString(), courseSupports: matchingItems });
      }
    }
  }, [type, detailMentor, listSubject, form]);

  const onFinish = useCallback((data: any) => {
    if (type == 'update') {
      const editData = ({ ...data, courseSupports: isSupport ? (isEditSubject ? data.courseSupports : data.courseSupports.map((item: any) => item.value)) : [] })      
      Modal.confirm({
        title: 'XÁC NHẬN',
        content: 'Xác nhận sửa tài khoản ?',
        onOk: () => {
          setType('create');
          form.resetFields();
          dispatch(MANAGE_MENTOR.EDIT_ACCOUNT(editData))
        }
      });
    }
    else {
      const createData = { ...data, courseSupports: isSupport ? data.courseSupports : [] }
      Modal.confirm({
        title: 'Xác nhận tạo tài khoản',
        onOk: () => {
          dispatch(MANAGE_MENTOR.CREATE_ACCOUNT(createData))
          form.resetFields();
        }
      });
    }
  }, [type, isEditSubject, isSupport]);

  return <div className='h-sidebar'>

    <Header titleRight={<h3 className='text-3xl font-bold'>Danh sách</h3>}
      title={type == 'create' ? <h3 className='text-3xl font-bold'>Tạo tài khoản Mentor</h3> :
        <div className='flex flex-row'>
          {/* <Button onClick={() => setType('create')} className='bold bg-yellow' type='primary'>Quay lại</Button> */}
          <div onClick={() => {
            setType('create');
            form.resetFields();
          }}>
            <Icon classIcon='mr-1' showMask={false} width={24} height={24} name={IconName.arrowLeft} />
          </div>
          <h3 className='text-3xl font-bold'>Sửa tài khoản Mentor</h3>
        </div>
      } />
    <div className='grid gap-4 grid-cols-7'>
      <div className='col-span-3'>
        <div className='bg-white p-4 rounded-2xl'>
          <Form form={form} onFinish={onFinish} size='large' labelCol={{ sm: 8 }} labelAlign='left'>
            <Form.Item
              name='emailLogin'
              rules={[
                { required: true, validateTrigger: 'onFinish', message: 'Email Beva bắt buộc' },
                {
                  type: 'email',
                  validateTrigger: 'onFinish',
                  message: 'Email không đúng định dạng'
                },
                {
                  validateTrigger: 'onFinish',
                  validator: async (rule: any, value: any) => {
                    if (value) {
                      if (!(value.endsWith("@beva.edu.vn") || value.endsWith("@beva.training"))) {
                        throw new Error('Mail Beva không hợp lệ!');
                      }
                    }
                  }
                }
              ]}
              label='Email Beva'>
              <Input placeholder='Email Beva' />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, validateTrigger: 'onFinish', message: 'Tên bắt buộc' }
              ]}
              name='name'
              label={"Tên Mentor"}
            >
              <Input allowClear
                placeholder={"Tên Mentor"} />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              name='mentorPermission'
              label={<InjectMessage id='admin.mentor.role' />}
              rules={[
                {
                  required: true,
                  // transform: (value) => value && value.toString(),
                  validateTrigger: 'onFinish',
                  message: <InjectMessage id='admin.mentor.role_required' />
                },
              ]}
            >
              <Select
                onChange={handleChange}
                options={[
                  { value: "mentor-support", label: "Mentor Support" },
                  { value: "mentor-course", label: "Mentor Giáo Trình" },
                ]}
              />
            </Form.Item>
            {isSupport && <Form.Item
              labelAlign="left"
              rules={[
                {
                  required: isSupport,
                  // transform: (value) => value && value.toString(),
                  validateTrigger: 'onFinish',
                  type: 'array',
                  message: <InjectMessage id='admin.mentor.subject_required' />
                },
              ]}
              name='courseSupports'
              // initialValue={}
              label={<InjectMessage id='admin.mentor.subject' />}
            >
              <Select
                mode="multiple"
                onChange={handleChangeSubject}
                options={listSubject}
                placeholder={"Chuyên ngành"}

              />
            </Form.Item>}
            <Form.Item
              name='emailRegister'
              rules={[
                { required: true, validateTrigger: 'onFinish', message: 'Email Beva bắt buộc' },
                { type: 'email', validateTrigger: 'onFinish', message: 'Email không đúng định dạng' }
              ]}
              label='Email cá nhân'>
              <Input placeholder='Email cá nhân' />
            </Form.Item>
            {type == 'update' ?
              <Form.Item
                name='id'
                hidden
              >
              </Form.Item>
              : <Form.Item
                rules={[
                  { required: true, validateTrigger: 'onFinish', message: <InjectMessage id='auth.password.required' /> },
                  { min: 8, validateTrigger: 'onFinish', message: <InjectMessage id='auth.password.atLeast8Chars' /> }
                ]}
                name='password'
                label={<InjectMessage id='auth.password' />}
              >
                <Input.Password
                  autoComplete='new-password'
                  placeholder={"Mật khẩu"} />
              </Form.Item>
            }
            <Form.Item
              labelAlign="left"
              rules={[
                { required: true, validateTrigger: 'onFinish', message: <InjectMessage id='admin.mentor.bank_account_required' /> },
                {
                  validateTrigger: 'onFinish',
                  pattern: new RegExp(/^[0-9]+$/),
                  message: <InjectMessage id='admin.mentor.bank_account_invalid' />,
                },
              ]}
              name='bankAccount'
              label={"STK ngân hàng"}
            >
              <Input allowClear
                placeholder={"STK ngân hàng"}
              />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              name='bankName'
              rules={[
                { required: true, validateTrigger: 'onFinish', message: <InjectMessage id='admin.mentor.bank_name_required' /> },
              ]}
              label={<InjectMessage id='admin.mentor.bank_name' />}
            >
              <Input allowClear
                placeholder={"Tên ngân hàng"}
              />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              name='citizenIdentificationCard'
              rules={[
                { required: true, validateTrigger: 'onFinish', message: <InjectMessage id='admin.mentor.cccd_required' /> },
                {
                  validateTrigger: 'onFinish',
                  pattern: new RegExp(/^[0-9]+$/),
                  message: <InjectMessage id='admin.mentor.cccd_invalid' />,
                },
              ]}
              label={<InjectMessage id='admin.mentor.cccd' />}
            >
              <Input allowClear
                placeholder={"Số CCCD"}
              />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              rules={[
                { required: true, validateTrigger: 'onFinish', message: <InjectMessage id='admin.mentor.address_required' /> },
              ]}
              name='address'
              label={<InjectMessage id='admin.mentor.address' />}
            >
              <Input allowClear
                placeholder={"Địa chỉ"}
              />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              rules={[
                {
                  required: true,
                  validateTrigger: 'onFinish',
                  message: <InjectMessage id='admin.mentor.salary_per_hour_required' />
                },
              ]}
              name='manHour'
              label={<InjectMessage id='admin.mentor.salary_per_hour' />}
            >
              <Input allowClear
                placeholder={"Lương theo giờ"}
              />
            </Form.Item>
            <div className='text-end'>
              <Button htmlType='submit' type='primary'>{type == 'update' ? "Sửa tài khoản" : "Tạo tài khoản"}</Button>
            </div>
          </Form>
        </div>
      </div>
      <div className='col-span-4'>
        <div className='bg-white p-4 rounded-2xl'>
          <Table
            pagination={{
              pageSize: 8
            }}
            dataSource={listMentor.list}
            columns={[
              {
                title: "Tên",
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: "Email",
                dataIndex: 'emailLogin',
                key: 'emailLogin'
              },
              {
                title: 'Action', key: 'action',
                render: (text, record, index) => {
                  return <div>
                    <Button
                      style={{ marginRight: 8 }}
                      type="primary"
                      onClick={() => {
                        setType('update');
                        dispatch(MANAGE_MENTOR.DETAIL_ACCOUNT({ userId: record.id }))
                      }}
                    >
                      Sửa
                    </Button>
                    <Button
                      style={{ marginRight: 8 }}
                      danger
                      onClick={(e) => {
                        e.preventDefault();
                        Modal.confirm({
                          title: 'Xác nhận xoá tài khoản',
                          onOk: () => {
                            dispatch(MANAGE_MENTOR.DELETE_ACCOUNT({ id: record.id }))
                          }
                        });
                      }}
                    >
                      Xoá
                    </Button>
                  </div>
                },
              }
            ]}
          />
        </div>
      </div>
    </div>
  </div>;
};
export default Mentor;
