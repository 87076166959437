import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, COURSE, PATH } from '../../actions/actionTypes';
import { message } from 'antd';
import {
  CAREER_ADD_COURSE,
  CAREER_ASSIGN,
  CAREER_CREATE,
  CAREER_INFO,
  CAREER_LIST, CAREER_LIST_ASSIGN,
  COURSE_ADD,
  COURSE_ADD_LESSON,
  COURSE_GET_DETAIL,
  COURSE_GET_LIST,
  COURSE_PUBLISH
} from '../../actions/types';
import {
  reqCourseAdd,
  reqCourseInfo,
  reqCourseList, reqCourseVersionCreate, reqCourseVersionInfo,
  reqCourseVersionList,
  reqLessonAdd
} from '../../services/v2/course';
import { ApiResponse } from '../../../utils/AxiosHelper';
import { remakeCourse } from '../../../utils/hepler';
import { AxiosResponse } from 'axios/index';
import {
  reqCareerPathAddCourse, reqCareerPathAssignStd,
  reqCareerPathCreate,
  reqCareerPathInfo,
  reqCareerPathList,
  reqCareerPathListAssign
} from '../../services/v2/career-path';


function* getPathList({ payload }: ActionType) {
  try {
    yield put(PATH.SET_STATE({ loading: true }));
    const response: AxiosResponse<any> = yield call(reqCareerPathList, payload);
    yield put(PATH.SET_STATE({ list: response.data.data }));
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(PATH.SET_STATE({ loading: false }));
  }
}

function* getPathListAssign({ payload }: ActionType) {
  try {
    yield put(PATH.SET_STATE({ loading: true }));
    const response: AxiosResponse = yield call(reqCareerPathListAssign, payload);
    yield put(PATH.SET_STATE({ listAssign: response.data.data }));
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(PATH.SET_STATE({ loading: false }));
  }
}

function* getPathInfo({ payload }: ActionType) {
  try {
    yield put(PATH.SET_STATE({ loading: true }));
    const response: AxiosResponse<any> = yield call(reqCareerPathInfo, payload);
    yield put(PATH.SET_STATE({ info: response.data.data }));
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(PATH.SET_STATE({ loading: false }));
  }
}

function* onPathAddCourse({ payload }: ActionType) {
  try {
    yield put(PATH.SET_STATE({ acLoad: true }));
    yield call(reqCareerPathAddCourse, payload);
    yield put(PATH.GET_INFO({ id: payload.id }));
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(PATH.SET_STATE({ acLoad: false }));
  }
}

function* onPathAssign({ payload }: ActionType) {
  try {
    yield put(PATH.SET_STATE({ acLoad: true }));
    yield call(reqCareerPathAssignStd, payload);
    yield put(PATH.GET_LIST());
    message.success('Thêm học viên thành công!');
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(PATH.SET_STATE({ acLoad: false }));
  }
}

function* onPathCreate({ payload }: ActionType) {
  try {
    yield put(PATH.SET_STATE({ acLoad: true }));
    yield call(reqCareerPathCreate, payload);
    yield put(PATH.GET_LIST());
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(PATH.SET_STATE({ acLoad: false }));
  }
}

export default function* rootSagas() {
  yield all([
    takeLatest(CAREER_LIST, getPathList),
    takeLatest(CAREER_LIST_ASSIGN, getPathListAssign),
    takeLatest(CAREER_INFO, getPathInfo),
    takeLatest(CAREER_CREATE, onPathCreate),
    takeLatest(CAREER_ASSIGN, onPathAssign),
    takeLatest(CAREER_ADD_COURSE, onPathAddCourse)
  ]);
};
