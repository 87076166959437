import { ActionType } from '../actions/actionTypes';
import { CAREER_STATE } from '../actions/types';

export type TCourseStructure = {
  id: string;
  title: string;
  version: string;
  course_id: string;
}
export type TCareerPath = {
  id: string;
  title: string;
  description: string;
  term: string;
  author_id: string;
  is_publish: boolean;
  course_structure: null | TCourseStructure[]
}
export type TCareerPathState = {
  list: TCareerPath[];
  listAssign: any;
  info: TCareerPath | null,
  loading: boolean,
  acLoad: boolean
}
const INITIAL_STATE: TCareerPathState = {
  loading: false,
  acLoad: false,
  list: [],
  info: null,
  listAssign: []
};

const careerPathReducer = (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case CAREER_STATE :
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
export default careerPathReducer;
