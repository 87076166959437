import React, { FC } from 'react';
import { Form, Input as InputAnt } from 'antd';
import styles from './modules/input.module.scss';
import type { FormItemProps } from 'antd';

interface TProps extends FormItemProps {
  name: string,
  label?: string,
  placeholder?: string
}

const Input: FC<TProps> = ({ name, label, placeholder, ...props }) => {
  return <div className={`${styles.inputGroup} px-6 pt-3.5 mt-6 w-full group aspect-auto rounded-2xl border border-slate-200`}>
    <Form.Item
      {...props}
      label={label}
      name={name}>
      <InputAnt placeholder={placeholder} className={`${styles.input} border-none`} />
    </Form.Item>
  </div>;
};
export default Input;
