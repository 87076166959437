import {
  COMMON_SET_STATE,
  MENTOR_STATE_SUCCESS

} from '../actions/types';

export interface IMentorState {
  locale: string;
  loading: boolean;
  actionLoad?: boolean,
  chatList: any;
  testList: any,
  supportInfo: any,
  workSchedule: any
}

const initialState = {
  locale: 'vi',
  loading: false,
  chatList: [],
  testList: [],
  supportInfo: null,
  workSchedule: null
};
export const mentorReducer = (state = initialState, action: any): IMentorState => {
  switch (action.type) {
    case MENTOR_STATE_SUCCESS:
    case COMMON_SET_STATE:
      return { ...state, loading: false, actionLoad: false, ...action.payload };
    default:
      return state;
  }
};
export default mentorReducer;
