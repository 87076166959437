import React, { useEffect, useState } from 'react';
import { IRoute, IRouteRole } from '../../../redux/route/routers';
import routers, { publicRoutes } from './routers';
import { Route, Switch } from 'react-router-dom';
import PageNotFound from '../PageNotFound';
import ServiceUnavailable from '../ServiceUnavailable';

const ContentRoute = () => {

  const [routeList, setRouteList] = useState<IRoute[]>([]);
  const roles = JSON.parse((localStorage.getItem('roles')!));

  useEffect(() => {
    const uniqueRole = [...new Set(roles.flat())];
    const typeLogin = localStorage.getItem('typeLogin') as keyof IRouteRole;
    const routes = routers[typeLogin];
    const publicRoute = publicRoutes[typeLogin];
    const list = routes && routes.reduce((initial: IRoute[], item: IRoute) => (uniqueRole.includes(item.path) ? [...initial, item] : initial), routeList);
    setRouteList([...list ?? [], ...publicRoute ?? []]);
  }, []);

  return <Switch>
    {routeList && routeList.map((value: any, index: number) => {
      return !value.items ?
        <Route
          exact={value.exact}
          key={`ridx_${index}`}
          path={`${value.path}`}
          component={value.component}
        />
        :
        value.items.map((item: any, key: number) => <Route
          exact={item.exact}
          key={`ridxxx_${index}_${key}`}
          path={`${item.path}`}
          component={item.component}
        />);
    })}
    <Route path='/service-unavailable' component={ServiceUnavailable} />
    <Route component={PageNotFound} />
  </Switch>;
};
export default ContentRoute;
