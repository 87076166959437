import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import commonReducer, { ICommonState } from './Common';
import authReducer, { IAuhtState } from './Auth';
import { RootStateOrAny } from 'react-redux';
import manageStudentReducer, { IManageAccountState } from './ManageStudent';
import manageAdminReducer, { IManageAdmintState } from './ManageAdmin';
import manageMentorReducer, { IManageMentorState } from './ManageMentor';

import { IMentorState, mentorReducer } from './Mentor';
import courseReducer, { ICourseState } from './Course';
import studentReducer, { IStdState } from './Student';
import quizReducer, { IQuizState } from './Quiz';
import scheduleReducer, { ISchedule } from './Schedule';
import careerPathReducer, { TCareerPathState } from './CareerPath';

interface IAppState {
  auth: IAuhtState;
  router: any;
  common: ICommonState;
  manageStudent: IManageAccountState;
  manageAdmin: IManageAdmintState;
  manageMentor: IManageMentorState;
  mentor: IMentorState;
  course: ICourseState;
  student: IStdState;
  quiz: IQuizState;
  schedule: ISchedule;
  careerPath: TCareerPathState;
}

export const rootReducer = (history: any) => combineReducers<IAppState>({
  router: connectRouter(history),
  common: commonReducer,
  auth: authReducer,
  manageStudent: manageStudentReducer,
  manageAdmin: manageAdminReducer,
  manageMentor: manageMentorReducer,
  mentor: mentorReducer,
  course: courseReducer,
  student: studentReducer,
  quiz: quizReducer,
  schedule: scheduleReducer,
  careerPath: careerPathReducer
});
export type AppState = RootStateOrAny;
export default rootReducer;
