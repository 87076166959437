import { ActionType, COMMON, MENTOR } from '../actions/actionTypes';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { CHAT_LIST_GET, TEST_LIST_GET } from '../actions/types';
import { reqGetChatList, reqGetTestList } from '../services/mentor';
import { ChatListResponse, TestScheduleResponse } from '../types/Mentor';
import { message } from 'antd';

function* getChatList({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ChatListResponse = yield call(reqGetChatList);
    yield put(MENTOR.MENTOR_STATE_SUCCESS({
      chatList: response.data
    }));
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* getScheduleList({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: TestScheduleResponse = yield call(reqGetTestList);
    yield put(MENTOR.MENTOR_STATE_SUCCESS({
      testList: response.data
    }));
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(CHAT_LIST_GET, getChatList),
    takeLatest(TEST_LIST_GET, getScheduleList)
  ]);
}
