import { Button, Form, Input, Modal, Switch, Table, Typography } from 'antd';
import Header from '../../components/Header';
import InjectMessage from '../../../../utils/InjectMessage';
import { useCallback, useEffect, useState } from 'react';
import { MANAGE_STUDENT } from '../../../../redux/actions/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers';
import Icon, { IconName } from '../../components/Icon';
import { TablePaginationConfig } from 'antd/lib';

const Students = () => {
  const dispatch = useDispatch();
  const detailStudent = useSelector((app: AppState) => app.manageStudent.detailStudent);
  const { listStudent } = useSelector((app: AppState) => app.manageStudent);
  const [type, setType] = useState('create');
  const [form] = Form.useForm();
  const changePage = useCallback((params = {}) => {
    dispatch(MANAGE_STUDENT.GET_ACCOUNT(params));
  }, []);

  useEffect(() => {
    if (type == 'update') {
      if (detailStudent && Object.keys(detailStudent).length !== 0 && detailStudent.constructor === Object) {
        form.setFieldsValue({
          // avatar: detailStudent.avatar, 
          emailLogin: detailStudent.emailLogin,
          name: detailStudent.name,
          emailRegister: detailStudent.emailRegister,
          id: detailStudent.id
        })
      }
    }
  }), [type, detailStudent];



  const onFinish = useCallback((data: any) => {
    if (type == 'update') {
      Modal.confirm({
        title: 'XÁC NHẬN',
        content: 'Xác nhận sửa tài khoản ?',
        onOk: () => {
          setType('create');
          form.resetFields();
          dispatch(MANAGE_STUDENT.EDIT_ACCOUNT(data))
        }
      });
    }
    else {
      Modal.confirm({
        title: 'Xác nhận tạo tài khoản',
        onOk: () => {
          dispatch(MANAGE_STUDENT.CREATE_ACCOUNT(data));
          form.resetFields();
        }
      });
    }
  }, [type]);

  useEffect(() => {
    changePage();
  }, []);
  return <div className='h-sidebar'>

    <Header titleRight={<h3 className='text-3xl font-bold'>Danh sách</h3>}
      title={type == 'create' ? <h3 className='text-3xl font-bold'>Tạo tài khoản Sinh viên</h3> :
        <div className='flex flex-row'>
          {/* <Button onClick={() => setType('create')} className='bold bg-yellow' type='primary'>Quay lại</Button> */}
          <div onClick={() => {
            setType('create');
            form.resetFields();
          }}>
            <Icon classIcon='mr-1' showMask={false} width={24} height={24} name={IconName.arrowLeft} />
          </div>
          <h3 className='text-3xl font-bold'>Sửa tài khoản Sinh viên</h3>
        </div>
      } />
    <div className='grid gap-4 grid-cols-7'>
      <div className='col-span-3'>
        <div className='bg-white p-4 rounded-2xl'>
          <Form form={form} onFinish={onFinish} size='large' labelCol={{ sm: 8 }} labelAlign='left'>
            <Form.Item
              name='emailRegister'
              rules={[
                { required: true, validateTrigger: 'onFinish', message: 'Email Beva bắt buộc' },
                { type: 'email', validateTrigger: 'onFinish', message: 'Email không đúng định dạng' }
              ]}
              label='Email cá nhân'>
              <Input placeholder='Email cá nhân' />
            </Form.Item>
            <Form.Item
              name='emailLogin'
              rules={[
                { required: true, validateTrigger: 'onFinish', message: 'Email Beva bắt buộc' },
                {
                  type: 'email',
                  validateTrigger: 'onFinish',
                  message: 'Email không đúng định dạng'
                },
                {
                  validateTrigger: 'onFinish',
                  validator: async (rule: any, value: any) => {
                    if (value) {
                      if (!(value.endsWith("@beva.edu.vn") || value.endsWith("@beva.training"))) {
                        throw new Error('Mail Beva không hợp lệ!');
                      }
                    }
                  }
                }
              ]}
              label='Email Beva'>
              <Input placeholder='Email Beva' />
            </Form.Item>
            <Form.Item
              rules={[
                { required: true, validateTrigger: 'onFinish', message: 'Tên bắt buộc' }
              ]}
              name='name'
              label={"Tên sinh viên"}
            >
              <Input allowClear
                placeholder={"Tên sinh viên"} />
            </Form.Item>
            {type == 'update' ?
              <Form.Item
                name='id'
                hidden
              >
              </Form.Item>
              : <Form.Item
                rules={[
                  { required: true, validateTrigger: 'onFinish', message: <InjectMessage id='auth.password.required' /> },
                  { min: 8, validateTrigger: 'onFinish', message: <InjectMessage id='auth.password.atLeast8Chars' /> }
                ]}
                name='password'
                label={<InjectMessage id='auth.password' />}
              >
                <Input.Password
                  autoComplete='new-password'
                  placeholder={"Mật khẩu"} />
              </Form.Item>
            }
            <div className='text-end'>
              <Button htmlType='submit' type='primary'>{type == 'update' ? "Sửa tài khoản" : "Tạo tài khoản"}</Button>
            </div>
          </Form>
        </div>
      </div>
      <div className='col-span-4'>
        <div className='bg-white p-4 rounded-2xl'>
          <Table
            pagination={{
              pageSize: 8
            }}
            onChange={({ current }: TablePaginationConfig) => changePage({ page: current })}
            dataSource={listStudent.list}
            columns={[
              // {
              //   title: 'Avatar',
              //   dataIndex: 'avatar',
              //   key: 'avatar',
              //   render: (theImageURL: string | undefined) => {
              //     return <Image
              //       fallback={PlaceholderUser}
              //       preview={false} width={64}
              //       height={64}
              //       style={{ borderRadius: 100 }}
              //       src={theImageURL} />;
              //   }
              // },
              {
                title: "Tên",
                dataIndex: 'name',
                key: 'name',
              },
              {
                title: "Email",
                dataIndex: 'emailLogin',
                key: 'emailLogin'
              },
              // {
              //   title: "Ngày tạo",
              //   dataIndex: 'createdAt',
              //   key: 'createdAt',
              //   render: (create: string | undefined) => moment(create, 'YYYY-MM-DDThh:mm:ss[Z]').format('LL')
              // },
              {
                title: 'Action', key: 'action',
                render: (text, record, index) => {
                  return <div>
                    <Button
                      style={{ marginRight: 8 }}
                      type="primary"
                      onClick={() => {
                        setType('update');
                        dispatch(MANAGE_STUDENT.DETAIL_ACCOUNT({ userId: record.id }))
                      }}
                    >
                      Sửa
                    </Button>
                    <Button
                      style={{ marginRight: 8 }}
                      danger
                      onClick={(e) => {
                        e.preventDefault();
                        Modal.confirm({
                          title: 'Xác nhận xoá tài khoản',
                          onOk: () => {
                            dispatch(MANAGE_STUDENT.DELETE_ACCOUNT({ id: record.id }))
                          }
                        });
                      }}
                    >
                      Xoá
                    </Button>
                  </div>
                },
              }
            ]}
          />
        </div>
      </div>
    </div>
  </div>;
};
export default Students;
