import { ELessonType, ESubjectType } from '../types/Mentor';

export const courseTypeOptions = [
  { label: 'Môn học', value: ESubjectType.learning },
  { label: 'Môn thi', value: ESubjectType.test }
];
export const lessonTypeOptions = [
  { label: 'Video', value: ELessonType.video },
  { label: 'Article', value: ELessonType.article },
  { label: 'Quiz', value: ELessonType.quiz }
];

export const Permission = {
  STUDENT: 'student',
  MENTOR: 'mentor',
  ADMIN: 'admin'
};
export const defaultVideoSetting = {
  vimeo: {
    playerOptions: {
      autopause: false,
      byline: false,
      portrait: false,
      title: false
    },
    title: ''
  },
  youtube: {
    playerVars: {
      playsinline: 1,
      showinfo: 0,
      rel: 0,
      iv_load_policy: 3,
      modestbranding: 1
    }
  }
};
export const colors = [
  { name: 'Pink', code: '#eb2f95', value: '--pink' },
  { name: 'Red', code: '#f5222d', value: '--red' },
  { name: 'Yellow', code: '#fbdb14', value: '--yellow' },
  { name: 'Orange', code: '#fa8b16', value: '--orange' },
  { name: 'Cyan', code: '#17c2c3', value: '--cyan' },
  { name: 'Green', code: '#52c51a', value: '--green' },
  { name: 'Blue', code: '#1678ff', value: '--blue' },
  { name: 'purple', code: '#722ed1', value: '--purple' },
  { name: 'Geek blue', code: '#3055eb', value: '--geek-blue' },
  { name: 'Volcano', code: '#fa551b', value: '--volcano' },
  { name: 'Gold', code: '#fbad14', value: '--gold' },
  { name: 'Lime', code: '#a1d911', value: '--lime' }
];

export const classBgColors = {
  '#eb2f95': 'bg-[#eb2f95]',
  '#f5222d': 'bg-[#f5222d]',
  '#fbdb14': 'bg-[#fbdb14]',
  '#fa8b16': 'bg-[#fa8b16]',
  '#17c2c3': 'bg-[#17c2c3]',
  '#52c51a': 'bg-[#52c51a]',
  '#1678ff': 'bg-[#1678ff]',
  '#722ed1': 'bg-[#722ed1]',
  '#3055eb': 'bg-[#3055eb]',
  '#fa551b': 'bg-[#fa551b]',
  '#fbad14': 'bg-[#fbad14]',
  '#a1d911': 'bg-[#a1d911]',
};
export const classBgBeforeColors = {
  '#eb2f95': 'before:bg-[#eb2f95]',
  '#f5222d': 'before:bg-[#f5222d]',
  '#fbdb14': 'before:bg-[#fbdb14]',
  '#fa8b16': 'before:bg-[#fa8b16]',
  '#17c2c3': 'before:bg-[#17c2c3]',
  '#52c51a': 'before:bg-[#52c51a]',
  '#1678ff': 'before:bg-[#1678ff]',
  '#722ed1': 'before:bg-[#722ed1]',
  '#3055eb': 'before:bg-[#3055eb]',
  '#fa551b': 'before:bg-[#fa551b]',
  '#fbad14': 'before:bg-[#fbad14]',
  '#a1d911': 'before:bg-[#a1d911]',
};
export const classTextColors = {
  '#eb2f95': 'text-[#eb2f95]',
  '#f5222d': 'text-[#f5222d]',
  '#fbdb14': 'text-[#fbdb14]',
  '#fa8b16': 'text-[#fa8b16]',
  '#17c2c3': 'text-[#17c2c3]',
  '#52c51a': 'text-[#52c51a]',
  '#1678ff': 'text-[#1678ff]',
  '#722ed1': 'text-[#722ed1]',
  '#3055eb': 'text-[#3055eb]',
  '#fa551b': 'text-[#fa551b]',
  '#fbad14': 'text-[#fbad14]',
  '#a1d911': 'text-[#a1d911]',
};
export const classBorderColors = {
  '#eb2f95': 'border-[#eb2f95]',
  '#f5222d': 'border-[#f5222d]',
  '#fbdb14': 'border-[#fbdb14]',
  '#fa8b16': 'border-[#fa8b16]',
  '#17c2c3': 'border-[#17c2c3]',
  '#52c51a': 'border-[#52c51a]',
  '#1678ff': 'border-[#1678ff]',
  '#722ed1': 'border-[#722ed1]',
  '#3055eb': 'border-[#3055eb]',
  '#fa551b': 'border-[#fa551b]',
  '#fbad14': 'border-[#fbad14]',
  '#a1d911': 'border-[#a1d911]',
};
