import { axiosAuth, axiosCatch } from '../../utils/AxiosHelper';
import { AxiosResponse } from 'axios';

export const reqScheduleCreate = async (params: any): Promise<AxiosResponse<any>> => {
  return await axiosAuth().post('/api/calendar/create', params).catch(axiosCatch);
};

export const reqScheduleUpdate = async (params: any): Promise<AxiosResponse<any>> => {
  return await axiosAuth().post('/api/calendar/update', params).catch(axiosCatch);
};

export const reqScheduleDelete = async (params: any): Promise<AxiosResponse<any>> => {
  return await axiosAuth().post('/api/calendar/delete', params).catch(axiosCatch);
};

export const reqScheduleList = async (params = {}): Promise<AxiosResponse<any>> => {
  return await axiosAuth().get('/api/calendar/list' ).catch(axiosCatch);
};

