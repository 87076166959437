import { ActionType } from '../actions/actionTypes';
import {
  CREATE_STUDENT_SUCCESS,
  DELETE_STUDENT_SUCCESS,
  DETAIL_STUDENT_SUCCESS,
  GET_LIST_STUDENT_SUCCESS, STUDENT_STATE
} from '../actions/types';
import { IResponseDetailStudent } from '../types/Admin';

export interface IManageAccountState {
  success: boolean,
  error: boolean,
  listStudent: any,
  loading: boolean,
  detailStudent: IResponseDetailStudent
}

const initialState: IManageAccountState = {
  success: true,
  error: false,
  loading: false,
  listStudent: [],
  detailStudent: {} as IResponseDetailStudent
};
const manageStudentReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case DELETE_STUDENT_SUCCESS:
      const std = state.listStudent.find((std: any) => std.id !== action.payload);
      return { ...state, listStudent: std };
      case DETAIL_STUDENT_SUCCESS:
        return { ...state, detailStudent: action.payload.data };
    case CREATE_STUDENT_SUCCESS:
    case STUDENT_STATE:
      return { ...state, ...action.payload };

      case GET_LIST_STUDENT_SUCCESS:
        return { ...state, listStudent: action.payload.data };
    default:
      return state;
  }
};
export default manageStudentReducer;
