import {
  QUIZ_COLLECTION_CREATE,
  QUIZ_COLLECTION_DELETE,
  QUIZ_COLLECTION_INFO,
  QUIZ_COLLECTION_LIST,
  QUIZ_COLLECTION_UPDATE,
  ADMIN_STATE,
  AUTH_GET_PERMISSION,
  AUTH_GET_PERMISSION_SUCCESS,
  AUTH_LOGIN,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_RENEW_TOKEN,
  CHAT_LIST_GET,
  COMMON_GET_MENU,
  COMMON_SET_STATE,
  COURSE_ADD,
  COURSE_ADD_GROUP,
  COURSE_ADD_LESSON,
  COURSE_ADD_SUBJECT,
  COURSE_ADD_SUBJECT_CACHE,
  COURSE_DEL_GROUP,
  COURSE_DEL_LESSON,
  COURSE_DELETE,
  COURSE_DELETE_SUBJECT,
  COURSE_GET_DETAIL,
  COURSE_GET_LIST,
  COURSE_GET_SUBJECT,
  COURSE_GROUP_DETAIL,
  COURSE_STATE,
  COURSE_UPDATE,
  COURSE_UPDATE_GROUP,
  COURSE_UPDATE_LESSON,
  COURSE_UPDATE_SUBJECT,
  CREATE_ADMIN,
  CREATE_ADMIN_SUCCESS,
  CREATE_MENTOR,
  CREATE_MENTOR_SUCCESS,
  CREATE_STUDENT,
  CREATE_STUDENT_SUCCESS,
  DELETE_ADMIN,
  DELETE_ADMIN_SUCCESS,
  DELETE_MENTOR,
  DELETE_MENTOR_SUCCESS,
  DELETE_STUDENT,
  DELETE_STUDENT_SUCCESS,
  DETAIL_ADMIN,
  DETAIL_ADMIN_SUCCESS,
  DETAIL_MENTOR,
  DETAIL_MENTOR_SUCCESS,
  DETAIL_STUDENT,
  DETAIL_STUDENT_SUCCESS,
  EDIT_ADMIN,
  EDIT_ADMIN_SUCCESS,
  EDIT_MENTOR,
  EDIT_MENTOR_SUCCESS,
  EDIT_STUDENT,
  EDIT_STUDENT_SUCCESS,
  GET_LIST_ADMIN,
  GET_LIST_ADMIN_SUCCESS,
  GET_LIST_MENTOR,
  GET_LIST_MENTOR_SUCCESS,
  GET_LIST_STUDENT,
  GET_LIST_STUDENT_SUCCESS,
  MENTOR_STATE_SUCCESS,
  NOTES_ADD_COLLECTION,
  NOTES_DELETE_COLLECTION,
  NOTES_GET_COLLECTION,
  NOTES_GET_COLLECTION_SUCCESS,
  NOTES_GET_LIST,
  NOTES_GET_LIST_SUCCESS,
  NOTES_UPDATE_COLLECTION,
  STD_COURSE_INFO,
  STD_COURSE_LIST,
  STD_ENROLL,
  STD_ENROLL_LIST,
  STD_ENROLL_SUCCESS,
  STD_GET_ARTICLE,
  STD_STATE,
  STD_UPDATE_PROCESS,
  STUDENT_STATE,
  TEST_LIST_GET,
  WORK_SCHEDULE_GET,
  QUIZ_CREATE,
  QUIZ_UPDATE,
  QUIZ_DELETE,
  QUIZ_INFO,
  NOTES_PERSONAL_GET_LIST,
  COMMON_SET_DOCUMENT,
  GET_COLLECTION_NOTES_PERSONAL,
  GET_LIST_COURSE_NOTES,
  SCHEDULE_CREATE,
  SCHEDULE_UPDATE,
  SCHEDULE_DELETE,
  SCHEDULE_GET_LIST,
  SCHEDULE_STATE,
  COURSE_PUBLISH,
  COURSE_CHANGE_VERSION,
  CAREER_LIST,
  CAREER_STATE,
  CAREER_LIST_ASSIGN,
  CAREER_INFO,
  CAREER_ADD_COURSE,
  CAREER_CREATE,
  CAREER_ASSIGN,
  STD_COURSE_ASSIGN, STD_COURSE_ASSIGN_INFO, STD_LESSON_ASSIGN_INFO, STD_LESSON_ASSIGN_PROCESS
} from './types';
import { DeleteCollectionNote, LoginData, TScheduleAdd, UpdateCollectionNote } from '../types/User';
import {
  DeleteMentorAccountData,
  DeleteStudentAccountData,
  DetailAdminAccountData,
  DetailMentorAccountData,
  DetailStudentAccountData,
  GetListAccountData
} from '../types/Admin';
import { ICourseItem, ISubjectItem } from '../types/Mentor';

export interface ActionType {
  type: string,
  payload: any
}

export const COMMON = {
  CLOSE_MODAL: (closeModal: boolean) => {
    return { type: COMMON_SET_STATE, payload: { closeModal } };
  },
  SET_STATE: (payload: any) => {
    return {
      type: COMMON_SET_DOCUMENT,
      payload
    };
  },
  SET_MENU: (menus: any) => {
    return {
      type: COMMON_SET_STATE,
      payload: { menus }
    };
  },
  GET_MENU: (typeLogin: any, userPermission: any) => {
    return {
      type: COMMON_GET_MENU,
      payload: { typeLogin, userPermission }
    };
  },
  LOADING: (loading: boolean): ActionType => {
    return {
      type: COMMON_SET_STATE,
      payload: { loading }
    };
  }
};
export const AUTH = {
  LOGIN: (payload: LoginData) => {
    return {
      type: AUTH_LOGIN,
      payload
    };
  },
  LOGIN_SUCCESS: (payload: any) => {
    return {
      type: AUTH_LOGIN_SUCCESS,
      payload
    };
  },
  LOGOUT_SUCCESS: () => {
    return {
      type: AUTH_LOGOUT_SUCCESS
    };
  },
  LOGOUT: () => {
    return {
      type: AUTH_LOGOUT
    };
  },
  RENEW_TOKEN: () => {
    return {
      type: AUTH_RENEW_TOKEN
    };
  },
  GET_PERMISSION: (payload: any) => {
    return {
      type: AUTH_GET_PERMISSION
    };
  },

  GET_PERMISSION_SUCCESS: (payload: any) => {
    return {
      type: AUTH_GET_PERMISSION_SUCCESS,
      payload
    };
  }
};

export const MANAGE_STUDENT = {
  SET_STATE: (payload: any) => {
    return {
      type: STUDENT_STATE,
      payload
    };
  },
  GET_ACCOUNT: (payload?: GetListAccountData) => {
    return {
      type: GET_LIST_STUDENT,
      payload
    };
  },
  DETAIL_ACCOUNT: (payload: DetailStudentAccountData) => {
    return {
      type: DETAIL_STUDENT,
      payload
    };
  },
  DETAIL_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: DETAIL_STUDENT_SUCCESS,
      payload
    };
  },
  GET_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: GET_LIST_STUDENT_SUCCESS,
      payload
    };
  },
  CREATE_ACCOUNT: (payload: any) => {
    return {
      type: CREATE_STUDENT,
      payload
    };
  },
  CREATE_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: CREATE_STUDENT_SUCCESS,
      payload
    };
  },
  DELETE_ACCOUNT: (payload: DeleteStudentAccountData) => {
    return {
      type: DELETE_STUDENT,
      payload
    };
  },
  DELETE_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: DELETE_STUDENT_SUCCESS,
      payload
    };
  },
  EDIT_ACCOUNT: (payload: any) => {
    return {
      type: EDIT_STUDENT,
      payload
    };
  },
  EDIT_ACCOUNT_SUCCESS: () => {
    return {
      type: EDIT_STUDENT_SUCCESS
    };
  }
};

export const MANAGE_ADMIN = {
  SET_STATE: (payload: any) => {
    return {
      type: ADMIN_STATE,
      payload
    };
  },
  GET_ACCOUNT: (payload: GetListAccountData) => {
    return {
      type: GET_LIST_ADMIN,
      payload
    };
  },
  GET_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: GET_LIST_ADMIN_SUCCESS,
      payload
    };
  },
  // CREATE_ACCOUNT: (payload: CreateStudentAccountData) => {
  CREATE_ACCOUNT: (payload: any) => {
    return {
      type: CREATE_ADMIN,
      payload
    };
  },
  CREATE_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: CREATE_ADMIN_SUCCESS,
      payload
    };
  },
  DETAIL_ACCOUNT: (payload: DetailAdminAccountData) => {
    return {
      type: DETAIL_ADMIN,
      payload
    };
  },
  DETAIL_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: DETAIL_ADMIN_SUCCESS,
      payload
    };
  },
  DELETE_ACCOUNT: (payload: DeleteStudentAccountData) => {
    return {
      type: DELETE_ADMIN,
      payload
    };
  },
  DELETE_ACCOUNT_SUCCESS: (payload: any) => {
    console.log('payload DELETE', payload);
    return {
      type: DELETE_ADMIN_SUCCESS,
      payload
    };
  },
  EDIT_ACCOUNT: (payload: any) => {
    console.log('payload', payload);
    return {
      type: EDIT_ADMIN,
      payload
    };
  },
  EDIT_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: EDIT_ADMIN_SUCCESS,
      payload
    };
  }
};

export const MANAGE_MENTOR = {
  SET_STATE: (payload: any) => {
    return {
      type: ADMIN_STATE,
      payload
    };
  },
  GET_ACCOUNT: (payload: any) => {
    return {
      type: GET_LIST_MENTOR,
      payload
    };
  },
  GET_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: GET_LIST_MENTOR_SUCCESS,
      payload
    };
  },
  // CREATE_ACCOUNT: (payload: CreateStudentAccountData) => {
  CREATE_ACCOUNT: (payload: any) => {
    return {
      type: CREATE_MENTOR,
      payload
    };
  },
  CREATE_ACCOUNT_SUCCESS: () => {
    return {
      type: CREATE_MENTOR_SUCCESS
    };
  },
  DELETE_ACCOUNT: (payload: DeleteMentorAccountData) => {
    return {
      type: DELETE_MENTOR,
      payload
    };
  },
  DELETE_ACCOUNT_SUCCESS: (payload: any) => {
    console.log('payload DELETE', payload);
    return {
      type: DELETE_MENTOR_SUCCESS,
      payload
    };
  },
  EDIT_ACCOUNT: (payload: any) => {
    return {
      type: EDIT_MENTOR,
      payload
    };
  },
  EDIT_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: EDIT_MENTOR_SUCCESS,
      payload
    };
  },
  DETAIL_ACCOUNT: (payload: DetailMentorAccountData) => {
    return {
      type: DETAIL_MENTOR,
      payload
    };
  },
  DETAIL_ACCOUNT_SUCCESS: (payload: any) => {
    return {
      type: DETAIL_MENTOR_SUCCESS,
      payload
    };
  }
};

export const MENTOR = {
  GET_CHAT_LIST: (payload: any) => {
    return {
      type: CHAT_LIST_GET,
      payload
    };
  },
  GET_TEST_LIST: (payload: any) => {
    return {
      type: TEST_LIST_GET,
      payload
    };
  },
  GET_WORK_SCHEDULE: (payload: any) => {
    return {
      type: WORK_SCHEDULE_GET,
      payload
    };
  },
  MENTOR_STATE_SUCCESS: (payload: any) => {
    return {
      type: MENTOR_STATE_SUCCESS,
      payload
    };
  }
};
export const COURSE = {
  CHANGE_VERSION: (payload: any) => {
    return {
      type: COURSE_CHANGE_VERSION,
      payload: payload
    };
  },
  GET_LIST: (payload: any = {}, getInfo?: boolean) => {
    return {
      type: COURSE_GET_LIST,
      payload: {
        params: payload,
        getInfo
      }
    };
  },
  GET_DETAIL: (payload: any) => {
    return {
      type: COURSE_GET_DETAIL,
      payload
    };
  },
  GET_LESSON_GROUP: (payload: any) => {
    return {
      type: COURSE_GROUP_DETAIL,
      payload
    };
  },
  COURSE_STATE: (payload: any) => {
    return {
      type: COURSE_STATE,
      payload
    };
  },
  ADD_SUBJECT: (payload: ISubjectItem) => {
    return {
      type: COURSE_ADD_SUBJECT,
      payload
    };
  },
  ADD_CACHE: (payload: ISubjectItem) => {
    return {
      type: COURSE_ADD_SUBJECT_CACHE,
      payload
    };
  },
  UPDATE_SUBJECT: (payload: any) => {
    return {
      type: COURSE_UPDATE_SUBJECT,
      payload
    };
  },
  DELETE_SUBJECT: (subjectId: string) => {
    return {
      type: COURSE_DELETE_SUBJECT,
      payload: { subjectId }
    };
  },
  GET_SUBJECT: (subjectId: string) => {
    return {
      type: COURSE_GET_SUBJECT,
      payload: subjectId
    };
  },
  ADD_LESSON_GROUP: (payload: ISubjectItem) => {
    return {
      type: COURSE_ADD_GROUP,
      payload
    };
  },
  UPDATE_GROUP: (payload: any) => {
    return {
      type: COURSE_UPDATE_GROUP,
      payload
    };
  },
  DELETE_GROUP: (payload: any) => {
    return {
      type: COURSE_DEL_GROUP,
      payload
    };
  },
  UPDATE_LESSON: (payload: any) => {
    return {
      type: COURSE_UPDATE_LESSON,
      payload
    };
  },
  PUBLISH: (payload: any) => {
    return {
      type: COURSE_PUBLISH,
      payload
    };
  },
  DELETE_LESSON: (payload: any) => {
    return {
      type: COURSE_DEL_LESSON,
      payload
    };
  },
  ADD_LESSON: (payload: any) => {
    return {
      type: COURSE_ADD_LESSON,
      payload
    };
  },
  CREATE: (payload: ICourseItem) => {
    return {
      type: COURSE_ADD,
      payload
    };
  },
  UPDATE: (payload: any) => {
    return {
      type: COURSE_UPDATE,
      payload
    };
  },
  DELETE: (payload: any) => {
    return {
      type: COURSE_DELETE,
      payload
    };
  }
};

export const STUDENT = {
  UPDATE_PROCESS: (payload: any, nextRoute?: any) => {
    return {
      type: STD_UPDATE_PROCESS,
      payload
    };
  },
  GET_COURSE: (payload = {}) => {
    return {
      type: STD_COURSE_LIST,
      payload
    };
  },
  GET_PATH_COURSE_INFO: (pathId: string, courseId: string) => {
    return {
      type: STD_COURSE_ASSIGN_INFO,
      payload: { courseId, pathId }
    };
  },
  GET_PATH_LESSON_INFO: (pathId: string, courseId: string, lessonId: string) => {
    return {
      type: STD_LESSON_ASSIGN_INFO,
      payload: { pathId, courseId, lessonId }
    };
  },
  GET_PATH_LESSON_UPDATE: (data: any) => {
    return {
      type: STD_LESSON_ASSIGN_PROCESS,
      payload: data
    };
  },
  GET_COURSE_ASSIGN: (payload = {}) => {
    return {
      type: STD_COURSE_ASSIGN,
      payload
    };
  },
  GET_ARTICLE: (docId: string) => {
    return { type: STD_GET_ARTICLE, payload: docId };
  },
  SET_STATE: (payload = {}) => {
    return {
      type: STD_STATE,
      payload
    };
  },
  GET_COURSE_INFO: (payload = {}) => {
    return {
      type: STD_COURSE_INFO,
      payload
    };
  },
  GET_ENROLL_LIST: (payload = {}) => {
    return {
      type: STD_ENROLL_LIST,
      payload
    };
  },
  SET_ENROLL: (payload = {}) => {
    return {
      type: STD_ENROLL,
      payload
    };
  },
  SET_ENROLL_SUCCESS: (payload = {}) => {
    return {
      type: STD_ENROLL_SUCCESS,
      payload
    };
  },
  GET_NOTES_COLLECTION: () => {
    return {
      type: NOTES_GET_COLLECTION
      // payload: payload
    };
  },

  GET_PERSONAL_NOTES: () => {
    return {
      type: NOTES_PERSONAL_GET_LIST
      // payload: payload
    };
  },
  GET_NOTES_PERSONAL_COLLECTION: () => {
    return {
      type: GET_COLLECTION_NOTES_PERSONAL
      // payload: payload
    };
  },
  GET_NOTES_COLLECTION_SUCCESS: (payload = {}) => {
    return {
      type: NOTES_GET_COLLECTION_SUCCESS,
      payload
    };
  },
  GET_NOTES_LIST: (payload: any) => {
    return {
      type: NOTES_GET_LIST,
      payload: payload
    };
  },
  GET_NOTES_LIST_SUCCESS: (payload: any) => {
    return {
      type: NOTES_GET_LIST_SUCCESS,
      payload: payload
    };
  },
  ADD_NOTES_COLLECTION: (title: string) => {
    return {
      type: NOTES_ADD_COLLECTION,
      payload: title
    };
  },
  DELETE_NOTES_COLLECTION: (collectionNoteId: DeleteCollectionNote) => {
    return {
      type: NOTES_DELETE_COLLECTION,
      payload: collectionNoteId
    };
  },
  UPDATE_NOTES_COLLECTION: (payload: UpdateCollectionNote) => {
    return {
      type: NOTES_UPDATE_COLLECTION,
      payload: payload
    };
  },
  GET_LIST_COURSE_NOTES: () => {
    return {
      type: GET_LIST_COURSE_NOTES
    };
  }

};

export const QUIZ = {
  GET_QUIZ_COLLECTION: (payload: any) => {
    return {
      type: QUIZ_COLLECTION_LIST,
      payload
    };
  },
  CREATE_QUIZ_COLLECTION: (payload: string) => {
    return {
      type: QUIZ_COLLECTION_CREATE,
      payload
    };
  },
  UPDATE_QUIZ_COLLECTION: (payload: any) => {
    return {
      type: QUIZ_COLLECTION_UPDATE,
      payload
    };
  },
  DELETE_QUIZ_COLLECTION: (payload: any) => {
    return {
      type: QUIZ_COLLECTION_DELETE,
      payload
    };
  },
  INFO_QUIZ_COLLECTION: (payload: any) => {
    return {
      type: QUIZ_COLLECTION_INFO,
      payload
    };
  },
  CREATE_QUIZ: (payload: any) => {
    return {
      type: QUIZ_CREATE,
      payload
    };
  },
  UPDATE_QUIZ: (payload: any) => {
    return {
      type: QUIZ_UPDATE,
      payload
    };
  },
  DELETE_QUIZ: (payload: any) => {
    return {
      type: QUIZ_DELETE,
      payload
    };
  },
  INFO_QUIZ: (payload: any) => {
    return {
      type: QUIZ_INFO,
      payload
    };
  }
};

export const SCHEDULE = {
  LIST: (payload: any) => {
    return {
      type: SCHEDULE_GET_LIST,
      payload
    };
  },
  STATE: (payload: any) => {
    return {
      type: SCHEDULE_STATE,
      payload
    };
  },
  ADD: (payload: TScheduleAdd) => {
    return {
      type: SCHEDULE_CREATE,
      payload
    };
  },
  UPDATE: (payload: any) => {
    return {
      type: SCHEDULE_UPDATE,
      payload
    };
  },
  DELETE: (payload: any) => {
    return {
      type: SCHEDULE_DELETE,
      payload
    };
  }
};

export const PATH = {
  SET_STATE: (payload = {}) => {
    return {
      type: CAREER_STATE,
      payload
    };
  },
  GET_LIST: (payload = {}) => {
    return {
      type: CAREER_LIST,
      payload
    };
  },
  GET_LIST_ASSIGN: (payload = {}) => {
    return {
      type: CAREER_LIST_ASSIGN,
      payload
    };
  },
  GET_INFO: (payload = {}) => {
    return {
      type: CAREER_INFO,
      payload
    };
  },
  ADD_COURSE: (payload = {}) => {
    return {
      type: CAREER_ADD_COURSE,
      payload
    };
  },
  CREATE: (payload = {}) => {
    return {
      type: CAREER_CREATE,
      payload
    };
  },
  ASSIGN_STD: (payload = {}) => {
    return {
      type: CAREER_ASSIGN,
      payload
    };
  }
};
