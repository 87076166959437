import { lazy } from 'react';

const Dashboard = lazy(() => import('../mentor/dashboard'));
const CourseAdd = lazy(() => import('../mentor/course/add'));
const Quiz = lazy(() => import('../mentor/quiz'));


const mentor = [
  {
    path: '/',
    component: Dashboard,
    key: 'mentor.dashboard',
    exact: true
  }
];
export const publicMentor = [
  {
    path: '/course',
    component: CourseAdd,
    key: 'mentor.course',
    exact: true
  },
  {
    path: '/quiz',
    component: Quiz,
    key: 'mentor.quiz',
    exact: true
  }
];
export default mentor;
