import {
  CreateAdminAccountData,
  CreateMentorAccountData,
  CreateQuizCollectionData,
  CreateStudentAccountData,
  DeleteMentorAccountData,
  DeleteQuizCollectionData,
  DeleteStudentAccountData,
  DetailAdminAccountData,
  DetailMentorAccountData,
  DetailStudentAccountData,
  EditAdminAccountData,
  EditMentorAccountData,
  EditStudentAccountData,
  IDeleteQuizData,
  IInfoQuizData,
  IQuizData,
  IResponseDetailAdmin,
  IResponseDetailMentor,
  IResponseDetailStudent,
  IResponseListAdmin,
  IResponseListMentor,
  IResponseListStudent,
  IUpdateQuizData,
  InfoQuizCollectionData,
  ResponseCollectionQuiz,
  ResponseCreateMentortAccount,
  UpdateQuizCollectionData
} from '../types/Admin';
import { axiosAuth, axiosCatch } from '../../utils/AxiosHelper';
import { AxiosResponse } from 'axios';

export const reqGetListAdminAccount = async (): Promise<AxiosResponse<IResponseListAdmin>> => {
  return await axiosAuth().get('/admin/account/list').catch(axiosCatch);
};

export const reqCreateAdminAccount = async (createData: CreateAdminAccountData) => {
  console.log('reqCreateAdminAccount', createData);
  return await axiosAuth().post('/admin/account/create', createData).catch(axiosCatch);
};

export const reqDetailAdminAccount = async (params: DetailAdminAccountData): Promise<AxiosResponse<IResponseDetailAdmin>> => {
  return await axiosAuth().get(`/admin/account/get`, { params }).catch(axiosCatch);
};

export const reqEditAdminAccount = async (data: EditAdminAccountData) => {
  return await axiosAuth().post('/admin/account/update', data).catch(axiosCatch);
};

export const reqGetListStudentAccount = async () :  Promise<AxiosResponse<IResponseListStudent>> => {
  return await axiosAuth().get(`/admin/student/list`).catch(axiosCatch);
};

export const reqDetailStudentAccount = async (params: DetailStudentAccountData) => {
  return await axiosAuth().get(`/admin/student/get`, { params }).catch(axiosCatch);
};

export const reqCreateStudentAccount = async (createData: CreateStudentAccountData): Promise<AxiosResponse<IResponseDetailStudent>> => {
  return await axiosAuth().post('/admin/student/create', createData).catch(axiosCatch);
};

export const reqDeleteStudentAccount = async (dataDeleteStudent: DeleteStudentAccountData) => {
  return await axiosAuth().post('/admin/student/delete', dataDeleteStudent).catch(axiosCatch);
};

export const reqEditStudentAccount = async (data: EditStudentAccountData) => {
  return await axiosAuth().post('/admin/student/update', data).catch(axiosCatch);
};
export const reqGetListMentorAccount = async (): Promise<AxiosResponse<IResponseListMentor>> => {
  return await axiosAuth().get(`/admin/mentor/list`).catch(axiosCatch);
};

export const reqCreateMentorAccount = async (data: CreateMentorAccountData)=> {
  return await axiosAuth().post('/admin/mentor/create', data).catch(axiosCatch);
};

export const reqDeleteMentorAccount = async (data: DeleteMentorAccountData) => {
  return await axiosAuth().post('/admin/mentor/delete', data).catch(axiosCatch);
};

export const reqDetailMentorAccount = async (params: DetailMentorAccountData): Promise<AxiosResponse<IResponseDetailMentor>> => {
  return await axiosAuth().get(`/admin/mentor/get`, { params }).catch(axiosCatch);
};

export const reqEditMentorAccount = async (data: EditMentorAccountData) => {
  return await axiosAuth().post('/admin/mentor/update', data).catch(axiosCatch);
};

export const reqGetCollectionQuiz = async () => {
  return await axiosAuth().get('/api/collection-quiz/list').catch(axiosCatch);
};
export const reqCreateCollectionQuiz = async (data:CreateQuizCollectionData ) => {
  return await axiosAuth().post('/api/collection-quiz/create', data).catch(axiosCatch);
};

export const reqUpdateCollectionQuiz = async (data:UpdateQuizCollectionData ) => {
  return await axiosAuth().post('/api/collection-quiz/update', data).catch(axiosCatch);
};
export const reqDeleteCollectionQuiz = async (data:DeleteQuizCollectionData ) => {
  return await axiosAuth().post('/api/collection-quiz/delete', data).catch(axiosCatch);
};
export const reqInfoCollectionQuiz = async (data:InfoQuizCollectionData ) => {  
  return await axiosAuth().get('/api/collection-quiz/info', {params: data}).catch(axiosCatch);
};

export const reqCreateQuiz = async (data:IQuizData ) => {
  return await axiosAuth().post('/api/quiz/create', data).catch(axiosCatch);
};

export const reqUpdateQuiz = async (data:IUpdateQuizData ) => {
  return await axiosAuth().post('/api/quiz/update', data).catch(axiosCatch);
};


export const reqInfoQuiz = async (data: IInfoQuizData) => {  
  return await axiosAuth().get('/api/quiz/info', {params: data}).catch(axiosCatch);
};

export const reqDeleteQuiz = async (data:IDeleteQuizData ) => {  
  return await axiosAuth().post('/api/quiz/delete',data).catch(axiosCatch);
};




