import Icon, { IconName } from './Icon';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './modules/chatBot.module.scss';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import copy from 'copy-to-clipboard';
import { Form, Input, message, Dropdown, Space, Modal } from 'antd';
import { openApi } from '../../../utils/AxiosHelper';
import { reqCreatePersonalNotes } from '../../../redux/services/notes';
import { GPT_GET_HISTORIES, GPT_GET_MESSAGES, GPT_SAVE_MESSAGE } from '../../../redux/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { AppState } from '../../../redux/reducers';
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';

const ChatBot = () => {
  const dispatch = useDispatch();
  const { GPTHistories, GPTMsg } = useSelector((app: AppState) => app.student);

  const [form] = Form.useForm();
  const [formNote] = Form.useForm();
  const userId = localStorage.getItem('id');

  const [open, setOpen] = useState<boolean>(false);
  const [isZoom, setIsZoom] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [typing, setTyping] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [messageId, setMessageId] = useState<string | null>(() => {
    const CHAT_ID = localStorage.getItem('CHAT_ID');
    if (!CHAT_ID) {
      const newID = nanoid();
      localStorage.setItem('CHAT_ID', newID);
      return newID;
    }
    return CHAT_ID;
  });

  const [conversations, setConversations] = useState<any>([]);

  const [displayText, setDisplayText] = useState('');

  const contentRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<any>(null);

  const scrollBottom = () => {
    contentRef.current?.scrollTo({
      top: contentRef.current?.scrollHeight,
      behavior: 'smooth'
    });
  };
  const toogleZoom = () => {
    setIsZoom(!isZoom);
  };

  const toggleChat = () => {
    scrollBottom();
    inputRef.current?.focus();
    setOpen(!open);
  };

  const onSaveMessage = (message: any) => {
    dispatch({
      type: GPT_SAVE_MESSAGE,
      payload: message
    });
  };
  const onGetConversations = useCallback((messageId: string) => {
    dispatch({
      type: GPT_GET_MESSAGES,
      payload: { messageId, userId }
    });
    scrollBottom();
  }, []);

  const onGetCompletion = useCallback(async (data: any) => {
    try {
      setLoading(true);
      setTyping(true);
      form.resetFields();
      scrollBottom();

      const userMessage = { role: 'user', ...data };
      const newConversation = [...conversations, userMessage];
      setConversations((prev: any) => [...prev, userMessage]);
      await onSaveMessage({ ...userMessage, messageId, userId });
      setTyping(false);

      const response: any = await openApi(newConversation);
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let text = '';
      while (true) {
        const chunk = await reader.read();
        const { done, value } = chunk;
        if (done) {
          break;
        }
        const decodedChunk = decoder.decode(value);
        const lines = decodedChunk.split('\n');
        const parseLines = lines.map((line: any) => line.replace(/^data: /, '').trim())
          .filter((line: any) => line !== '' && line != '[DONE]')
          .map((line: any) => JSON.parse(line));
        for (const parseLine of parseLines) {
          const { choices } = parseLine;
          const { delta } = choices[0];
          const { content } = delta;
          if (content) {
            text += content;
            setDisplayText((prev: string) => prev += content);
          }
        }
        scrollBottom();
      }
      const assistant = { role: 'assistant', content: text };
      setConversations((prev: any) => [...prev, assistant]);
      //save to db
      await onSaveMessage({ ...assistant, messageId, userId });
    } catch (e: any) {
      console.log('ERROR', e);
    } finally {
      setLoading(false);
      setDisplayText('');
      inputRef.current.focus();
    }
  }, [conversations]);

  const onFinishSaveNote = async (data: any) => {
    try {
      await reqCreatePersonalNotes(data);
      message.success('Lưu note thành công!');
      setVisible(false);
      form.resetFields();
    } catch (e: any) {
      message.error(e.message);
    }
  };

  useEffect(() => {
    if (messageId && userId) {
      dispatch({
        type: GPT_GET_HISTORIES,
        payload: { userId, messageId }
      });
      onGetConversations(messageId);
    }
  }, [messageId, userId]);

  useEffect(() => {
    const defaultConversation = GPTMsg.length > 0 ? GPTMsg : [{
      role: 'assistant',
      content: 'Hi! Beva bot can help you today?'
    }];
    setConversations(defaultConversation);
  }, [GPTMsg]);

  const renderContent = (text: string) => {
    return <div className={`max-width-full w-full ${styles.content}`}>
      <ReactMarkdown
        components={{
          code({ node, inline, className, children, ...props }: any) {
            const content = String(children).replace(/\n$/, '');
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <div className='overflow-x-auto w-full' style={{ borderRadius: 10 }}>
                <div className='header bg-slate-900 text-white p-2'>
                  <div className='flex justify-between'>
                    <span>{match[1]}</span>
                    <span onClick={() => onCopyCode(content)}>Coppy</span>
                  </div>
                </div>
                <SyntaxHighlighter
                  style={okaidia}
                  language={match[1]}
                  customStyle={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    marginTop: 0,
                    background: '#000'
                  }}
                  PreTag='div'
                  {...props}
                >
                  {content}
                </SyntaxHighlighter>
              </div>
            ) : (
              <code style={{ whiteSpace: 'pre-line' }} className={className} {...props}>
                {children}
              </code>
            );
          }
        }}
      >
        {text}
      </ReactMarkdown>
    </div>;
  };

  const renderChatContent = useMemo(() => {
    return <div className=''>
      {conversations && conversations.map((item: any, index: number) => {

        if (item.role !== 'user') {
          return <div key={`${item.role}_${index}`} className='my-2 flex items-center justify-start max-w-full'>
            <div className='text-body w-fit max-w-[90%] bg-[#F2F5FA] px-3 rounded-xl rounded-bl-none'>
              {renderContent(item.content)}
            </div>
            <Dropdown
              className='cursor-pointer'
              menu={{
                items: [
                  {
                    label: 'Copy',
                    icon: <Icon showMask={false} width={18} height={18} name={IconName.bookMark} />,
                    key: 'copy',
                    onClick: () => onCopyCode(item.content)
                  }
                ]
              }} trigger={['click']}>
              <svg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M9.4092 4.66211C9.99224 4.66211 10.4649 4.27036 10.4649 3.78711C10.4649 3.30386 9.99224 2.91211 9.4092 2.91211C8.82616 2.91211 8.35352 3.30386 8.35352 3.78711C8.35352 4.27036 8.82616 4.66211 9.4092 4.66211Z'
                  fill='#040404' />
                <path
                  d='M9.4092 8.16211C9.99224 8.16211 10.4649 7.77036 10.4649 7.28711C10.4649 6.80386 9.99224 6.41211 9.4092 6.41211C8.82616 6.41211 8.35352 6.80386 8.35352 7.28711C8.35352 7.77036 8.82616 8.16211 9.4092 8.16211Z'
                  fill='#040404' />
                <path
                  d='M9.4092 11.6621C9.99224 11.6621 10.4649 11.2704 10.4649 10.7871C10.4649 10.3039 9.99224 9.91211 9.4092 9.91211C8.82616 9.91211 8.35352 10.3039 8.35352 10.7871C8.35352 11.2704 8.82616 11.6621 9.4092 11.6621Z'
                  fill='#040404' />
              </svg>
            </Dropdown>
          </div>;
        }
        return <div key={`${item.role}_${index}`} className='flex mb-5 justify-end max-w-full'>
          <div
            className='text-white w-fit max-w-[90%] bg-primary px-3 rounded-xl rounded-br-none'>
            {renderContent(item.content)}
          </div>
        </div>;
      })}
      {(typing || (loading && displayText)) && <div className='my-2 flex justify-start max-w-full'>
        <div className='text-body w-fit max-w-[90%] bg-[#F2F5FA] px-3 rounded-xl rounded-bl-none'>
          {typing ? <div className={styles.typing}>
            <div className={styles.dot} />
            <div className={styles.dot} />
            <div className={styles.dot} />
          </div> : renderContent(displayText)}
        </div>
      </div>}
    </div>;
  }, [conversations, displayText, typing]);


  const onCopyCode = (content: string) => {
    copy(content);
    message.success('Code copied to clipboard!');
  };

  return <>
    <button onClick={toggleChat} className={`fixed w-fit h-fit z-all left-7 bottom-5 ${open && 'hidden'}`}>
      <Icon width={80} height={80} name={IconName.chatBot} showMask={false} />
    </button>

    <div
      className={`${styles.chatBot} ${!open && 'hidden'} z-all p-4 ${isZoom ? 'h-box w-2/5' : 'w-96 h-96'} rounded-2xl fixed bottom-[28px] left-[28px]`}>
      <div className='flex justify-between items-center relative'>
        <h5>Beva bot</h5>
        <div className='flex items-center'>
          <button className='mr-2' onClick={toogleZoom}>
            {isZoom ? <FullscreenExitOutlined color='#2E61D6' /> : <FullscreenOutlined color='#2E61D6' />}
          </button>
          <button onClick={toggleChat}>
            <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path d='M6 12.5H18' stroke='#2E61D6' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          </button>

        </div>
      </div>
      <div ref={contentRef} className='content h-80 overflow-y-auto pb-10 max-h-80 relative'>
        {renderChatContent}
      </div>
      <Form onFinish={onGetCompletion} form={form}
            className='absolute bottom-2 p-4 flex justify-between items-center left-0 w-full h-9'>
        <Form.Item name='content' noStyle>
          <Input.TextArea
            onPressEnter={(e: any) => {
              if (!e.shiftKey) {
                form.submit();
              }
            }}
            readOnly={loading}
            ref={inputRef}
            autoSize={{ maxRows: 3 }}
            placeholder='Type a message'
            className={styles.input} />
        </Form.Item>
        <button disabled={loading} type='submit' className='w-9 h-9 items-center flex justify-center ms-1'>
          <svg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M16.284 5.28385L6.85809 16.6071L6.83197 10.7409L1.7648 7.78525L16.284 5.28385Z'
                  stroke='#2E61D6'
                  strokeWidth='2' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        </button>
      </Form>
    </div>

    <Modal
      onCancel={() => setVisible(false)}
      open={visible}
      footer={<Space>
        <button
          className='bg-white cursor-pointer border border-slate-200 hover:bg-slate-200 text-dark px-5 py-2 rounded-xl'
          onClick={() => setVisible(false)}>
          Hủy
        </button>
        <button
          className='bg-blue-700 cursor-pointer hover:bg-blue-600 text-white px-5 py-2 rounded-xl shadow-md'
          form='save-note' type='submit'>Lưu
        </button>
      </Space>}
      title='Ghi chú cá nhân'>
      <Form onFinish={onFinishSaveNote} id='save-note' form={formNote} labelCol={{ sm: 8 }} labelAlign='left'>
        <Form.Item name='title' label='Tiêu đề'>
          <Input />
        </Form.Item>
        <Form.Item name='content' label='Nội dung'>
          <Input.TextArea rows={3} />
        </Form.Item>
      </Form>
    </Modal>
  </>;
};
export default ChatBot;
