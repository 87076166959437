import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';

import Sidebar from './components/Sidebar';
import ContentRoute from './routes';
import HeaderMobile from './components/HeaderMobile';
import logo from '../../assets/img/logo.png';
import { CloseOutlined } from '@ant-design/icons';
import ChatBot from './components/ChatBot';

const Layout = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1028);
  const [collapsed, setCollapsed] = useState<boolean>(localStorage.getItem('collapsed') === '1');
  const authType = localStorage.getItem('typeLogin');

  useEffect(() => {
    const abortController = new AbortController();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1028);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      abortController.abort();
    };

  }, []);

  return <div className='flex relative'>
    {isMobile ? <Drawer
      width={250}
      bodyStyle={{ padding: 0 }}
      onClose={() => setShowDrawer(!showDrawer)}
      placement='left'
      open={showDrawer}
      closeIcon={false}
      title={<div className='flex justify-between items-center'>
        <img width={100} alt='Beva education' src={logo} />
        <button onClick={() => setShowDrawer(!showDrawer)}>
          <CloseOutlined />
        </button>
      </div>}>
      <Sidebar hideArrow showLogo={false} />
    </Drawer> : <div className={`fixed top-[28px] w-fit z-10 max-h-full hidden lg:block`}>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
    </div>}

    <div className={`${!isMobile && (collapsed ? 'ms-[100px]' : 'ms-[220px]')} flex-1 h-full`}>
      {/*<div className='lg:block hidden sticky top-0 bg-white'>*/}
      {/*  <Header />*/}
      {/*</div>*/}
      <div className='fixed w-full shadow-lg z-all top-0 pb-0 lg:hidden md:block'>
        <HeaderMobile
          open={showDrawer}
          onOpen={setShowDrawer} />
      </div>
      <div className={`min-h-sidebar lg:px-0 px-3 lg:mt-0 mt-24`}>
        <ContentRoute />
      </div>
    </div>
    {authType === 'student' && <ChatBot />}
  </div>;
};
export default Layout;
