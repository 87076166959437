import { debounce } from "lodash";
import * as React from "react";
import Flex from "src/editor/app/components/Flex";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Heading } from "../../../../student/block/editor/shared/editor/lib/getHeadings";

import type { Editor as TEditor } from "src/editor/app/editor/index";
import Container from "../../../../student/block/editor/app/scenes/Document/components/Container";
import Editor from "../../../../student/block/editor/app/scenes/Document/components/Editor";
import Theme from "../../../../student/block/editor/app/components/Theme";
import { message } from "antd";
import { INotes_TYPES } from "src/redux/types/User";
import { reqDetaillCourseNotesChildren, reqDetaillCourseNotesParent, reqUpdateCourseNotesChildren, reqUpdateCourseNotesParent } from "src/redux/services/student";


type Props = {
  onCreateLink?: (title: string) => Promise<string>;
  onSearchLink?: (term: string) => any;
  isLite?: boolean;
  defaultValue?: string;
  id?: any;
  title?: string;
  notesId?: any;
  onGetList?: (notesId: string) => void;
  typeNotes?: string;
  hideTitle?: boolean
};
// const defaultValue = "## Tổng quan\n\n\n:::info\nMã sản phẩm: **LM-AI/1.0** (Ra mắt: 2021)\n\n:::\n\n***Tổng quan chức năng AI HUB v1.0 & v2.0:***\n\n* AI Camera Hub cũng có thể thay thế HC/LC, đóng vai trò như một bộ điều khiển trung tâm quản lý các thiết bị trong nhà\n* Lấy hình ảnh từ Camera IP trong cùng mạng Local , sau đó xử lý hình ảnh bằng thuật toán AI. \n* Có thể sử dụng sự kiện phát hiện xâm nhập của Camera AI để kích hoạt Rule an ninh.\n* Xem trực tiếp hình ảnh lấy từ Camera thông qua App Lumi Life.\n* Cấu hình và điều khiển các thiết bị Zigbee/Bluetooth Mesh của Lumi\n* Xử lý các logic Rule/Cảnh/Lịch/Nhóm đảo chiều giống như HC.\n* Tích hợp cổng đầu ra tiếp điểm khô để điều khiển tải điện AC.\n* Hình thức lưu trữ video: ***Lưu tóm tắt hoặc lưu đầy đủ sự kiện***.\n\n "

class NoteCourse extends React.Component<Props> {
  editor = React.createRef<TEditor>();

  state = {
    isUploading: false,
    title: '',
    headings: [],
    editorTextP: '',
    isSaving: false,
    prevEditorText: '',
    notesId: this.props.id,
  }
  getEditorText: () => string = () => this.state.editorTextP ?? '';

  componentDidMount() {
    this.state.notesId && this.getDetailNote();
  }

  componentDidUpdate(prevProps: Props) {

  }
  getDetailNote = async () => {
    try {
      const responseNotes = this.props.typeNotes == 'parent' ? await reqDetaillCourseNotesParent({ courseNoteId: this.state.notesId }) :

      await reqDetaillCourseNotesChildren({ noteId: this.state.notesId });
      this.setState({
        title: responseNotes.data.data.title,
        editorTextP: responseNotes.data.data.content,
        prevEditorText: responseNotes.data.data.content,
      })
    }
    catch (e) {
      message.error(e.message);
    }
  };
  componentWillUnmount() {
    this.autosave.cancel();
    this.onSave()
      .catch((error) => {
        message.error('Lưu notes thất bại!');
      });
  }

  onSave = async () => {
    if (this.state.isSaving) {
      return Promise.resolve();
    }
    const text = this.getEditorText && this.getEditorText();
    const editorText = this.getEditorText() && this.getEditorText().trim();

    if ((editorText === "" || editorText === "#" || editorText === "\\")) {
      return Promise.resolve();
    }
    if ((editorText == (this.state.prevEditorText ?? '').trim()) ) {
      return Promise.resolve();
    }

    this.setState({ isSaving: true });
    try {
      if (this.state.notesId) {
        const response = this.props.typeNotes=='parent'?await reqUpdateCourseNotesParent({
          courseNoteId: this.state.notesId,
          content: text,
        }) : await reqUpdateCourseNotesChildren({
          noteId: this.state.notesId,
          content: text,
        })  ;
        response.status !== 200 && message.error('Update notes thất bại!');
      }
      this.setState({
        isSaving: false,
        isEditorDirty: false,
        prevEditorText: text,
      });
      return Promise.resolve();
    } catch (e) {
      message.error('Update notes thất bại!');
      return Promise.reject(e);
    }
  };

  autosave = debounce(() => {
    this.onSave();
  }, 3000);

  onFileUploadStart = () => {
    this.setState({ isUploading: true });

  };

  onFileUploadStop = () => {
    this.setState({ isUploading: false });
  };

  onChange = (getEditorText: () => string) => {
    this.getEditorText = getEditorText;
    // this.updateIsDirty();
    this.autosave();
  };

  onHeadingsChange = (headings: Heading[]) => {
    this.setState({ headings });
  };

  onChangeTitle = (value: string) => {
    // this.updateIsDirty();
    this.autosave();
  };

  render() {
    return (
      <div>
        <Theme>
          <MaxWidth
            archived={false}
            showContents={true}
            isEditing={true}
            isFullWidth={true}
            column
            auto
          >

            <Flex auto={true} reverse>
              <Editor
                // key={embedsDisabled ? "disabled" : "enabled"}
                key={"enabled"}
                value={this.state.editorTextP}
                ref={this.editor}
                hideTitle={this.props.hideTitle}
                title={this.state.title}
                // template={document.isTemplate}
                // value={readOnly ? document.text : undefined}
                defaultValue={this.state.editorTextP}
                embedsDisabled={false}
                // onSynced={this.onSynced}
                onFileUploadStart={this.onFileUploadStart}
                onFileUploadStop={this.onFileUploadStop}
                onCreateLink={this.props.onCreateLink}
                onChangeTitle={this.onChangeTitle}
                onChange={this.onChange}
                onHeadingsChange={this.onHeadingsChange}
                onSave={this.onSave}
                // onCancel={this.goBack}
                readOnly={false}
                readTitleOnly={true}
                readOnlyWriteCheckboxes={false}
              >
              </Editor>
              {/* <Contents
            headings={this.state.headings}
            isFullWidth={true}
          /> */}
            </Flex>
          </MaxWidth>
        </Theme>
      </div>
    );
  }
}

const Background = styled(Container)`
  background: ${(props) => props.theme.background};
  transition: ${(props) => props.theme.backgroundTransition};
`;

const ReferencesWrapper = styled.div<{ isOnlyTitle?: boolean }>`
  margin-top: ${(props) => (props.isOnlyTitle ? -45 : 16)}px;

  @media print {
    display: none;
  }
`;

type MaxWidthProps = {
  isEditing?: boolean;
  isFullWidth?: boolean;
  archived?: boolean;
  showContents?: boolean;
};

const MaxWidth = styled(Flex) <MaxWidthProps>`
  // Adds space to the gutter to make room for heading annotations
  padding: 0 32px;
  transition: padding 100ms;
  max-width: 100vw;
  width: 100%;
  padding-bottom: 16px;

  ${breakpoint("tablet")`
    margin: 0px auto 12px;
    max-width: ${(props: MaxWidthProps) =>
      props.isFullWidth
        ? "100vw"
        : `calc(64px + 46em + ${props.showContents ? "256px" : "0px"});`}
  `};

  ${breakpoint("desktopLarge")`
    max-width: ${(props: MaxWidthProps) =>
      props.isFullWidth ? "100vw" : `calc(64px + 52em);`}
  `};
`;

export default NoteCourse;
