import * as React from "react";
import { ThemeProvider } from "styled-components";
import { breakpoints } from "../../shared/styles";
import GlobalStyles from "../../shared/styles/globals";
import { dark, light, lightMobile, darkMobile } from "../../shared/styles/theme";
import useMediaQuery from "../hooks/useMediaQuery";
import { TooltipStyles } from "./Tooltip";

const Theme: React.FC = ({ children }) => {
  const resolvedTheme =  light;
  const resolvedMobileTheme = lightMobile;
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.tablet}px)`);
  const isPrinting = useMediaQuery("print");
  const theme = isPrinting
    ? light
    : isMobile
    ? resolvedMobileTheme
    : resolvedTheme;

  // React.useEffect(() => {
  //   window.dispatchEvent(
  //     new CustomEvent("theme-changed", {
  //       detail: { isDark: false },
  //     })
  //   );
  // }, [ui.resolvedTheme]);

  return (
    <ThemeProvider theme={theme}>
      <>
        <TooltipStyles />
        <GlobalStyles
          useCursorPointer={true}
        />
        {children}
      </>
    </ThemeProvider>
  );
};

export default Theme;
