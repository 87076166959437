import { IconName } from './components/Icon';
import Button from './components/Button';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { COMMON } from '../../redux/actions/actionTypes';

const PageNotFound = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(COMMON.SET_STATE({ pageTitle: 'Page not found' }));
  }, []);

  return <div 
  style={{   alignItems: 'center', justifyContent: 'center', display: 'flex' , height: '90vh', overflowY: 'hidden' }}
  >
    <div
    className='pb-14 w-full items-center md:gap-5 grid md:grid-cols-2'>
      <div className='col-span-1 hidden md:block mb-25 text-center'>
        <img className='max-w-fit w-4/5 m-auto' src={IconName.serviceError} />
      </div>
      <div className='md:col-span-1 text-center'>
      <svg className='mx-auto' width="371" height="132" viewBox="0 0 371 132" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.86 104.88V81.66L57.38 2.99999H94.28L39.38 81.66L22.46 76.62H120.92V104.88H0.86ZM67.46 129V104.88L68.54 76.62V55.2H102.2V129H67.46ZM183.775 131.52C172.975 131.52 163.375 128.94 154.975 123.78C146.695 118.62 140.155 111.18 135.355 101.46C130.555 91.74 128.155 79.92 128.155 66C128.155 52.08 130.555 40.26 135.355 30.54C140.155 20.82 146.695 13.38 154.975 8.22C163.375 3.05999 172.975 0.479992 183.775 0.479992C194.455 0.479992 203.935 3.05999 212.215 8.22C220.615 13.38 227.215 20.82 232.015 30.54C236.815 40.26 239.215 52.08 239.215 66C239.215 79.92 236.815 91.74 232.015 101.46C227.215 111.18 220.615 118.62 212.215 123.78C203.935 128.94 194.455 131.52 183.775 131.52ZM183.775 102.36C187.615 102.36 190.975 101.16 193.855 98.76C196.855 96.36 199.195 92.52 200.875 87.24C202.555 81.84 203.395 74.76 203.395 66C203.395 57.12 202.555 50.04 200.875 44.76C199.195 39.48 196.855 35.64 193.855 33.24C190.975 30.84 187.615 29.64 183.775 29.64C179.935 29.64 176.515 30.84 173.515 33.24C170.635 35.64 168.295 39.48 166.495 44.76C164.815 50.04 163.975 57.12 163.975 66C163.975 74.76 164.815 81.84 166.495 87.24C168.295 92.52 170.635 96.36 173.515 98.76C176.515 101.16 179.935 102.36 183.775 102.36ZM250.118 104.88V81.66L306.638 2.99999H343.538L288.638 81.66L271.718 76.62H370.178V104.88H250.118ZM316.718 129V104.88L317.798 76.62V55.2H351.458V129H316.718Z" fill="#2E61D6"/>
</svg>
        <div className='mt-5 justify-center flex font-semibold text-2xl'>
          <svg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M29.2649 8.775L20.3549 3.63C18.8999 2.79 17.0999 2.79 15.6299 3.63L6.73488 8.775C5.27988 9.615 4.37988 11.175 4.37988 12.87V23.13C4.37988 24.81 5.27988 26.37 6.73488 27.225L15.6449 32.37C17.0999 33.21 18.8999 33.21 20.3699 32.37L29.2799 27.225C30.7349 26.385 31.6349 24.825 31.6349 23.13V12.87C31.6199 11.175 30.7199 9.63 29.2649 8.775ZM16.8749 11.625C16.8749 11.01 17.3849 10.5 17.9999 10.5C18.6149 10.5 19.1249 11.01 19.1249 11.625V19.5C19.1249 20.115 18.6149 20.625 17.9999 20.625C17.3849 20.625 16.8749 20.115 16.8749 19.5V11.625ZM19.3799 24.945C19.3049 25.125 19.1999 25.29 19.0649 25.44C18.7799 25.725 18.4049 25.875 17.9999 25.875C17.8049 25.875 17.6099 25.83 17.4299 25.755C17.2349 25.68 17.0849 25.575 16.9349 25.44C16.7999 25.29 16.6949 25.125 16.6049 24.945C16.5299 24.765 16.4999 24.57 16.4999 24.375C16.4999 23.985 16.6499 23.595 16.9349 23.31C17.0849 23.175 17.2349 23.07 17.4299 22.995C17.9849 22.755 18.6449 22.89 19.0649 23.31C19.1999 23.46 19.3049 23.61 19.3799 23.805C19.4549 23.985 19.4999 24.18 19.4999 24.375C19.4999 24.57 19.4549 24.765 19.3799 24.945Z'
              fill='#FF7648' />
          </svg>
          Upps! access denied!
        </div>
        <p className='text-[#9295A3] mt-3 font-medium text-xl'>The page you requested was not found.</p>
        <Button className='w-fit px-10 m-auto bg-primary' text='Back to home' to='/' />
      </div>
    </div>
  </div>;
};
export default PageNotFound;
