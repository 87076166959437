import student, { publicStudent } from './student';
import mentor, { publicMentor } from './mentor';
import admin, { publicAdmin } from './admin';
import { IRouteRole } from '../../../redux/route/routers';


export const publicRoutes: IRouteRole = {
  student: publicStudent,
  mentor: publicMentor,
  admin: publicAdmin
};
const routers: IRouteRole = {
  mentor,
  admin,
  student
};
export default routers;
