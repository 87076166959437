import Header from '../../components/Header';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Drawer,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Space,
  Table,
  Timeline
} from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { COURSE, MANAGE_STUDENT, PATH } from '../../../../redux/actions/actionTypes';
import Icon, { IconName } from '../../components/Icon';
import TreeMenu from '../../components/TreeMenu';
import { AppState } from '../../../../redux/reducers';
import { AxiosResponse } from 'axios';
import { reqCourseVersionInfo } from '../../../../redux/services/v2/course';
import { Tab } from '@headlessui/react';
import { remakeCourse } from '../../../../utils/hepler';


const CareerPath = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formCourse] = Form.useForm();
  const { list, listAssign, info } = useSelector((app: AppState) => app.careerPath);
  const { allList, necessaryList, basicList } = useSelector((app: AppState) => app.course);
  const { listStudent } = useSelector((app: AppState) => app.manageStudent);


  const [visible, setVisible] = useState(false);
  const [courseVisible, setCourseVisible] = useState(false);
  const [stdVisible, setStdVisible] = useState(false);
  const [stdAssignVisible, setStdAssignVisible] = useState(false);
  const [stdSelected, setStdSelected] = useState<string[]>([]);

  const onFinish = (data: any) => {
    dispatch(PATH.CREATE(data));
    setVisible(false);
    form.resetFields();
  };

  const onFinishAddCourse = (data: any) => {
    const { ids, id } = data;
    if (!ids) {
      message.info('Vui lòng chọn khóa học!');
      return;
    }
    for (const course_revision_id of ids) {
      dispatch(PATH.ADD_COURSE({ course_revision_id, id }));
    }
    setCourseVisible(false);
    formCourse.resetFields();
  };

  const onApplyStdAssign = useCallback(() => {
    if (!stdSelected || stdSelected.length === 0) {
      message.info('Vui lòng chọn ít nhật 1 học viên!');
      return;
    }
    for (const student_id of stdSelected) {
      dispatch(PATH.ASSIGN_STD({ student_id, career_path_id: info.id }));
    }
    setStdVisible(false);
    dispatch(PATH.GET_LIST_ASSIGN());
  }, [stdSelected, info]);

  const onChangeVersion = async (node: any, item: any) => {
    const nodeIndex = allList.indexOf(node);
    if (item.id === 'original') {
      dispatch(COURSE.GET_LIST());
      return;
    }
    const response: AxiosResponse<any> = await reqCourseVersionInfo(item.id);
    const data = response.data.data;
    dispatch(COURSE.CHANGE_VERSION({
      data: {
        ...data,
        level: 0, key: item.id,
        versionId: item.id,
        hasVersion: node.versions.length > 0,
        hasChildren: node.children.length > 0,
        versions: node.versions,
        type: node.type_course,
        children: data.children ? remakeCourse(data.children, '', 1, data.id) : []
      },
      type: node.type_course,
      nodeIndex
    }));
  };

  const renderItem = (node: any) => {
    return <div className='grid grid-cols-5 py-1 w-full justify-between items-center'>
      <div className='col-span-4 flex items-top'>
        <span className={`text-[#040404] font-bold`}>{node.title}</span>
      </div>
    </div>;
  };

  const renderTimeLine = useMemo(() => {
    if (!info) {
      return <div className='flex h-full flex-col justify-center items-center'>
        <img src={IconName.noLive} width={200} alt='Empty' />
        <div className='mt-5 text-center'>
          <h4 className='text-dark uppercase text-lg font-bold'>Chú ý!</h4>
          <p>Chọn lộ trình học để xem</p>
        </div>
      </div>;
    }
    return <>
      <div className='mb-10 flex justify-between'>
        <h4 className='font-bold text-xl'>{info.title}</h4>
        <Space>
          <Button onClick={() => setStdAssignVisible(true)} type='primary'>Học viên</Button>
          <Button onClick={() => setCourseVisible(true)}>Chọn khóa học</Button>
        </Space>
      </div>
      <Timeline
        pending='Thêm khóa học...'
        mode='alternate'
        items={info.course_structure.map((item: any) => {
          return {
            children: <div className='flex flex-col'>
              <span className='font-medium text-base'>{item.title}</span>
              <span className='text-slate-500 text-sm'>version: {item.version}</span>
            </div>
          };
        })}
      />
    </>;
  }, [info]);

  const renderItemCourse = (node: any) => {

    return <div className='grid grid-cols-5 py-1 w-full justify-between items-center'>
      <div className='col-span-4 flex items-top'>
        <span className={`text-[#040404] font-bold`}>{node.title}</span>
      </div>
      <div className='col-span-1 flex justify-end'>
        <span className='w-fit'>
          {(node.level === 0 && node.versions.length > 0) ? <Dropdown menu={{
              items: node.versions && node.versions.map((item: any) => {
                return {
                  label: item.version,
                  value: item.id,
                  onClick: () => onChangeVersion(node, item)
                };
              })
            }} trigger={['click']}>
              <div className='flex items-center'>
                <span className='text-[12px] me-1 text-slate-500'>{node.version ?? 'Original'}</span>
                <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={1.5}
                     stroke='currentColor' className='w-3 h-3'>
                  <path strokeLinecap='round' strokeLinejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' />
                </svg>
              </div>
            </Dropdown>
            : <svg className='group-hover:block hidden' width='24' height='24' viewBox='0 0 24 24' fill='none'
                   xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10ZM19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z'
                stroke='#2E61D6' strokeWidth='1.5' />
              <path opacity='0.4'
                    d='M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z'
                    stroke='#2E61D6' strokeWidth='1.5' />
            </svg>}
        </span>
      </div>
    </div>;
  };

  useEffect(() => {
    dispatch(PATH.GET_LIST());
    dispatch(PATH.GET_LIST_ASSIGN());
    dispatch(COURSE.GET_LIST({}, true));
    dispatch(MANAGE_STUDENT.GET_ACCOUNT({}));
  }, []);

  return <>
    <Header title={<h3 className='text-3xl font-bold'>Lộ trình học</h3>} />
    <div className='max-h-box mt-3 grid gap-4 grid-cols-6'>
      <div className='col-span-2'>
        <div className='p-3 relative h-box overflow-y-auto shadow-box rounded-2xl bg-white'>
          <div className='flex justify-end mb-4'>
            <button
              onClick={() => setVisible(true)}
              className='p-2 flex items-center justify-center bg-primary text-white rounded-full'>
              <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path d='M8.88965 1V17.3346' stroke='white' strokeWidth='2' strokeLinecap='round'
                      strokeLinejoin='round' />
                <path d='M16.6875 9.21191L0.999674 9.21191' stroke='white' strokeWidth='2' strokeLinecap='round'
                      strokeLinejoin='round' />
              </svg>
            </button>
          </div>

          <TreeMenu
            defaultExpandAll={true}
            autoExpandParent={true}
            switcherHidden={true}
            showIcon={false}
            onSelect={(keys, { node, selected }: any) => {
              if (selected) {
                dispatch(PATH.GET_INFO({ id: node.id }));
              }
            }}
            switcherIcon={({ expanded, ...props }: any) => {
              return <Icon
                {...props}
                classIcon='mt-1.5'
                name={expanded ? IconName.switcherDown : IconName.switcherRight}
                width={expanded ? 16 : 9} height={expanded ? 16 : 9}
                showMask={false} />;
            }}
            titleRender={renderItem}
            treeData={list} />
        </div>
      </div>

      <div className='col-span-4'>
        <div className='bg-white relative overflow-y-auto h-box shadow-box rounded-2xl p-4'>
          {renderTimeLine}
        </div>
      </div>
    </div>

    <Modal
      onOk={() => form.submit()}
      onCancel={() => setVisible(false)}
      title='Thêm/sửa lộ trình' open={visible}>
      <Form onFinish={onFinish} labelCol={{ sm: 8 }} labelAlign='left' form={form}>
        <Form.Item
          rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
          name='title'
          label='Tiêu đề'>
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Vui lòng nhập miêu tả' }]}
          name='description'
          label='Mô tả'>
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          name='is_publish'
          initialValue={true}
          valuePropName='checked'
          label='Công khai'>
          <Checkbox>Học viên có thể xem</Checkbox>
        </Form.Item>
        <Form.Item
          initialValue={dayjs().format('YYYY')}
          name='term'
          label='Kì học'>
          <Input />
        </Form.Item>
      </Form>
    </Modal>

    <Modal
      onOk={() => formCourse.submit()}
      onCancel={() => setCourseVisible(false)}
      title='Chọn khóa học chuyên môn' open={courseVisible}>
      <Form form={formCourse} onFinish={onFinishAddCourse}>
        <Form.Item
          hidden
          name='ids'>
          <Input />
        </Form.Item>
        <Form.Item
          hidden
          initialValue={info?.id}
          name='id'>
          <Input />
        </Form.Item>


        <Tab.Group>
          <Tab.List className='flex space-x-1 rounded-xl bg-slate-200 p-1'>
            <Tab className={({ selected }) => {
              return `${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'} w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2`;
            }}
                 key='necessary'>
              Chuyển hóa
            </Tab>
            <Tab
              key='basic'
              className={({ selected }) => {
                return `${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'} w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2`;
              }}
            >
              Chuyên môn
            </Tab>
          </Tab.List>
          <Tab.Panels className='mt-5'>
            <Tab.Panel>
              <TreeMenu
                checkable
                checkStrictly={true}
                onCheck={({ checked }: any) => formCourse.setFieldsValue({ ids: checked })}
                selectable={false}
                showIcon={false}
                switcherIcon={({ expanded, ...props }: any) => {
                  return <Icon
                    {...props}
                    classIcon='mt-1.5'
                    name={expanded ? IconName.switcherDown : IconName.switcherRight}
                    width={expanded ? 16 : 9} height={expanded ? 16 : 9}
                    showMask={false} />;
                }}
                titleRender={renderItemCourse}
                treeData={necessaryList && necessaryList.filter((item: any) => item.hasVersion && item.hasChildren)} />
            </Tab.Panel>
            <Tab.Panel>

              <TreeMenu
                checkable
                checkStrictly={true}
                onCheck={({ checked }: any) => formCourse.setFieldsValue({ ids: checked })}
                selectable={false}
                showIcon={false}
                switcherIcon={({ expanded, ...props }: any) => {
                  return <Icon
                    {...props}
                    classIcon='mt-1.5'
                    name={expanded ? IconName.switcherDown : IconName.switcherRight}
                    width={expanded ? 16 : 9} height={expanded ? 16 : 9}
                    showMask={false} />;
                }}
                titleRender={renderItemCourse}
                treeData={basicList && basicList.filter((item: any) => item.hasVersion && item.hasChildren)} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

      </Form>
    </Modal>
    <Drawer
      extra={<Button onClick={() => setStdVisible(true)}>Thêm học viên</Button>}
      open={stdAssignVisible}
      onClose={() => setStdAssignVisible(false)}
      width={720}
      title='Danh sách học viên tham gia lộ trình học'>

      <Table
        dataSource={listAssign}
        columns={[
          { title: 'STT', dataIndex: 'id', key: 'id', render: (id, raw, index) => `${index + 1}` },
          {
            title: 'Học viên',
            dataIndex: 'student_id',
            key: 'student_id', render: (id, raw, index) => {
              const student = listStudent.list.find((std: any) => std.id === id);
              return student ? student.name : 'Anonymous';
            }
          },
          {
            title: 'Ngày áp dụng',
            dataIndex: 'assigned_at',
            key: 'assigned_at',
            render: (date: string) => dayjs(date).format('YYYY-MM-DD')
          },
          {
            title: 'Tiến độ',
            dataIndex: 'progress',
            key: 'progress',
            render: (progress: string) => `${progress}%`
          },
          {
            title: 'Hành động', key: 'action', render: (raw, index) => {
              return <Space>
                <Button>Chi tiết</Button>
              </Space>;
            }
          }
        ]}
      />
    </Drawer>

    <Drawer
      extra={<Button onClick={onApplyStdAssign}>Áp dụng</Button>}
      open={stdVisible}
      onClose={() => setStdVisible(false)}
      width={720}
      title='Danh sách học viên'>
      <Table
        rowKey='id'
        rowSelection={{
          onChange: (selectedRowKeys: any) => {
            setStdSelected(selectedRowKeys);
          }
        }}
        dataSource={listStudent.list}
        columns={[
          { title: 'STT', dataIndex: 'id', key: 'id', render: (id, raw, index) => `${index + 1}` },
          { title: 'Tên học viên', dataIndex: 'name', key: 'name' },
          { title: 'Email', dataIndex: 'emailLogin', key: 'emailLogin' }
        ]}
      />
    </Drawer>
  </>;
};
export default CareerPath;
;
