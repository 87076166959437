import { MenuProps } from 'antd/lib';
import { Dropdown, TreeProps } from 'antd';
import Icon, { IconName } from '../../../components/Icon';
import TreeMenu from '../../../components/TreeMenu';
import React, { FC } from 'react';
import { CourseNotesProps, TYPES_NOTES } from 'src/redux/actions/types';
import { EventDataNode } from 'antd/es/tree';

export interface DataNode {
  key: string;
  title: string;
  children?: DataNode[];
  type?: string;
  collectionPersonalNoteId?: string;
  parentPersonalNoteId?: string;
};

interface TProps extends TreeProps {
  setDropdownOpen: (value: React.SetStateAction<boolean>) => void;
  setNotesInfo: (value: React.SetStateAction<CourseNotesProps | undefined>) => void;
  onDelete: (id: string, type: string) => void;
  dataNodes: DataNode[];
  onSelect: (selectedKeys: React.Key[], info: {
    event: 'select';
    selected: boolean;
    node: EventDataNode<DataNode>;
    selectedNodes: DataNode[];
    nativeEvent: MouseEvent;
  }) => void;
  selectedKeys: React.Key[];
}

const TreeList: FC<TProps> = ({
  dataNodes,
  setDropdownOpen,
  setNotesInfo,
  onDelete,
  onSelect,
  selectedKeys,
  ...props
}) => {
  const [focusKey, setFocusKey] = React.useState<string | number>();
  return <TreeMenu
    {...props}
    titleRender={(node: any) => {
      const fontSize = node.type === 'parent' ? 'text-[22px]' : node.type === 'group' ? 'text-[18px]' : 'text-[15px]';
      const fonWeight = node.type === 'parent' ? 'font-medium' : '';
      const fontColor = node.type === 'parent' ? 'text-primary' : 'text-black';
      const items: MenuProps['items'] = [
        {
          key: '1',
          label: <span> Thêm ghi chú mới</span>,
          onClick: () => {
            setDropdownOpen(false);
            if (node.type == 'parent') {
              setNotesInfo({
                collectionPersonalNoteId: node.key,
                typeNotes: TYPES_NOTES.CREATE_NOTES,
                parentPersonalNoteId: ''
              });
            } else {
              setNotesInfo({
                collectionPersonalNoteId: node.collectionPersonalNoteId,
                parentPersonalNoteId: node.parentPersonalNoteId,
                typeNotes: TYPES_NOTES.CREATE_NOTES
              });
            }
          }
        },
        {
          key: '2',
          label: <span>Xoá</span>,
          onClick: () => onDelete(node.key, node.type)
        }
      ];
      return <div
        className='grid grid-cols-5 w-full justify-between items-center'>
        <div className='col-span-3 flex items-top'>
          <span className={`${fontSize} ${fonWeight} ${fontColor}`}>{node.title}</span>
        </div>
        <div className='col-span-2 justify-end flex items-center'>
          {node.key == focusKey ? <Dropdown
            menu={{ items }}
            trigger={['click']}
            onOpenChange={(open) => {
              setDropdownOpen(open);
            }}
          >
            <div onClick={e => e.preventDefault()}>
              <Icon classIcon='ms-3' showMask={false} width={24} height={24} name={IconName.more} />
            </div>
          </Dropdown> : <div/>}
        </div>
      </div>;
    }}
    defaultSelectedKeys={[dataNodes[0].key]}
    onSelect={onSelect}
    selectedKeys={selectedKeys}
    onClick={(e) => e.preventDefault()}
    treeData={dataNodes}
    defaultExpandAll={true}
    onMouseEnter={(e) => setFocusKey(e.node.key)}
    switcherIcon={({ expanded, ...props }: any) => {
      return props.data.key == focusKey ? <Icon
        {...props}
        name={expanded ? IconName.switcherDown : IconName.switcherRight}
        width={expanded ? 16 : 9} height={expanded ? 16 : 9}
        showMask={false} /> : <div />;
    }} />;
};
export default TreeList;
