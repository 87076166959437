import { AdminRoute, IMenuRoute, MentorRoute, StudentRoute } from '../contants/routeRole';

export const reqGetMenu = async (userRole: string) => {
  switch (userRole) {
    case 'admin':
      return AdminRoute;
    case 'mentor':
      return MentorRoute;
    case 'student':
      return StudentRoute;
    default:
      return [];
  }
};
