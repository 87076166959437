import { COMMON_SET_DOCUMENT, COMMON_SET_STATE } from '../actions/types';

export interface ICommonState {
  locale: string;
  loading: boolean;
  menus: any;
  closeModal: boolean;
  title: string;
  pageTitle: string,
  headerInline: boolean
}

const initialState = {
  locale: 'vi',
  loading: false,
  menus: [],
  closeModal: true,
  title: 'Beva traning',
  pageTitle: 'Dashboard',
  headerInline: false
};
export const commonReducer = (state = initialState, action: any): ICommonState => {
  switch (action.type) {
    case COMMON_SET_STATE:
    case COMMON_SET_DOCUMENT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default commonReducer;
