import viLang from './entries/vi-VN';

interface IAppLocale {
  [key: string]: any;
}

const AppLocale: IAppLocale = {
  vi: viLang
};
export default AppLocale;
