import { ActionType } from '../actions/actionTypes';
import { ADMIN_STATE, DETAIL_ADMIN_SUCCESS, GET_LIST_ADMIN_SUCCESS } from '../actions/types';
import { IResponseDetailStudent } from '../types/Admin';



export interface IManageAdmintState {
  success: boolean,
  listAdmin: any,
  detailAdmin: IResponseDetailStudent,
  loading: boolean
}

const initialState: IManageAdmintState = {
  success: true,
  listAdmin: {},
  detailAdmin: {} as IResponseDetailStudent,
  loading: false,
};
const manageAdminReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_LIST_ADMIN_SUCCESS:
      return { ...state, listAdmin: action.payload.data };
    case DETAIL_ADMIN_SUCCESS:
      return { ...state, detailAdmin: action.payload.data };
      case ADMIN_STATE:
          return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default manageAdminReducer;
