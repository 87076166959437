import React, { FC, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Icon, { IconName } from './Icon';
import user from '../../../assets/img/user.png';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH } from '../../../redux/actions/actionTypes';
import { AppState } from '../../../redux/reducers';

interface TProps {
  showSearch?: boolean,
  title?: React.ReactNode,
  titleRight?: React.ReactNode,
  className?: string
}

const Header: FC<TProps> = ({ showSearch = false, title = '',  titleRight = '', className = '' }) => {
  const dispatch = useDispatch();
  const { hasNoty } = useSelector((app: AppState) => app.auth);
  const [search, setSearch] = useState(false);


  return <div className={`lg:flex hidden h-14 min-w-full w-full ${className}`}>
    <div className='grid md:gap-4 gap-y-4 2xl:grid-cols-8 xl:grid-cols-7 md:grid-cols-3 w-full'>
      <div className='2xl:col-span-3 xl:col-span-3 md:col-span-1 flex justify-start'>
        {title}
        <div className='flex items-center'>
          <div className={showSearch ? 'relative ms-4' : 'hidden'}>
            <input placeholder='Tìm kiếm...' className={`duration-200 rounded-full px-3 h-12 ${!search && 'hidden'}`} />
            <button
              onClick={() => setSearch(!search)}
              className={`${search && 'absolute right-0 top-0 m-auto'} w-12 h-12 flex justify-center items-center cursor-pointer rounded-full bg-white hover:bg-slate-200`}>
              <Icon showMask={false} classIcon='h-auto' name={IconName.search} />
            </button>
          </div>
        </div>
      </div>
      <div className='2xl:col-span-5 xl:col-span-4 col-span-12'>
       <div className={`flex ${titleRight ? 'justify-between' : 'justify-end'}`}>
         {titleRight}
         <div className='flex items-center justify-end z-all'>
           <div className='relative me-3'>
             <img alt='' src={IconName.ring} width={34} height={32} />
             {hasNoty && <img alt='' className='absolute -top-0 -right-0' src={IconName.dotNoty} />}
           </div>
           <img
             width={60}
             height={60}
             className='me-1 inline-block h-12 w-12 rounded-full ring-2 ring-white'
             src={user}
             alt='beva' />
           <Menu as='div' className='relative inline-block text-left'>
             <Menu.Button
               className='inline-flex w-full items-center justify-center ml-3 font-medium text-xl'>
               <span>{localStorage.getItem('name') ?? 'Guest'}</span>
               <svg className='ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100' width='24' height='24'
                    viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                 <path
                   d='M17.0004 7H7.00036C6.82211 7.00064 6.64725 7.04882 6.49383 7.13957C6.34041 7.23031 6.21397 7.36035 6.12756 7.51625C6.04115 7.67216 5.99789 7.8483 6.00225 8.0265C6.00661 8.20469 6.05843 8.3785 6.15236 8.53L11.1524 16.53C11.2422 16.6738 11.3672 16.7924 11.5156 16.8746C11.6639 16.9569 11.8308 17 12.0004 17C12.17 17 12.3368 16.9569 12.4851 16.8746C12.6335 16.7924 12.7585 16.6738 12.8484 16.53L17.8484 8.53C17.943 8.37863 17.9953 8.20469 18 8.02625C18.0047 7.8478 17.9615 7.67136 17.875 7.51523C17.7885 7.3591 17.6617 7.22898 17.5079 7.13838C17.3541 7.04778 17.1789 7 17.0004 7Z'
                   fill='#040404' />
               </svg>
             </Menu.Button>
             <Transition
               enter='transition ease-out duration-100'
               enterFrom='transform opacity-0 scale-95'
               enterTo='transform opacity-100 scale-100'
               leave='transition ease-in duration-75'
               leaveFrom='transform opacity-100 scale-100'
               leaveTo='transform opacity-0 scale-95'
             >
               <Menu.Items
                 className='z-all absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                 <div className='px-1 py-1 '>
                   <Menu.Item>
                     <button className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                       Tài khoản
                     </button>
                   </Menu.Item>
                   <Menu.Item>
                     <button className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                       Cài đặt
                     </button>
                   </Menu.Item>
                   <Menu.Item>
                     <button
                       onClick={() => dispatch(AUTH.LOGOUT())}
                       className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                       Đăng xuất
                     </button>
                   </Menu.Item>
                 </div>
               </Menu.Items>
             </Transition>
           </Menu>
         </div>
       </div>
      </div>
    </div>
  </div>;
};
export default Header;
