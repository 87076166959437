import { QUIZ_STATE } from '../actions/types';
import { InfoQuiz, QuizCollection } from '../types/Admin';

export interface IQuizState {
  collections: any;
  questions: any;
  loading: boolean;
  quizListCollection?: QuizCollection;
  quizCollectionDetail?:QuizCollection;
  quizInfo?: InfoQuiz;
  loaded: boolean;
}

const initialState: IQuizState = {
  collections: [],
  questions: [],
  loading: false,
  quizListCollection: undefined,
  quizCollectionDetail: undefined,
  quizInfo: undefined,
  loaded: false,
};
export const quizReducer = (state = initialState, action: any): IQuizState => {
  switch (action.type) {
    case QUIZ_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default quizReducer;
