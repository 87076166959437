import React, { FC } from 'react';
import { history } from '../../../redux/store';

interface TProps {
  color?: 'primary | green';
  text: string;
  to?: string;
  className?: string;
}

const Button: FC<TProps> = ({ text, className, to = '#' }) => {
  return <button
    onClick={() => to && history.push(to)}
    className={`hover:drop-shadow-primary bg-primary mt-7 rounded-2xl h-12 text-white w-full ${className}`}>
    {text}
  </button>;
};
export default Button;
