import { axiosAuth, axiosCatch } from '../../../utils/AxiosHelper';

const getType = () => {
  const typeLogin = localStorage.getItem('typeLogin');
  return typeLogin === 'mentor' ? 'mentor' : 'admin';
};

export const reqCourseAdd = async (data: any) => {
  return await axiosAuth(true).post(`/${getType()}/course/add`, data).catch(axiosCatch);
};

export const reqCourseUpdate = async (data: any) => {
  return await axiosAuth(true).post(`/${getType()}/course/update`, data).catch(axiosCatch);
};
export const reqCourseDelete = async (data: any) => {
  return await axiosAuth(true).post(`/${getType()}/course/delete-version`, data).catch(axiosCatch);
};

export const reqCourseInfo = async (id: string) => {
  return await axiosAuth(true).get(`/${getType()}/course/info`, { params: { id } }).catch(axiosCatch);
};
export const reqCoursePublish = async (data: any) => {
  return await axiosAuth(true).post(`/${getType()}/course/publish`, data).catch(axiosCatch);
};

export const reqCourseList = async (params = {}) => {
  return await axiosAuth(true).get(`/${getType()}/course/list`, { params }).catch(axiosCatch);
};

export const reqCourseVersionList = async (courseId: string) => {
  return await axiosAuth(true).get(`/${getType()}/course/list-version`, { params: { id: courseId } }).catch(axiosCatch);
};
export const reqCourseVersionCreate = async (data: any) => {
  return await axiosAuth(true).post(`/${getType()}/course/create-version`, data).catch(axiosCatch);
};
export const reqCourseVersionInfo = async (versionId: string) => {
  return await axiosAuth(true).get(`/${getType()}/course/info-version`, {
    params: { id: versionId }
  }).catch(axiosCatch);
};


export const reqLessonList = async (params = {}) => {
  return await axiosAuth(true).get(`/${getType()}/lesson/list`, { params }).catch(axiosCatch);
};
export const reqLessonAdd = async (data: any) => {
  return await axiosAuth(true).post(`/${getType()}/lesson/add`, data).catch(axiosCatch);
};
export const reqLessonUpdate = async (data: any) => {
  return await axiosAuth(true).post(`/${getType()}/lesson/update`, data).catch(axiosCatch);
};
export const reqLessonInfo = async (id: string) => {
  return await axiosAuth(true).get(`/${getType()}/lesson/info`, { params: { id } }).catch(axiosCatch);
};
