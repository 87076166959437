import dayjs from 'dayjs';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './modules/calendar.module.scss';
import { Badge, DatePicker, Form, Input, Modal, Select, Space, TimePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SCHEDULE } from '../../../redux/actions/actionTypes';
import { TScheduleAdd } from '../../../redux/types/User';
import { AppState } from '../../../redux/reducers';
import CalendarForm from './CalendarForm';

interface TProps {
  onClick?: (day: any) => void;
  showBtnAdd?: boolean;
}

const Calendar: FC<TProps> = ({ onClick, showBtnAdd }) => {
  const dispatch = useDispatch();
  const { acLoad, schedules } = useSelector((app: AppState) => app.schedule);

  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [activeDay, setActiveDay] = useState<any>(dayjs());
  const [open, setOpen] = useState<boolean>(false);

  const now = dayjs();


  const days = [
    { key: 'Sun', name: 'Sunday' },
    { key: 'Mon', name: 'Monday' },
    { key: 'Tue', name: 'Tuesday' },
    { key: 'Wed', name: 'Wednesday' },
    { key: 'Thu', name: 'Thursday' },
    { key: 'Fri', name: 'Friday' },
    { key: 'Sat', name: 'Saturday' }
  ];

  const prevMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, 'month'));
  };

  const nextMonth = () => {
    setCurrentMonth(currentMonth.add(1, 'month'));
  };
  const renderDay = useMemo(() => {
    return activeDay.format('dddd');
  }, [activeDay]);

  useEffect(() => {
    dispatch(SCHEDULE.LIST({}));
  }, []);

  const renderCalendar = useMemo(() => {
    const monthStart = currentMonth.startOf('month');
    const monthEnd = currentMonth.endOf('month');
    const startDate = monthStart.startOf('week');
    const endDate = monthEnd.endOf('week');
    const calendar = [];
    let day = startDate;

    while (day <= endDate) {
      const week = [];
      for (let i = 0; i < 7; i++) {
        const sameMonth = day.isSame(currentMonth, 'month');
        const sameDay = day.isSame(now, 'day');
        const sameClicked = !sameDay && day.isSame(activeDay, 'day');
        const cloneDay = day;
        week.push(<div className='col-span-1 text-center'>
            <button
              onClick={() => {
                if (sameClicked) {
                  setActiveDay(now);
                  return;
                }
                setActiveDay(cloneDay);
              }}
              className={`hover:cursor-pointer hover:bg-slate-200 rounded-full w-8 h-8 text-sm ${sameClicked && 'bg-slate-200'} ${sameDay && 'bg-[#9BA1FF] text-white'} ${sameMonth ? '' : 'text-slate-500'}`}>
              {day.format('D')}
            </button>
          </div>
        );
        day = day.add(1, 'day');
      }
      calendar.push(
        <div className='grid my-3 grid-cols-7'>
          {week}
        </div>
      );
    }

    return calendar;
  }, [currentMonth, activeDay]);

  return <div className={`bg-white ${styles.calendar}`}>
    <div className='flex items-center justify-between mb-3'>
      <h3 className='text-2xl'>
        {currentMonth.format('MMMM YYYY')}
        <span className='ms-3 text-body'>{renderDay}</span>
      </h3>
      <div>
        <button className='me-3' onClick={prevMonth}>
          <svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M7.0002 15.4139L0.293201 8.70694C0.200256 8.61415 0.126518 8.50394 0.0762072 8.38263C0.0258964 8.26131 0 8.13127 0 7.99994C0 7.8686 0.0258964 7.73856 0.0762072 7.61724C0.126518 7.49593 0.200256 7.38573 0.293201 7.29294L7.0002 0.585938L8.4142 1.99994L2.4142 7.99994L8.4142 13.9999L7.0002 15.4139Z'
              fill='#9295A3' />
          </svg>
        </button>
        <button onClick={nextMonth}>
          <svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M1.99994 15.4139L0.585938 13.9999L6.58594 7.99994L0.585938 1.99994L1.99994 0.585938L8.70694 7.29294C8.79988 7.38573 8.87362 7.49593 8.92393 7.61724C8.97424 7.73856 9.00014 7.8686 9.00014 7.99994C9.00014 8.13127 8.97424 8.26131 8.92393 8.38263C8.87362 8.50394 8.79988 8.61415 8.70694 8.70694L1.99994 15.4139Z'
              fill='#040404' />
          </svg>
        </button>
      </div>
    </div>
    <div className='grid grid-cols-7'>
      {days.map((item: any) => <div key={item.key} className='text-center text-[#4DC591]'>{item.key}</div>)}
    </div>
    {renderCalendar}
    {showBtnAdd && <button
      onClick={() => setOpen(!open)}
      className='w-full bg-primary shadow-md hover:shadow-primary flex items-center justify-center text-white rounded-2xl py-3'>
      <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M12.39 6.94H7.658V11.594H5.474V6.94H0.742V4.886H5.474V0.206H7.658V4.886H12.39V6.94Z'
              fill='white' />
      </svg>
      <span className='ms-3'>Add new task</span>
    </button>}
    <CalendarForm
      activeDay={activeDay}
      setOpen={setOpen}
      acLoad={acLoad}
      open={open} />
  </div>;
};
export default Calendar;
