import { TrashIcon, InsertAboveIcon, InsertBelowIcon } from "outline-icons";
import { EditorState } from "prosemirror-state";
import * as React from "react";
import { MenuItem } from "../../../shared/editor/types";
import { globalIntl } from "src/utils/hepler";

export default function tableRowMenuItems(
  state: EditorState,
  index: number,
): MenuItem[] {
  return [
    {
      name: "addRowAfter",
      tooltip: globalIntl("editor.addRowBefore"),
      icon: <InsertAboveIcon />,
      attrs: { index: index - 1 },
      active: () => false,
      visible: index !== 0,
    },
    {
      name: "addRowAfter",
      tooltip: globalIntl("editor.addRowAfter"),
      icon: <InsertBelowIcon />,
      attrs: { index },
      active: () => false,
    },
    {
      name: "separator",
    },
    {
      name: "deleteRow",
      tooltip: globalIntl("editor.deleteRow"),
      icon: <TrashIcon />,
      active: () => false,
    },
  ];
}
