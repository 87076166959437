import React, { useEffect } from 'react';

import { IconName } from '../components/Icon';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { AppState } from '../../../redux/reducers';
import { LoginData } from '../../../redux/types/User';
import { AUTH } from '../../../redux/actions/actionTypes';
import Input from '../components/Input';
import Button from '../components/Button';

const Login = (props: any) => {

  const [form] = useForm();
  const dispatch = useDispatch();
  const location = useLocation();

  const { loading, token } = useSelector((app: AppState) => app.auth);

  function getAccountType(str: string): string {
    if (str.includes('/login-admin')) {
      return 'admin';
    }
    if (str.includes('/login-mentor')) {
      return 'mentor';
    }
    if (str.includes('/login')) {
      return 'student';
    }
    return '';
  }

  const onFinish = (data: LoginData) => {
    data.typeLogin = getAccountType(location.pathname);
    dispatch(AUTH.LOGIN(data));
  };

  useEffect(() => {
    if (token) {
      props.history.push('/');
    }
  }, [token, props.history]);

  return <div 
  className='h-screen flex items-center justify-center overflow-hidden'>
    <div className='grid lg:grid-cols-2 overflow-hidden rounded-3xl lg:w-4/5 w-full bg-white '>
      <div className='lg:col-span-1'>
        <div className='login-layout'></div>
      </div>
      <div className='lg:col-span-1'>
        <div className='px-12 py-16'>
          <h1 className='text-primary font-medium text-[32px]'>Đăng nhập</h1>
          <Form onFinish={onFinish}
                layout='vertical'
                size='large'
                form={form}>
            <Input
              rules={[{ required: true }]}
              label='Email'
              name='email'
              placeholder='your-mail@beva.edu.vn' />
            <Input
              rules={[{ required: true }]}
              label='Password'
              name='password'
              placeholder='Enter your password' />

            {/* <div className='flex mt-5'>
              <span>Forgot password ?</span>
              <button className='text-amber-500 ml-2'>Reset password</button>
            </div> */}
            <Button text='Đăng nhập'>
            </Button>
            <div className='flex justify-center mt-5'>
              <span>Quên mật khẩu ?</span>
              <button className='text-amber-500 ml-2'>
                {loading && <svg className='animate-spin h-5 w-5 mr-3 ...' viewBox='0 0 24 24'>
                </svg>}
                Lấy lại mật khẩu
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>;
};
export default Login;
