import { ListPersonalCollectionNotes, PersonalCollectionNotes } from "src/redux/types/User";

export function findParentId(data: ListPersonalCollectionNotes, childId: string): any | null {
    for (let item of data) {
      const parentId = findParentIdRecursive(item, childId);
      if (parentId) return item.id;
    }
    return null;
  }

  export function findParentIdRecursive(node: PersonalCollectionNotes, childId: string): string | null {
    if (node.id === childId) {
      return node.id;
    }
    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        const parentId = findParentIdRecursive(child, childId);
        if (parentId) return parentId;
      }
    }

    return null;
  }
