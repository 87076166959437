import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { reqScheduleCreate, reqScheduleDelete, reqScheduleList, reqScheduleUpdate } from '../services/schedule';
import { SCHEDULE_CREATE, SCHEDULE_DELETE, SCHEDULE_GET_LIST, SCHEDULE_UPDATE } from '../actions/types';
import { SCHEDULE } from '../actions/actionTypes';
import { ApiResponse } from '../../utils/AxiosHelper';


function* onGetTask({ payload }: any) {
  try {
    yield put(SCHEDULE.STATE({ loading: true }));
    const response: ApiResponse<any> = yield call(reqScheduleList, payload);
    yield put(SCHEDULE.STATE({
      schedules: response.data.data
    }));
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(SCHEDULE.STATE({ loading: false }));
  }
}

function* onCreateTask({ payload }: any) {
  try {
    yield put(SCHEDULE.STATE({ acLoad: true }));
    yield call(reqScheduleCreate, payload);
    message.success('Tạo lịch thành công');
    yield put(SCHEDULE.LIST({}));
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(SCHEDULE.STATE({ acLoad: false }));
  }
}


function* onUpdateTask({ payload }: any) {
  try {
    yield put(SCHEDULE.STATE({ acLoad: true }));
    yield call(reqScheduleUpdate, payload);
    yield put(SCHEDULE.LIST({}));
    message.success('Cập nhật lịch thành công');
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(SCHEDULE.STATE({ acLoad: false }));
  }
}

function* onDeleteTask({ payload }: any) {
  try {
    yield put(SCHEDULE.STATE({ acLoad: true }));
    yield call(reqScheduleDelete, payload);
    yield put(SCHEDULE.LIST({}));
    message.success('Xóa lịch thành công');
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(SCHEDULE.STATE({ acLoad: false }));
  }
}

export default function* rootSagas() {
  yield all([
    takeLatest(SCHEDULE_GET_LIST, onGetTask),
    takeLatest(SCHEDULE_DELETE, onDeleteTask),
    takeLatest(SCHEDULE_UPDATE, onUpdateTask),
    takeLatest(SCHEDULE_CREATE, onCreateTask)
  ]);
}
