import { nanoid } from '@ant-design/pro-components';
import { ChatListResponse, EChatStt, ETestSchedule, TestScheduleResponse } from '../types/Mentor';

export const reqGetChatList = async (): Promise<ChatListResponse> => {
  return {
    data: [
      { id: nanoid(), name: 'Nguyễn Văn Hiển', status: EChatStt.pending },
      { id: nanoid(), name: 'Nguyễn Đình Thắng', status: EChatStt.pending },
      { id: nanoid(), name: 'Nguyễn Đình Tân', status: EChatStt.pending },
      { id: nanoid(), name: 'Phạm Thái Hiền', status: EChatStt.pending }
    ],
    pageSize: 20,
    current: 1,
    total: 80
  };
};

export const reqGetTestList = async (): Promise<TestScheduleResponse> => {
  return {
    data: [
      {
        id: nanoid(),
        startAt: '09:00 20/12/2023',
        source: 'môn API',
        name: 'Nguyễn Văn Hiển',
        status: ETestSchedule.pending
      },
      {
        id: nanoid(),
        startAt: '09:00 20/12/2023',
        source: 'môn API',
        name: 'Nguyễn ReactJs Hiển',
        status: ETestSchedule.completed
      },
      {
        id: nanoid(),
        startAt: '09:00 20/12/2023',
        source: 'môn NodeJs',
        name: 'Nguyễn Văn Hiển',
        status: ETestSchedule.completed
      }
    ],
    pageSize: 20,
    current: 1,
    total: 80
  };
};
