import { ActionType } from '../actions/actionTypes';
import { AUTH_GET_PERMISSION_SUCCESS, AUTH_LOGIN_SUCCESS, AUTH_LOGOUT_SUCCESS } from '../actions/types';

export interface IAuhtState {
  token: string | null,
  user: any,
  initURL?: string | null,
  name?: string | null,
  userPermission?: any,
  roles?: any,
}

const initialState: IAuhtState = {
  token: null,
  user: null,
  initURL: '/',
};
const authReducer = (state = initialState, action: ActionType) => {  
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return { ...state, ...action.payload };
    case AUTH_LOGOUT_SUCCESS:
      return { ...initialState };
      case AUTH_GET_PERMISSION_SUCCESS:
        return { ...state, ...action.payload };

    default:
      return state;
  }
};
export default authReducer;
