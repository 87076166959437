import { ICourseResponse, TProcessData } from '../types/Mentor';
import { axiosAuth, axiosCatch, docApi } from '../../utils/AxiosHelper';
import { AxiosResponse } from 'axios';
import {
  AddCollectionNote,
  CreateCollectionPersonalNote,
  DeleteCollectionNote,
  DeletePersonalNote,
  ICollectionNote,
  IResPerCollectionNote,
  IResponseCreateCollectionPersonalNote,
  IResponseAddCollectionNote,
  IResponseDeleteCollectionNote,
  IUpdateCourseNoteChildrenData,
  IUpdateCourseNoteParentData,
  ResColllectionPersonalNoteInfo,
  ResponseListCourseNote,
  ResponseParentCourseNote,
  UpdateCollectionNote,
  UpdateCollectionPersonalNote,
  CreatePersonalNote,
  ResponseCreatePersonalNote,
  UpdatePersonalNote,
  InfoPersonalNote,
  ResponseInfoPersonalNote,
  ListPersonalNote,
  ResponseListPersonalNote,
  ResponseUpdatePersonalNote,
  ResponseUpdateCollectionPersonalNote
} from '../types/User';

export const reqGetStdCourse = async (params: any = {}): Promise<AxiosResponse<ICourseResponse>> => {
  return await axiosAuth().get('/student/course/list', { params }).catch(axiosCatch);
};
export const reqGetStdCourseAssign = async (params: any = {}): Promise<AxiosResponse<any>> => {
  return await axiosAuth().get('/api/career-path/list-assign', { params }).catch(axiosCatch);
};
export const reqGetStdCourseAssignInfo = async (params: any = {}): Promise<AxiosResponse<any>> => {
  return await axiosAuth(true).get('/student/course/info-version', { params }).catch(axiosCatch);
};
export const reqGetStdLessonAssignInfo = async (params: any = {}): Promise<AxiosResponse<any>> => {
  return await axiosAuth(true).get('/student/lesson/info-version', { params }).catch(axiosCatch);
};
export const reqUpdateStdLessonAssignProcess = async (data: any): Promise<AxiosResponse<any>> => {
  return await axiosAuth(true).post('/student/lesson/progress-update', data).catch(axiosCatch);
};

export const reqGetStdCourseInfo = async (params: any = {}): Promise<AxiosResponse<any>> => {
  return await axiosAuth().get('/student/course/info', { params }).catch(axiosCatch);
};
export const reqEnrollStdRegister = async (params: any = {}): Promise<AxiosResponse<any>> => {
  return await axiosAuth().post('/student/course/enroll', params).catch(axiosCatch);
};
export const reqGetArticle = async (docId: string): Promise<AxiosResponse<any>> => {
  return await docApi.post('/api/documents.info', { id: docId }).catch(axiosCatch);
};
export const reqUpdateProcess = async (data: TProcessData): Promise<AxiosResponse<any>> => {
  return await axiosAuth().post('/student/lesson/progress', data).catch(axiosCatch);
};
export const reqGetNotesCollection = async (): Promise<AxiosResponse<ICollectionNote>> => {
  return await axiosAuth().get('/student/collection-note/list').catch(axiosCatch);
};
export const reqAddNotesCollection = async (title: AddCollectionNote): Promise<AxiosResponse<IResponseAddCollectionNote>> => {
  return await axiosAuth().post('/student/collection-note/create', title).catch(axiosCatch);
};
export const reqDeleteNotesCollection = async (collectionNoteId: DeleteCollectionNote): Promise<AxiosResponse<IResponseDeleteCollectionNote>> => {
  return await axiosAuth().post('/student/collection-note/delete', collectionNoteId).catch(axiosCatch);
};
export const reqUpdateNotesCollection = async (payload: UpdateCollectionNote): Promise<AxiosResponse<{}>> => {
  return await axiosAuth().post('/student/collection-note/update', payload).catch(axiosCatch);
};

export const reqSaveMessage = async (message: any) => {
  return await axiosAuth().post('/gpt/save-message', message).catch(axiosCatch);
};
export const reqGetMessages = async (params: any): Promise<AxiosResponse<any>> => {
  return await axiosAuth().get('/gpt/messages', { params }).catch(axiosCatch);
};
export const reqGetHistories = async (params: any): Promise<AxiosResponse<any>> => {
  return await axiosAuth().get('/gpt/histories', { params }).catch(axiosCatch);
};
//new collection personal notes api
export const reqCreatePersonalNotesCollection = async (title: CreateCollectionPersonalNote): Promise<AxiosResponse<IResponseCreateCollectionPersonalNote>> => {
  return await axiosAuth().post('/student/collection-personal-note/create', title).catch(axiosCatch);
};

export const reqUpdatePersonalNotesCollection = async (payload: UpdateCollectionPersonalNote): Promise<AxiosResponse<ResponseUpdateCollectionPersonalNote>> => {
  return await axiosAuth().post('/student/collection-personal-note/update', payload).catch(axiosCatch);
};
export const reqInfoPersonalNotesCollection = async (params: any): Promise<AxiosResponse<ResColllectionPersonalNoteInfo>> => {
  return await axiosAuth().get('/student/collection-personal-note/info', { params }).catch(axiosCatch);
};
export const reqDeletePersonalNotesCollection = async (payload: DeletePersonalNote): Promise<AxiosResponse<{}>> => {
  return await axiosAuth().post('/student/collection-personal-note/delete', payload).catch(axiosCatch);
};
export const reqGetPersonalNotesCollection = async (): Promise<AxiosResponse<IResPerCollectionNote>> => {
  return await axiosAuth().get('/student/collection-personal-note/list').catch(axiosCatch);
};

//new personal notes api
export const reqCreatePersonalNotes = async (payload: CreatePersonalNote): Promise<AxiosResponse<ResponseCreatePersonalNote>> => {
  return await axiosAuth().post('/student/personal-note/create', payload).catch(axiosCatch);
};
export const reqUpdatePersonalNotes = async (payload: UpdatePersonalNote): Promise<AxiosResponse<ResponseUpdatePersonalNote>> => {
  return await axiosAuth().post('/student/personal-note/update', payload).catch(axiosCatch);
};
export const reqDeletePersonalNotes = async (payload: DeletePersonalNote): Promise<AxiosResponse<{}>> => {
  return await axiosAuth().post('/student/personal-note/delete', payload).catch(axiosCatch);
};
export const reqInfoPersonalNotes = async (params: InfoPersonalNote): Promise<AxiosResponse<ResponseInfoPersonalNote>> => {
  return await axiosAuth().get('/student/personal-note/info', { params }).catch(axiosCatch);
};
export const reqGetPersonalNotes = async (params: ListPersonalNote): Promise<AxiosResponse<ResponseListPersonalNote>> => {
  return await axiosAuth().get('/student/personal-note/list', { params }).catch(axiosCatch);
};
//new course notes
export const reqGetListCourseNotes = async (): Promise<AxiosResponse<ResponseListCourseNote>> => {
  return await axiosAuth().get('/student/course-note/list').catch(axiosCatch);
};
export const reqDetaillCourseNotesParent = async (params: any): Promise<AxiosResponse<ResponseParentCourseNote>> => {
  return await axiosAuth().get('/student/course-note/info', { params }).catch(axiosCatch);
};
export const reqDetaillCourseNotesChildren = async (params: any): Promise<AxiosResponse<ResponseParentCourseNote>> => {
  return await axiosAuth().get('/student/course-note/info-note', { params }).catch(axiosCatch);
};
export const reqUpdateCourseNotesParent = async (params: IUpdateCourseNoteParentData): Promise<AxiosResponse<ResponseParentCourseNote>> => {
  return await axiosAuth().post('/student/course-note/update', params).catch(axiosCatch);
};
export const reqUpdateCourseNotesChildren = async (params: IUpdateCourseNoteChildrenData): Promise<AxiosResponse<ResponseParentCourseNote>> => {
  return await axiosAuth().post('/student/course-note/update-note', params).catch(axiosCatch);
};
