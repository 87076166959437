import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import rootSaga from '../sagas/index';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, routeMiddleware];
const enhancers = [
  applyMiddleware(routerMiddleware(history), ...middlewares)
];
export const initStore = createStore(
  createRootReducer(history), // root reducer with router state
  {},
  composeWithDevTools(...enhancers)
);
export const store = createStore(
  createRootReducer(history), // root reducer with router state
  {},
  composeWithDevTools(...enhancers)
);
export default function configureStore(preloadedState: any) {



  sagaMiddleware.run(rootSaga);
  return store;
}
