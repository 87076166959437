import React, { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import styles from './modules/calendar.module.scss';
import { SCHEDULE } from '../../../redux/actions/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers';
import { Dropdown, Modal, Select } from 'antd';
import { TSchedule } from '../../../redux/types/User';
import Icon, { IconName } from './Icon';
import CalendarForm from './CalendarForm';
import { classBgBeforeColors, classBorderColors, classTextColors } from '../../../redux/contants';

interface TProps {
  schedules: TSchedule[];
}

const ScheduleCalendar: FC<TProps> = ({ schedules }) => {

  const dispatch = useDispatch();

  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [isMobile, setMobile] = useState(window.innerWidth <= 576);
  const [isWeek, setWeek] = useState(false);
  const [dayPicked, setDayPicked] = useState(dayjs());
  const { loading, acLoad } = useSelector((app: AppState) => app.schedule);
  const [open, setOpen] = useState<boolean>(false);
  const [action, setAction] = useState<string>('add');
  const [itemTrigger, setItemTrigger] = useState<TSchedule | undefined>();

  const now = dayjs();

  const prevMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, 'month'));
  };

  const nextMonth = () => {
    setCurrentMonth(currentMonth.add(1, 'month'));
  };

  const prevWeek = () => {
    setCurrentMonth(currentMonth.subtract((isMobile || isWeek) ? 1 : 2, 'weeks'));
  };

  const nextWeek = () => {
    setCurrentMonth(currentMonth.add((isMobile || isWeek) ? 1 : 2, 'weeks'));
  };

  const onclickMenu = (action: string, item: TSchedule) => {
    setAction(action);
    switch (action) {
      case 'update':
        setOpen(!open);
        setItemTrigger(item);
        break;
      case 'delete':
        Modal.confirm({
          title: 'Xóa sự kiện này?',
          onOk: () => {
            dispatch(SCHEDULE.DELETE({ id: item.id }));
          }
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const handleResize = () => {
      setMobile(window.innerWidth <= 576);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      abortController.abort();
    };

  }, []);

  const renderCalendar = useMemo(() => {
    const calendarDays = [];
    const range = (isMobile || isWeek) ? 7 : 14;
    const middleIndex = 6;
    for (let i = 0; i < range; i++) {
      const dayOffset = i - middleIndex;
      const day = currentMonth.add(dayOffset, 'day');
      const sameMonth = day.isSame(currentMonth, 'month');
      const sameDay = day.isSame(now, 'day');
      const clone = day;
      calendarDays.push(
        <div key={day.toString()}
             className={`flex ${sameDay ? styles.activeLine : ''} flex-col items-center`}>
          <span className={`${sameDay ? 'text-green' : 'text-body'} mb-4 mt-5`}>{day.format('ddd')}</span>
          <button
            onClick={() => setDayPicked(clone)}
            className={`${sameDay ? 'bg-[#9BA1FF] text-white' : 'bg-[#9295A3] bg-opacity-10'} ${!sameMonth && 'text-slate-500'} hover:cursor-pointer hover:bg-slate-200 rounded-full w-8 h-8 text-sm`}>
            {day.format('D')}
          </button>
        </div>
      );
    }
    return calendarDays;
  }, [currentMonth, isWeek, isMobile]);

  return <div className={`p-3 ${styles.calendar}`}>
    <div className='sticky z-30 py-3 top-0 bg-white'>
      <div className='flex justify-between items-center mb-3'>
        <div className='flex justify-start items-center'>
          <div className='m-0'>
          <span className='font-bold text-[32px] me-3'>
            {currentMonth.format('MMMM')}
          </span>
            <span className='text-lg'>
            {currentMonth.format('YYYY')}
          </span>
          </div>
          <div className='ms-4 mt-2'>
            <button className='me-3' onClick={prevMonth}>
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <g opacity='0.4'>
                  <path fillRule='evenodd' clipRule='evenodd'
                        d='M9.99634 13.333L4.66301 7.99967L9.99634 2.66634L11.333 3.99967L7.32967 7.99967L11.333 11.9997L9.99634 13.333Z'
                        fill='#2E61D6' />
                </g>
              </svg>
            </button>
            <button onClick={nextMonth}>
              <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path fillRule='evenodd' clipRule='evenodd'
                      d='M6.00366 2.66699L11.337 8.00033L6.00366 13.3337L4.66699 12.0003L8.67033 8.00033L4.66699 4.00033L6.00366 2.66699Z'
                      fill='#2E61D6' />
              </svg>
            </button>
          </div>
        </div>
        <div className=''>
          <Select
            onChange={(e) => setWeek(e === 'week')}
            style={{ width: 80 }}
            defaultValue='day'
            options={[{ label: 'Day', value: 'day' }, { label: 'Week', value: 'week' }]} />
        </div>
      </div>
      <div
        className='border-t-[1px] border-[#EFEFEF] flex justify-between items-end overflow-x-auto relative overflow-y-hidden'>
        <button className='' onClick={prevWeek}>
          <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M19.0002 23.4139L12.2932 16.7069C12.2003 16.6142 12.1265 16.5039 12.0762 16.3826C12.0259 16.2613 12 16.1313 12 15.9999C12 15.8686 12.0259 15.7386 12.0762 15.6172C12.1265 15.4959 12.2003 15.3857 12.2932 15.2929L19.0002 8.58594L20.4142 9.99994L14.4142 15.9999L20.4142 21.9999L19.0002 23.4139Z'
              fill='#040404' />
          </svg>
        </button>
        <div className='flex-1'>
          <div className={`grid ${isWeek || isMobile ? 'grid-cols-7' : 'grid-cols-14'} grid-rows-1`}>
            {renderCalendar}
          </div>
        </div>
        <button className='' onClick={nextWeek}>
          <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M12.9999 23.4139L11.5859 21.9999L17.5859 15.9999L11.5859 9.99994L12.9999 8.58594L19.7069 15.2929C19.7999 15.3857 19.8736 15.4959 19.9239 15.6172C19.9742 15.7386 20.0001 15.8686 20.0001 15.9999C20.0001 16.1313 19.9742 16.2613 19.9239 16.3826C19.8736 16.5039 19.7999 16.6142 19.7069 16.7069L12.9999 23.4139Z'
              fill='#040404' />
          </svg>
        </button>
      </div>
    </div>
    <div className='overflow-x-auto overflow-y-hidden max-w-screen-lg'>
      <div className='flex bg-white'>
        <div className='w-[100px] me-5'>
          {Array.from({ length: 24 }, (_, hour) => {
            return <div
              className='bg-[#9295A3] w-full rounded-full mb-[20px] flex justify-center items-center h-[40px] bg-opacity-10 text-center'
              key={hour}>
              <span className=''>{hour}:00</span>
            </div>;
          })}
        </div>
        <div className='min-h-full relative flex flex-1'>

          {Array.from({ length: 24 }, (_, hour) => {
            const top = 60 * hour + 20;
            return <div
              style={{ top, height: 1 }}
              className='absolute w-full border-b border-dashed border-[#EFEFEF]' key={hour} />;
          })}
          {schedules && schedules.map((item: TSchedule) => {
            const toDay = dayjs(dayPicked).format('D');
            const setDay = dayjs.unix(Number(item.day_in_week)).format('D');
            if (toDay !== setDay) {
              return;
            }
            const color = item.event_color || '#52c51a';
            const startNumber = Number(dayjs.unix(Number(item.begin_time)).format('HH'));
            const endNumber = Number(dayjs.unix(Number(item.end_time)).format('HH'));
            const diff = Math.ceil(endNumber - startNumber) + 1;
            const beginTime = dayjs.unix(Number(item.begin_time)).format('HH:mm');
            const endTime = dayjs.unix(Number(item.end_time)).format('HH:mm');
            const endMinute = Number(dayjs.unix(Number(item.end_time)).format('mm'));
            const sameTime = startNumber === endNumber;
            const height = sameTime ? 60 : 60 * diff - (endMinute > 30 ? 0 : 20);
            const top = 60 * startNumber;

            return <>
              <div
                style={{ top: `${top}px`, height: `${height}px` }}
                className={`rounded cursor-pointer text-sm absolute bg-white ${classBorderColors[color]} border-b-4 ${classTextColors[color]}`}>
                <div
                  className={`relative p-2 rounded overflow-hidden before:block before:absolute before:-inset-1 ${classBgBeforeColors[color]} before:bg-opacity-10 h-full min-w-[200px]`}>
                  <div className=''>
                    <p className='text-sm'>{item.title}</p>
                    <p className={sameTime ? 'hidden' : 'block text-sm'}>{item.description}</p>
                  </div>
                  <div className='bottom-0 mt-1 flex items-center'>
                    <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <g clipPath='url(#clip0_170_15161)'>
                        <circle cx='7' cy='7.91406' r='5.6875' stroke={color} strokeWidth='1.5' />
                        <path d='M7 4.85156V7.91406H8.75' stroke={color} strokeWidth='1.5' strokeLinecap='round'
                              strokeLinejoin='round' />
                      </g>
                      <defs>
                        <clipPath id='clip0_170_15161'>
                          <rect width='14' height='14' fill='white' transform='translate(0 0.914062)' />
                        </clipPath>
                      </defs>
                    </svg>
                    <span
                      className='ms-1 text-[12px]'>{beginTime} - {endTime} {startNumber >= 12 ? 'PM' : endNumber < 12 ? 'AM' : 'PM'}</span>
                  </div>

                  <Dropdown
                    className='cursor-pointer absolute right-0 top-2'
                    menu={{
                      items: [
                        {
                          label: 'Update',
                          icon: <Icon showMask={false} width={18} height={18} name={IconName.bookMark} />,
                          key: 'update',
                          onClick: () => onclickMenu('update', item)
                        },
                        {
                          label: 'Delete',
                          icon: <Icon showMask={false} width={18} height={18} name={IconName.bookMark} />,
                          key: 'delete',
                          onClick: () => onclickMenu('delete', item)
                        }
                      ]
                    }} trigger={['hover']}>
                    <svg width='18' height='15' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M9.4092 4.66211C9.99224 4.66211 10.4649 4.27036 10.4649 3.78711C10.4649 3.30386 9.99224 2.91211 9.4092 2.91211C8.82616 2.91211 8.35352 3.30386 8.35352 3.78711C8.35352 4.27036 8.82616 4.66211 9.4092 4.66211Z'
                        fill='#040404' />
                      <path
                        d='M9.4092 8.16211C9.99224 8.16211 10.4649 7.77036 10.4649 7.28711C10.4649 6.80386 9.99224 6.41211 9.4092 6.41211C8.82616 6.41211 8.35352 6.80386 8.35352 7.28711C8.35352 7.77036 8.82616 8.16211 9.4092 8.16211Z'
                        fill='#040404' />
                      <path
                        d='M9.4092 11.6621C9.99224 11.6621 10.4649 11.2704 10.4649 10.7871C10.4649 10.3039 9.99224 9.91211 9.4092 9.91211C8.82616 9.91211 8.35352 10.3039 8.35352 10.7871C8.35352 11.2704 8.82616 11.6621 9.4092 11.6621Z'
                        fill='#040404' />
                    </svg>
                  </Dropdown>
                </div>
              </div>
            </>;
          })}

        </div>
      </div>
    </div>

    <CalendarForm
      action={action}
      defaultValue={itemTrigger}
      activeDay={dayPicked}
      setOpen={setOpen}
      acLoad={acLoad}
      open={open} />
  </div>;
};
export default ScheduleCalendar;
