import { Tree, TreeProps } from 'antd';
import React, { FC } from 'react';
import styles from './modules/tree.module.scss';

interface TProps extends TreeProps {
  titleRender: (node: any) => React.ReactNode;
  treeData: any;
  switcherHidden?: boolean;
}

const TreeMenu: FC<TProps> = ({ treeData, titleRender, switcherHidden, ...props }) => {
  return <Tree
    {...props}
    className={`${styles.treeMenu} ${switcherHidden && styles.switcherHidden}`}
    titleRender={titleRender}
    treeData={treeData}
  />;
};
export default TreeMenu;
