import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, COURSE } from '../../actions/actionTypes';
import { message } from 'antd';
import {
  COURSE_ADD,
  COURSE_ADD_LESSON, COURSE_DELETE,
  COURSE_GET_DETAIL,
  COURSE_GET_LIST,
  COURSE_PUBLISH,
  COURSE_UPDATE, COURSE_UPDATE_LESSON
} from '../../actions/types';
import {
  reqCourseAdd, reqCourseDelete,
  reqCourseInfo,
  reqCourseList, reqCourseUpdate, reqCourseVersionCreate, reqCourseVersionInfo,
  reqCourseVersionList,
  reqLessonAdd, reqLessonUpdate
} from '../../services/v2/course';
import { ApiResponse } from '../../../utils/AxiosHelper';
import { remakeCourse } from '../../../utils/hepler';
import { ECourseType } from '../../types/Mentor';


function* getCourseList({ payload }: ActionType) {
  try {
    const response: ApiResponse<any> = yield call(reqCourseList, payload);
    const data = response.data.data.listCourse;
    for (const [index, item] of data.entries()) {
      const versionCourse: ApiResponse<any> = yield call(reqCourseVersionList, item.id);
      const versions = versionCourse.data.data;
      data[index] = {
        ...item,
        hasVersion: versions.length > 0,
        versions: [
          {
            id: 'original',
            course_id: item.id,
            version: 'Original'
          },
          ...versions
        ]
      };
      // if (versions && versions.length > 0) {
      //   const latest = versions[versions.length - 1];
      //   const response: AxiosResponse = yield reqCourseVersionInfo(latest.id);
      //   const version = response.data.data;
      //   data[index] = {
      //     ...version,
      //     id: item.id, // change item key ID is version ID to course ID
      //     versionId: version.id,
      //     versions
      //   };
      // } else {
      //   data[index] = {
      //     ...item,
      //     versions
      //   };
      // }

    }
    const allData = data.map((item: any) => {
      return {
        ...item,
        level: 0,
        key: item.id,
        hasChildren: item.children.length > 0,
        children: item.children ? remakeCourse(item.children, '', 1, item.id) : []
      };
    });

    const necessaryList = allData.filter((course: any) => course.type_course === ECourseType.necessary);
    const basicList = allData.filter((course: any) => course.type_course === ECourseType.basic);

    yield put(COURSE.COURSE_STATE({ necessaryList, basicList, allList: allData }));
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ loading: false }));
  }
}

function* addCourse({ payload }: ActionType) {
  try {
    yield call(reqCourseAdd, payload);
    yield put(COURSE.GET_LIST());
    message.success('Thêm khóa học thành công!');
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ loading: false }));
  }
}

function* updateCourse({ payload }: ActionType) {
  try {
    yield call(reqCourseUpdate, payload);
    yield put(COURSE.GET_LIST());
    message.success('Cập nhật khóa học thành công!');
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ loading: false }));
  }
}
function* deleteCourse({ payload }: ActionType) {
  try {
    yield call(reqCourseDelete, payload);
    yield put(COURSE.GET_LIST());
    message.success('Cập nhật khóa học thành công!');
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ loading: false }));
  }
}

function* addLesson({ payload }: ActionType) {
  try {
    yield call(reqLessonAdd, payload);
    yield put(COURSE.GET_LIST());
    message.success('Thêm bài học thành công!');
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ loading: false }));
  }
}

function* updateLesson({ payload }: ActionType) {
  try {
    yield call(reqLessonUpdate, payload);
    yield put(COURSE.GET_LIST());
    message.success('Cập nhật bài học thành công!');
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ loading: false }));
  }
}


function* getCourseInfo({ payload }: ActionType) {
  try {
    const response: ApiResponse<any> = yield call(reqCourseInfo, payload);

    yield put(COURSE.COURSE_STATE({ courseDetail: response.data.data }));
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ loading: false }));
  }
}

function* publish({ payload }: ActionType) {
  try {
    yield call(reqCourseVersionCreate, { id: payload.courseId, version: payload.version });
    yield put(COURSE.GET_LIST());
    message.success('Publish course success');
  } catch (e: any) {
    message.error(e.message);
  } finally {
    yield put(COURSE.COURSE_STATE({ loading: false }));
  }
}

export default function* rootSagas() {
  yield all([
    takeLatest(COURSE_GET_LIST, getCourseList),
    takeLatest(COURSE_ADD, addCourse),
    takeLatest(COURSE_UPDATE, updateCourse),
    takeLatest(COURSE_DELETE, deleteCourse),
    takeLatest(COURSE_GET_DETAIL, getCourseInfo),

    takeLatest(COURSE_ADD_LESSON, addLesson),
    takeLatest(COURSE_UPDATE_LESSON, updateLesson),
    takeLatest(COURSE_PUBLISH, publish)
  ]);
};
