import { lazy } from 'react';

const Dashboard = lazy(() => import('../admin/dashboard'));
const CourseAdd = lazy(() => import('../admin/course/add'));
const Quiz = lazy(() => import('../admin/quiz'));
import Account from '../admin/account';
import Mentor from '../admin/account/mentor';
import Student from '../admin/account/student';
import CareerPath from '../group/career-path';

const admin: any = [];
export const publicAdmin = [
  {
    path: '/',
    component: Dashboard,
    key: 'admin.dashboard',
    exact: true
  },
  {
    path: '/course',
    component: CourseAdd,
    key: 'admin.course',
    exact: true
  },
  {
    path: '/career-path',
    component: CareerPath,
    key: 'mentor.career',
    exact: true
  },
  {
    path: '/quiz',
    component: Quiz,
    key: 'admin.quiz',
    exact: true
  },
  {
    path: '/accounts',
    component: Account,
    key: 'admin.account',
    exact: true
  },
  {
    path: '/students',
    component: Student,
    key: 'admin.student',
    exact: true
  },
  {
    path: '/mentors',
    component: Mentor,
    key: 'admin.mentor',
    exact: true
  }
];
export default admin;
