import { FC } from 'react';

import dashboard from '../../../assets/icons/design/dashboard.svg';
import chat from '../../../assets/icons/design/chat.svg';
import notFound from '../../../assets/icons/design/404.svg';
import serviceError from '../../../assets/icons/design/500.svg';
import resource from '../../../assets/icons/design/resource.svg';
import course from '../../../assets/icons/design/course.svg';
import courseActive from '../../../assets/icons/design/course-active.svg';
import schedule from '../../../assets/icons/design/schedule.svg';
import setting from '../../../assets/icons/design/setting.svg';
import profile from '../../../assets/icons/design/profile.svg';
import search from '../../../assets/icons/design/search.svg';
import ring from '../../../assets/icons/design/ring.svg';
import dotNoty from '../../../assets/icons/design/dot-noti.svg';
import google from '../../../assets/icons/design/google.svg';
import facebook from '../../../assets/icons/design/facebook.svg';
import filter from '../../../assets/icons/design/filter.svg';
import grid from '../../../assets/icons/design/grid.svg';
import times from '../../../assets/icons/design/times.svg';
import book from '../../../assets/icons/design/book.svg';
import bookOpen from '../../../assets/icons/design/book-open.svg';
import clock from '../../../assets/icons/design/clock.svg';
import more from '../../../assets/icons/design/more.svg';
import studentVector from '../../../assets/icons/design/std-vector.svg';
import notes from '../../../assets/icons/design/notes.svg';
import ruler from '../../../assets/icons/design/ruler.svg';
import completed from '../../../assets/icons/design/completed.svg';
import dotDefault from '../../../assets/icons/design/dot-default.svg';
import dotPending from '../../../assets/icons/design/dot-pending.svg';
import play from '../../../assets/icons/design/play.svg';
import arrowRight from '../../../assets/icons/design/arrow-right.svg';
import arrowLeft from '../../../assets/icons/design/arrow-left.svg';
import playWhite from '../../../assets/icons/design/play-white.svg';
import next from '../../../assets/icons/design/next.svg';
import config from '../../../assets/icons/design/config.svg';
import repeat from '../../../assets/icons/design/repeat.svg';
import fullScreen from '../../../assets/icons/design/full-screen.svg';
import refresh from '../../../assets/icons/design/refresh.svg';
import pause from '../../../assets/icons/design/pause.png';
import repeatOne from '../../../assets/icons/design/repeat-one.png';
import chatBot from '../../../assets/icons/design/chat-bot.svg';
import bookMark from '../../../assets/icons/design/bookMark.svg';
import pencil from '../../../assets/icons/design/pencil.svg';
import courseNotes from '../../../assets/icons/design/course-notes.svg';
import arrowDown from '../../../assets/icons/design/arrow-down.svg';
import backsquare from '../../../assets/icons/design/backsquare.svg';
import noLive from '../../../assets/icons/design/no-live.svg';
import start from '../../../assets/icons/design/start.svg';
import switcherDown from '../../../assets/icons/design/swich-down.svg';
import switcherRight from '../../../assets/icons/design/swich-right.svg';
import calendar from '../../../assets/icons/design/calendar.svg';
import users from '../../../assets/icons/design/user.svg';
import coin from '../../../assets/icons/design/coin.svg';
import bookOpenWhite from '../../../assets/icons/design/book-open-white.svg';
import clockWhite from '../../../assets/icons/design/clock-white.svg';
import share from '../../../assets/icons/design/share.svg';

export const IconName = {
  dashboard, chat, notFound, resource, course, schedule, setting, profile,
  search, ring, dotNoty, google, facebook, filter, grid, times, book, more,
  clock, bookOpen, serviceError, studentVector, ruler, completed, dotDefault, dotPending,
  play, arrowRight, arrowLeft, courseActive, pause, chatBot, bookMark, pencil,
  playWhite, next, config, repeat, repeatOne, fullScreen, refresh, notes, courseNotes, arrowDown,
  noLive, start, backsquare, switcherDown, switcherRight, calendar, users, coin, bookOpenWhite, clockWhite,
  share
};

interface TProps {
  width?: number,
  height?: number,
  type?: 'svg' | 'png',
  fill?: string;
  name: any,
  maskClass?: string,
  classIcon?: string,
  showMask?: boolean
}

const Icon: FC<TProps> = (
  {
    name, maskClass,
    width = 24,
    height = 24,
    type = 'png',
    fill = '',
    showMask = true,
    classIcon,
    ...props
  }) => {
  return <div {...props}>
    <img
      src={name}
      width={width}
      className={classIcon}
      height={height} alt={name} />
    {showMask && <div
      className={maskClass}
      style={{
        width: width,
        height: height,
        mask: `url('${name}') center/cover`,
        WebkitMask: `url('${name}') center/cover`
      }} />}
  </div>;
};
export default Icon;
