import React, { FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Menu } from 'antd';
import logo from '../../../assets/img/logo.png';
import styles from './modules/sidebar.module.scss';
import Icon, { IconName } from './Icon';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducers';
import { Link } from 'react-router-dom';
import { history } from '../../../redux/store';
import { COMMON } from '../../../redux/actions/actionTypes';
import type { MenuProps } from 'antd';

interface TProps {
  showLogo?: boolean;
  hideArrow?: boolean;
  collapsed?: boolean,
  setCollapsed?: React.Dispatch<SetStateAction<boolean>>
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem;
}

const Sidebar: FC<TProps> = ({ collapsed, setCollapsed, hideArrow = false, showLogo = true }) => {

  const { menus } = useSelector((app: AppState) => app.common);
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState('/');

  const menuItems = useMemo(() => {
    return menus.map((item: any) => {
      return getItem(<Link to={item.path}>{item.title}</Link>,
        item.key,
        <Icon maskClass={styles.maskIcon} name={item.icon} />);
    });
  }, [menus]);

  useEffect(() => {
    localStorage.setItem('collapsed', collapsed ? '1' : '0');
  }, [collapsed]);

  useEffect(() => {
    const pathName = history.location.pathname;
    const route = menus.find((item: any) => item.path === pathName);

    if (route) {
      setActiveKey(route.key ?? '/');
      dispatch(COMMON.SET_STATE({ pageTitle: route?.title ?? 'Dashboard' }));
    }

  }, [activeKey, history, menus]);

  return <div className={`${styles.sidebarWrap} rounded-2xl bg-white`}>
    <div className={`${styles.sidebar}`}>
      {!collapsed && <Link to='/'>
        <img
          className={`mt-9 mx-5 mx-auto ${!showLogo && 'hidden'}`} width={200} alt='Beva education'
          src={logo} />
      </Link>}
      <button onClick={() => setCollapsed && setCollapsed(!collapsed)}
              className={`${hideArrow && 'hidden'} absolute right-0 top-5`}>
        <Icon name={collapsed ? IconName.arrowRight : IconName.arrowLeft} showMask={false} />
      </button>
      <div className='mt-10'>
        <Menu
          inlineCollapsed={collapsed}
          onClick={({ key }: any) => {
            setActiveKey(key);
            const page = menus.find((item: any) => item.key === key);
            dispatch(COMMON.SET_STATE({ pageTitle: page.title }));
          }}
          selectedKeys={[activeKey]}
          defaultSelectedKeys={[activeKey]}
          mode='inline'
          className={styles.menu}
          items={menuItems}
        />
      </div>
    </div>
  </div>;
};
export default Sidebar;
