import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  reqEnrollStdRegister,
  reqGetNotesCollection,
  reqGetArticle,
  reqGetStdCourse,
  reqGetStdCourseInfo,
  reqUpdateProcess,
  reqAddNotesCollection,
  reqDeleteNotesCollection,
  reqUpdateNotesCollection,
  reqSaveMessage,
  reqGetHistories,
  reqGetMessages,
  reqGetPersonalNotesCollection,
  reqGetListCourseNotes,
  reqGetStdCourseAssign, reqGetStdCourseAssignInfo, reqGetStdLessonAssignInfo, reqUpdateStdLessonAssignProcess
} from '../services/student';
import {
  GET_COLLECTION_NOTES_PERSONAL,
  GET_LIST_COURSE_NOTES,
  GPT_GET_HISTORIES, GPT_GET_MESSAGES, GPT_SAVE_MESSAGE,
  NOTES_ADD_COLLECTION,
  NOTES_DELETE_COLLECTION,
  NOTES_GET_COLLECTION,
  NOTES_PERSONAL_GET_LIST,
  NOTES_UPDATE_COLLECTION, STD_COURSE_ASSIGN, STD_COURSE_ASSIGN_INFO,
  STD_COURSE_INFO,
  STD_COURSE_LIST,
  STD_ENROLL,
  STD_ENROLL_LIST,
  STD_GET_ARTICLE, STD_LESSON_ASSIGN_INFO, STD_LESSON_ASSIGN_PROCESS, STD_STATE,
  STD_UPDATE_PROCESS
} from '../actions/types';
import { ActionType, COMMON, STUDENT } from '../actions/actionTypes';
import { ApiResponse } from '../../utils/AxiosHelper';
import { ECourseType, ICourseResponse, ILessonGroupItem } from '../types/Mentor';
import { message } from 'antd';
import { history } from '../store';
import {
  ICollectionNote,
  IResPerCollectionNote,
  IResponseAddCollectionNote,
  IResponseDeleteCollectionNote,
  IResponsePersonalNote,
  ResponseListCourseNote
} from '../types/User';
import { globalIntl, remakeCourse, remakeCourseAssign } from 'src/utils/hepler';
import { reqGetPersonalNotes } from '../services/notes';

function* onGetCourseList({ payload }: any) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<ICourseResponse> = yield call(reqGetStdCourse, payload);
    const { listCourse } = response.data;
    if (listCourse) {
      const basic = listCourse.filter((course) => course.typeCourse === ECourseType.basic);
      const necessary = listCourse.filter((course) => course.typeCourse === ECourseType.necessary);

      yield put(STUDENT.SET_STATE({
        enrolled: {
          basic: basic.filter((item) => item.enrolled),
          necessary: necessary.filter((item) => item.enrolled),
          allCourse: listCourse
        },
        courseList: { basic, necessary, allCourse: listCourse }
      }));
    }
  } catch (e) {
    //message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* onGetCourseInfo({ payload }: any) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<any> = yield call(reqGetStdCourseInfo, payload);
    yield put(STUDENT.SET_STATE({
      course: response.data
    }));
  } catch (e) {
    // message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* onRegEnroll({ payload }: any) {
  try {
    yield call(reqEnrollStdRegister, { courseId: payload.courseId });
    yield put(STUDENT.GET_COURSE_INFO({ courseId: payload.courseId }));
    history.push(`/course/${payload.courseId}`);
    message.success('Đăng ký chuyên ngành thành công!');
  } catch (e) {
    message.error(e.message);
  } finally {
  }
}

function* onReqUpdateProcess({ payload }: any) {
  try {
    yield put(STUDENT.SET_STATE({ acLoad: false }));
    yield call(reqUpdateProcess, payload);
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(STUDENT.SET_STATE({ acLoad: false }));
  }
}

function* onGetArticle({ payload }: any) {
  try {
    const response: ApiResponse<any> = yield call(reqGetArticle, payload);
    yield put(STUDENT.SET_STATE({
      article: response.data.data.text
    }));
  } catch (e) {
    message.error(e.message);
  }
}

function* getCollectionNotes({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<ICollectionNote> = yield call(reqGetNotesCollection);
    yield put(STUDENT.SET_STATE({
      collectionNotes: response.data.data
    }));
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* addCollectionNotes({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<IResponseAddCollectionNote> = yield call(reqAddNotesCollection, payload);
    console.log('addCollectionNotes', response.data);
    const msg = globalIntl('std.create.notesCollection.success');
    message.success(msg);
    yield put(STUDENT.GET_NOTES_COLLECTION());
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* deleteCollectionNotes({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<IResponseDeleteCollectionNote> = yield call(reqDeleteNotesCollection, payload);
    const msg = globalIntl('std.delete.notesCollection.success');
    message.success(msg);
    yield put(STUDENT.GET_NOTES_COLLECTION());
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* updateCollectionNotes({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<{}> = yield call(reqUpdateNotesCollection, payload);
    const msg = globalIntl('std.update.notesCollection.success');
    message.success(msg);
    yield put(STUDENT.GET_NOTES_COLLECTION());
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* getPersonalNotes() {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<IResponsePersonalNote> = yield call(reqGetPersonalNotes);
    yield put(STUDENT.SET_STATE({
      personalNotes: response.data.data.list
    }));
    yield put(COMMON.LOADING(false));

  } catch (e) {
    message.error(e.message);
    yield put(COMMON.LOADING(false));
  }
}

function* getPersonalColectionNotes() {
  try {
    yield put(STUDENT.SET_STATE({
      loading: true
    }));
    const response: ApiResponse<IResPerCollectionNote> = yield call(reqGetPersonalNotesCollection);
    yield put(STUDENT.SET_STATE({
      collectionPersonalNotes: response.data.data.list,
      loading: false
    }));
  } catch (e) {
    message.error(e.message);
    yield put(STUDENT.SET_STATE({
      loading: false
    }));
  }
}

function* getMessages({ payload }: ActionType) {
  try {
    const response: ApiResponse<any> = yield call(reqGetMessages, payload);
    yield put({
      type: STD_STATE,
      payload: {
        GPTMsg: response.data.data ? response.data.data.map((item: any) => {
          return {
            role: item.role,
            content: item.content
          };
        }) : []
      }
    });
  } catch (e) {
    message.error(e.message);
  }
}

function* getHistories({ payload }: ActionType) {
  try {
    const response: ApiResponse<any> = yield call(reqGetHistories, payload);
    yield put({
      type: STD_STATE,
      payload: {
        GPTHistories: response.data.data
      }
    });
  } catch (e) {
    // message.error(e.message);
  }
}

function* saveMessage({ payload }: ActionType) {
  try {
    yield call(reqSaveMessage, payload);
  } catch (e) {
    console.log('save message error', e);
  }
}

function* getListCourseNotes() {
  try {
    yield put(STUDENT.SET_STATE({
      loading: true
    }));
    const response: ApiResponse<ResponseListCourseNote> = yield call(reqGetListCourseNotes);
    yield put(STUDENT.SET_STATE({
      listCourseNotes: response.data.data.list,
      loading: false
    }));

  } catch (e) {
    message.error(e.message);
    yield put(STUDENT.SET_STATE({
      loading: false
    }));
  }
}

// version course 2

function* onGetCourseAssign({ payload }: any) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<any> = yield call(reqGetStdCourseAssign);
    let courseInfo = null;
    const data = response.data.data;
    if (payload.courseId && payload.pathId) {
      const coursePath = data.find((item: any) => item.career_path_id === payload.pathId);
      courseInfo = coursePath.revision_course_progress_info.find((item: any) => item.id === payload.courseId);
    }
    yield put(STUDENT.SET_STATE({
      careerPath: data,
      courseInfo: courseInfo ? remakeCourseAssign({
        ...courseInfo,
        children: courseInfo?.lesson_progress_info,
        ...payload
      }) : null
    }));
  } catch (e) {
    //message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* onGetCourseAssignInfo({ payload }: any) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<any> = yield call(reqGetStdCourseAssignInfo, {
      id: payload.courseId,
      career_path_id: payload.pathId
    });
    const data = response.data.data;
    yield put(STUDENT.SET_STATE({
      courseInfo: remakeCourseAssign({
        ...data,
        ...payload
      })
    }));
  } catch (e) {
    //message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* onGetLessonAssignInfo({ payload }: any) {
  try {
    yield put(COMMON.LOADING(true));
    const response: ApiResponse<any> = yield call(reqGetStdLessonAssignInfo, {
      id: payload.lessonId,
      revision_course_id: payload.courseId,
      career_path_id: payload.pathId
    });
    const data = response.data.data;
    yield put(STUDENT.SET_STATE({
      lessonInfo: {
        ...data,
        ...payload
      }
    }));
  } catch (e) {
    //message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* onUpdateLessonAssignProcess({ payload }: any) {
  try {
    yield put(COMMON.LOADING(true));
    yield call(reqUpdateStdLessonAssignProcess, payload);
  } catch (e) {
    //message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(STD_COURSE_LIST, onGetCourseList),
    takeLatest(STD_COURSE_INFO, onGetCourseInfo),
    takeLatest(STD_ENROLL, onRegEnroll),
    takeLatest(STD_GET_ARTICLE, onGetArticle),
    takeLatest(STD_UPDATE_PROCESS, onReqUpdateProcess),
    takeEvery(NOTES_GET_COLLECTION, getCollectionNotes),
    takeLatest(NOTES_ADD_COLLECTION, addCollectionNotes),
    takeLatest(NOTES_DELETE_COLLECTION, deleteCollectionNotes),
    takeLatest(NOTES_UPDATE_COLLECTION, updateCollectionNotes),
    takeLatest(NOTES_PERSONAL_GET_LIST, getPersonalNotes),
    takeLatest(GPT_GET_HISTORIES, getHistories),
    takeLatest(GPT_GET_MESSAGES, getMessages),
    takeLatest(GPT_SAVE_MESSAGE, saveMessage),
    takeLatest(GET_COLLECTION_NOTES_PERSONAL, getPersonalColectionNotes),
    takeLatest(GET_LIST_COURSE_NOTES, getListCourseNotes),

    // version course 2

    takeLatest(STD_COURSE_ASSIGN, onGetCourseAssign),
    takeLatest(STD_COURSE_ASSIGN_INFO, onGetCourseAssignInfo),
    takeLatest(STD_LESSON_ASSIGN_INFO, onGetLessonAssignInfo),
    takeLatest(STD_LESSON_ASSIGN_PROCESS, onUpdateLessonAssignProcess)
  ]);
}
