import React, { FC, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Icon, { IconName } from './Icon';
import user from '../../../assets/img/user.png';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH } from '../../../redux/actions/actionTypes';
import { AppState } from '../../../redux/reducers';
import logo from '../../../assets/img/logo.png';

interface TProps {
  open: boolean,
  onOpen: (isShow: boolean) => void;
}

const HeaderMobile: FC<TProps> = ({ onOpen, open }) => {
  const dispatch = useDispatch();
  const { hasNoty } = useSelector((app: AppState) => app.auth);
  const { pageTitle } = useSelector((app: AppState) => app.common);
  const [search, setSearch] = useState(false);


  return <div className='grid bg-white grid-cols-2 p-4 xl:h-12 md:h-auto min-w-full w-full'>
    <div className='flex flex-col justify-start ...'>
      <div className='flex items-center'>
        <button
          onClick={() => onOpen(!open)}
          className='group hover:bg-primary bg-white w-10 h-10 flex justify-center items-center'>
          <svg className='group-hover:fill-white' width='17' height='17' viewBox='0 0 17 17' fill='none'
               xmlns='http://www.w3.org/2000/svg'>
            <path
              className='group-hover:fill-white'
              d='M1.0183 16.0477C0.753152 16.0477 0.498863 15.9429 0.311374 15.7565C0.123885 15.5701 0.0185547 15.3173 0.0185547 15.0537C0.0185547 14.79 0.123885 14.5372 0.311374 14.3508C0.498863 14.1644 0.753152 14.0597 1.0183 14.0597H15.0148C15.2799 14.0597 15.5342 14.1644 15.7217 14.3508C15.9092 14.5372 16.0145 14.79 16.0145 15.0537C16.0145 15.3173 15.9092 15.5701 15.7217 15.7565C15.5342 15.9429 15.2799 16.0477 15.0148 16.0477H1.0183ZM1.0183 9.08972C0.753152 9.08972 0.498863 8.98499 0.311374 8.79858C0.123885 8.61217 0.0185547 8.35935 0.0185547 8.09573C0.0185547 7.8321 0.123885 7.57928 0.311374 7.39287C0.498863 7.20646 0.753152 7.10174 1.0183 7.10174H15.0148C15.2799 7.10174 15.5342 7.20646 15.7217 7.39287C15.9092 7.57928 16.0145 7.8321 16.0145 8.09573C16.0145 8.35935 15.9092 8.61217 15.7217 8.79858C15.5342 8.98499 15.2799 9.08972 15.0148 9.08972H1.0183ZM1.0183 2.13178C0.753152 2.13178 0.498863 2.02706 0.311374 1.84065C0.123885 1.65424 0.0185547 1.40141 0.0185547 1.13779C0.0185547 0.874167 0.123885 0.621341 0.311374 0.434932C0.498863 0.248523 0.753152 0.143799 1.0183 0.143799L15.0148 0.143799C15.2799 0.143799 15.5342 0.248523 15.7217 0.434932C15.9092 0.621341 16.0145 0.874167 16.0145 1.13779C16.0145 1.40141 15.9092 1.65424 15.7217 1.84065C15.5342 2.02706 15.2799 2.13178 15.0148 2.13178H1.0183Z'
              fill='#9295A3' />
          </svg>
        </button>
        <img width={80} alt='Beva education' src={logo} />
        <div className='hidden md:block relative'>
          <input placeholder='Tìm kiếm...' className={`duration-200 bg-[#F5FBFD] rounded-full pl-12 h-12`} />
          <button
            onClick={() => setSearch(!search)}
            className={`absolute left-0 top-0 m-auto w-12 h-12 flex justify-center items-center cursor-pointer rounded-full bg-[#F5FBFD] hover:bg-slate-200`}>
            <Icon showMask={false} classIcon='h-auto' name={IconName.search} />
          </button>
        </div>
      </div>
    </div>
    <div className='flex flex-col justify-end ...'>
      <div className='flex items-center justify-end'>
        <button
          onClick={() => setSearch(!search)}
          className={`w-12 h-12 flex justify-center items-center cursor-pointer rounded-full hover:bg-slate-200`}>
          <Icon showMask={false} classIcon='h-auto' name={IconName.search} />
        </button>
        <div className='relative'>
          <img src={IconName.ring} width={34} height={32} />
          {hasNoty && <img className='absolute -top-0 -right-0' src={IconName.dotNoty} />}
        </div>
        <img
          width={60}
          height={60}
          className='inline-block h-12 w-12 rounded-full ring-2 ring-white'
          src={user}
          alt='beva' />
        <Menu as='div' className='hidden relative md:inline-block text-left'>
          <Menu.Button
            className='inline-flex w-full justify-center ml-3 font-medium text-xl'>
            <span>{localStorage.getItem('name') ?? 'Guest'}</span>
            <svg className='ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100' width='24' height='24'
                 viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M17.0004 7H7.00036C6.82211 7.00064 6.64725 7.04882 6.49383 7.13957C6.34041 7.23031 6.21397 7.36035 6.12756 7.51625C6.04115 7.67216 5.99789 7.8483 6.00225 8.0265C6.00661 8.20469 6.05843 8.3785 6.15236 8.53L11.1524 16.53C11.2422 16.6738 11.3672 16.7924 11.5156 16.8746C11.6639 16.9569 11.8308 17 12.0004 17C12.17 17 12.3368 16.9569 12.4851 16.8746C12.6335 16.7924 12.7585 16.6738 12.8484 16.53L17.8484 8.53C17.943 8.37863 17.9953 8.20469 18 8.02625C18.0047 7.8478 17.9615 7.67136 17.875 7.51523C17.7885 7.3591 17.6617 7.22898 17.5079 7.13838C17.3541 7.04778 17.1789 7 17.0004 7Z'
                fill='#040404' />
            </svg>
          </Menu.Button>
          <Transition
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items
              className='z-all absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
              <div className='px-1 py-1 '>
                <Menu.Item>
                  <button className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                    Tài khoản
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                    Cài đặt
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    onClick={() => dispatch(AUTH.LOGOUT())}
                    className={`text-gray-900 group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                    Đăng xuất
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  </div>;
};
export default HeaderMobile;
