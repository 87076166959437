import {
  TrashIcon,
  AlignLeftIcon,
  AlignRightIcon,
  AlignCenterIcon,
  InsertLeftIcon,
  InsertRightIcon,
} from "outline-icons";
import { EditorState } from "prosemirror-state";
import * as React from "react";
import isNodeActive from "../../../shared/editor/queries/isNodeActive";
import { MenuItem } from "../../../shared/editor/types";
import { globalIntl } from "src/utils/hepler";

export default function tableColMenuItems(
  state: EditorState,
  index: number,
  rtl: boolean,
  // editor: Dictionary
): MenuItem[] {
  const { schema } = state;

  return [
    {
      name: "setColumnAttr",
      tooltip: globalIntl("editor.alignLeft"),
      icon: <AlignLeftIcon />,
      attrs: { index, alignment: "left" },
      active: isNodeActive(schema.nodes.th, {
        colspan: 1,
        rowspan: 1,
        alignment: "left",
      }),
    },
    {
      name: "setColumnAttr",
      tooltip: globalIntl("editor.alignCenter"),
      icon: <AlignCenterIcon />,
      attrs: { index, alignment: "center" },
      active: isNodeActive(schema.nodes.th, {
        colspan: 1,
        rowspan: 1,
        alignment: "center",
      }),
    },
    {
      name: "setColumnAttr",
      tooltip: globalIntl("editor.alignRight"),
      icon: <AlignRightIcon />,
      attrs: { index, alignment: "right" },
      active: isNodeActive(schema.nodes.th, {
        colspan: 1,
        rowspan: 1,
        alignment: "right",
      }),
    },
    {
      name: "separator",
    },
    {
      name: rtl ? "addColumnAfter" : "addColumnBefore",
      tooltip: rtl ? globalIntl("editor.addColumnAfter"): globalIntl("editor.addColumnBefore"),
      icon: <InsertLeftIcon />,
      active: () => false,
    },
    {
      name: rtl ? "addColumnBefore" : "addColumnAfter",
      tooltip: rtl ? globalIntl("editor.addColumnBefore") : globalIntl("editor.addColumnAfter"),
      icon: <InsertRightIcon />,
      active: () => false,
    },
    {
      name: "separator",
    },
    {
      name: "deleteColumn",
      tooltip: globalIntl("editor.deleteColumn"),
      icon: <TrashIcon />,
      active: () => false,
    },
  ];
}
