import AppLocale from '../lngProvider';
import { history, initStore } from '../redux/store';
import { ELessonType } from '../redux/types/Mentor';
import CryptoJS from 'crypto-js';
import { axiosCatch, docApi } from './AxiosHelper';

export const PlaceholderUser = '/assets/img/PlaceholderUser.png';
export const getYoutubeId = (youtubeLink: string, checkType: boolean = false) => {
  if (!youtubeLink) {
    return undefined;
  }
  const youtubeRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([\w-]{11})(?:\S+)?$/;
  const match = youtubeLink.match(youtubeRegex);
  if (match && match[1]) {
    return checkType ? true : match[1];
  }
  return undefined;
};

export const globalIntl = (id: string): string => {
  return AppLocale[initStore.getState().common.locale].messages[id];
};

export const toTiming = (value?: number) => {
  if (!value) {
    return '00:00';
  }
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = Math.floor(value % 60);
  const paddedMinutes = String(minutes).padStart(2, '0');
  const paddedSeconds = String(seconds).padStart(2, '0');
  if (hours <= 0) {
    return `${paddedMinutes}:${paddedSeconds}`;
  }
  return `${hours}:${paddedMinutes}:${paddedSeconds}`;
};

export const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getLessonType = (type: number | string) => {
  if (type === ELessonType.video) {
    return 'Video';
  }
  if (type === ELessonType.quiz) {
    return 'Quiz';
  }
  return 'Article';
};
export const isLoginPage = () => {
  const hashToken = localStorage.getItem('auth');
  return !hashToken && ['/', '/login', '/login-admin', '/login-mentor'].includes(window.location.pathname) ||
    history.location.pathname.match(/login/i) ||
    window.location.pathname.match(/login/i);
};

interface Document {
  id: string;
  url: string;
  children?: Document[];
}

export const findIdByUrl = (data: Document[], url: string): string | null => {
  for (const obj of data) {
    if (obj.url === url || obj.url.includes(url)) {
      return obj.id;
    }
    if (obj.children) {
      const result = findIdInChildren(obj.children, url);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

const findIdInChildren = (children: Document[] | undefined, url: string): string | null => {
  if (!children) {
    return null;
  }
  for (const child of children) {
    if (child.url === url || child.url.includes(url)) {
      return child.id;
    }
    const result = findIdInChildren(child.children, url);
    if (result) {
      return result;
    }
  }
  return null;
};

export const encrypQuiz = (data: any, secretKey = '') => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  } catch (e: any) {
    console.log('Error decrypt: ', e);
    return null;
  }
};

export const decryptQuiz = (cipherText: any, secretKey = '') => {
  const bytes = CryptoJS.AES.decrypt(cipherText, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const toObject = (item: any) => {
  if (!item) {
    return {};
  }
  return {
    id: item.id,
    name: item.title,
    group: item.listLessonGrp && item.listLessonGrp.map((group: any) => {
      return {
        id: group.id,
        name: group.title,
        description: group.description,
        percent: group.progressLessonGrpInfo?.progress ?? 0,
        lessons: group.listLessons
      };
    })
  };
};
export const limitTxt = (str: string) => str.length > 15 ? str.slice(0, 15) + '...' : str;
export const courseConverter = (data = [], parentLink = '', parentKey = null, path: string[] = []): any => {
  if (!data) {
    return [];
  }
  return data.map((item: any) => {
    const children = item.listLessonGrp ? item.listLessonGrp : item.listLessons ? item.listLessons : [];
    const link = `${parentLink}/${item.id}`;
    const type = item.listLessonGrp ? 'subject' : item.listLessons ? 'group' : 'lesson';
    const info = type === 'subject' ? item.progressSubjectInfo : type === 'group' ? item.progressLessonGrpInfo : item.progressLessonInfo;
    const count = type === 'subject' ? item.numberOfLessonGrp : item.numberOfLesson;
    const bread = [...path, limitTxt(item.title)];
    return {
      type,
      link: link ?? '/page-not-found',
      bread: bread,
      count: count ?? 0,
      info: info ?? null,
      description: item.description,
      parentKey: parentKey,
      title: item.title,
      label: item.title,
      key: item.id,
      id: item.id,
      url: item.url,
      lessonInfo: item.lessonInfo,
      typeLesson: item.typeLesson,
      children: courseConverter(children, link, item.key, bread),
      set: { ...item }
    };
  });
};

const remakeChildrenAssign = (children = [], level = 0, link = ''): any => {
  if (!children) {
    return [];
  }
  return children.map((item: any) => {
    const newUrl = `${link}/${item.id}`;
    return {
      ...item,
      key: item.id,
      level,
      link: newUrl,
      isGroup: item.children.length > 0,
      count: item.children.length,
      children: item.children ? remakeChildrenAssign(item.children, level + 1, link) : null
    };
  });
};
export const remakeCourseAssign = (course: any) => {
  const link = `/course/${course.pathId}/${course.courseId}`;
  return {
    ...course,
    link,
    key: course.id,
    level: 0,
    count: course.children.length,
    children: course.children ? remakeChildrenAssign(course.children, 1, link) : null
  };
};

export const remakeCourse = (data: any, parentKey = '', level = 0, courseId = ''): any => {
  if (Array.isArray(data)) {
    return data.map((item) => {
      return {
        ...item,
        courseId,
        key: item.id,
        level: level,
        checkable: level === 0,
        parentKey: parentKey,
        children: item.children ? remakeCourse(item.children, item.id, level + 1, courseId) : []
      };
    });
  }
  return {
    ...data,
    level: 0,
    key: data.id,
    children: data.children ? remakeCourse(data.children, data.id, level + 1, data.id) : []
  };
};

export const getCourseInfo = (course: any) => {
  const courseProcessInfo = course.progressInfo;
  const courseInfo = courseConverter(course.listSubject, `/course/${course.id}`);
  const currentSubject = courseInfo && courseInfo.find((item: any) => item.key === courseProcessInfo?.subject_current_id);
  const currentGroup = currentSubject && currentSubject.children.find((item: any) => item.key === courseProcessInfo?.lesson_group_current_id);
  const currentLesson = currentGroup && currentGroup.children.find((item: any) => item.key === courseProcessInfo?.lesson_current_id);
  return { courseInfo, currentSubject, currentGroup, currentLesson };
};


export async function findIdByURLBeva(inputURL: string, path?: string): Promise<string | null> {
  const response = path ? await docApi.post(path).catch(axiosCatch) : await docApi.post('/api/documents.list', { limit: 100 }).catch(axiosCatch);
  const data = response.data.data;
  for (const item of data) {
    if (item.url === inputURL) {
      return item.id;
    }
  }

  if (data.length < 100) {
    return null;
  }

  const nextPath = response.data.pagination.nextPath;
  if (nextPath) {
    return findIdByURLBeva(inputURL, nextPath);
  }

  return null;
}

export const versionUp = (data: any = []): string => {

  const [first, ...versions] = data;
  if (!versions || versions.length === 0 && first.id === 'original') {
    return '1.0.1';
  }
  const { version } = versions.pop();
  let [major, minor, patch] = version.split('.');
  patch = Number(patch) + 1;
  if (patch == 100) {
    minor = Number(minor) + 1;
    patch = 0;
  }
  if (minor == 100) {
    major = Number(major) + 1;
    minor = 0;
    patch = 0;
  }
  return `${major}.${minor}.${patch}`;
};


export const findNotesId = (listCourseNotes: any, params: any) => {
  for (const item of listCourseNotes) {
    if (item.course_id === params.courseId) {
      const { children } = item;
      for (const child of children) {
        if (child.subject_id === params.subjectId) {
          const { children: nestedChildren } = child;
          for (const nestedChild of nestedChildren) {
            if (nestedChild.lesson_group_id === params.groupId) {
              return nestedChild.id;
            }
          }
        }
      }
    }
  }
  return null;
};

/**
 * Flat item from array object by key
 * @param arr
 * @param key
 */
function flattenArrayByKey(arr: Array<any>, key: string) {
  return arr.reduce((result: Array<any>, item: any) => {
    result.push(item);
    if (item[key] && item[key].length > 0) {
      result.push(...flattenArrayByKey(item[key], key));
    }
    return result;
  }, []);
}

/**
 * Find next , previous, current item from target id
 * @param data
 * @param targetId
 */
export function findPreviousAndNextLessons(data: Array<any>, targetId: string) {
  const nextItem = null;
  const prevItem = null;
  const currentItem = null;
  const flatData = flattenArrayByKey(data, 'children');
  const index = flatData.findIndex((item: any) => item.id === targetId);
  if (index > -1) {
    return { prevItem: flatData[index - 1], nextItem: flatData[index + 1], currentItem: flatData[index] };
  }
  return { nextItem, prevItem, currentItem };
}
