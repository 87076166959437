import { debounce } from "lodash";
// import { action, observable } from "mobx";
// import { observer } from "mobx-react";
// import { AllSelection } from "prosemirror-state";
import * as React from "react";
// import { WithTranslation, withTranslation } from "react-i18next";
// import {
//   Prompt,
//   RouteComponentProps,
//   StaticContext,
//   withRouter,
//   Redirect,
// } from "react-router";
import Flex from "src/editor/app/components/Flex";

import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Heading } from "../../../../student/block/editor/shared/editor/lib/getHeadings";

// import { NavigationNode } from "../../../../shared/types";

import type { Editor as TEditor } from "src/editor/app/editor/index";
import Container from "../../../../student/block/editor/app/scenes/Document/components/Container";

import Editor from "../../../../student/block/editor/app/scenes/Document/components/Editor";
import Theme from "../../../../student/block/editor/app/components/Theme";
import { message } from "antd";
import { INotes_TYPES } from "src/redux/types/User";
import { reqInfoPersonalNotes, reqInfoPersonalNotesCollection, reqCreatePersonalNotes, reqUpdatePersonalNotes, reqUpdatePersonalNotesCollection, reqCreatePersonalNotesCollection } from "src/redux/services/student";
import { TYPES_NOTES } from "src/redux/actions/types";


type Props = {
  onCreateLink?: (title: string) => Promise<string>;
  onSearchLink?: (term: string) => any;
  isLite?: boolean;
  defaultValue?: string;
  id?: any;
  title?: string;
  notesId?: any;
  onGetList?: (notesId: string) => void;
  typeNotes?: string;
  collectionPersonalNoteId?: any;
  parentPersonalNoteId?: any;
};
// const defaultValue = "## Tổng quan\n\n\n:::info\nMã sản phẩm: **LM-AI/1.0** (Ra mắt: 2021)\n\n:::\n\n***Tổng quan chức năng AI HUB v1.0 & v2.0:***\n\n* AI Camera Hub cũng có thể thay thế HC/LC, đóng vai trò như một bộ điều khiển trung tâm quản lý các thiết bị trong nhà\n* Lấy hình ảnh từ Camera IP trong cùng mạng Local , sau đó xử lý hình ảnh bằng thuật toán AI. \n* Có thể sử dụng sự kiện phát hiện xâm nhập của Camera AI để kích hoạt Rule an ninh.\n* Xem trực tiếp hình ảnh lấy từ Camera thông qua App Lumi Life.\n* Cấu hình và điều khiển các thiết bị Zigbee/Bluetooth Mesh của Lumi\n* Xử lý các logic Rule/Cảnh/Lịch/Nhóm đảo chiều giống như HC.\n* Tích hợp cổng đầu ra tiếp điểm khô để điều khiển tải điện AC.\n* Hình thức lưu trữ video: ***Lưu tóm tắt hoặc lưu đầy đủ sự kiện***.\n\n "

class NotesPersonal extends React.Component<Props> {
  editor = React.createRef<TEditor>();

  state = {
    isUploading: false,
    title: '',
    headings: [],
    editorTextP: '',
    isSaving: false,
    // isEditorDirty: false,
    // isEmpty: true,
    prevEditorText: '',
    prevTitle: '',
    notesId: this.props.id,
  }
  getEditorText: () => string = () => this.state.editorTextP ?? '';

  componentDidMount() {
    this.getDetailNote();
    // this.updateIsDirty();
  }

  componentDidUpdate(prevProps: Props) {

  }
  getDetailNote = async () => {
    if (this.props.typeNotes == TYPES_NOTES.INFO_COLLECTION) {
      try {
        const responseNotes = await reqInfoPersonalNotesCollection({ id: this.props.collectionPersonalNoteId });
        this.setState({
          title: responseNotes.data.data.title,
          prevTitle: responseNotes.data.data.title,
          editorTextP: responseNotes.data.data.content,
          prevEditorText: responseNotes.data.data.content,
        })
      }
      catch (e) {
        message.error(e.message);
      }
    } else  if (this.props.typeNotes == TYPES_NOTES.INFO_NOTES) {
      try {
        const responseNotes = await reqInfoPersonalNotes({ personalNoteId: this.props.id });
        this.setState({
          title: responseNotes.data.data.title,
          prevTitle: responseNotes.data.data.title,
          editorTextP: responseNotes.data.data.content,
          prevEditorText: responseNotes.data.data.content,
        })
      }
      catch (e) {
        message.error(e.message);
      }
    }
    
  };
  componentWillUnmount() {
    this.autosave.cancel();
    this.onSave()
      .catch((error) => {
        message.error('Lưu notes thất bại!');
      });
  }

  onSave = async () => {
    if (this.state.isSaving) {
      return Promise.resolve();
    }
    const text = this.getEditorText && this.getEditorText();
    const editorText = this.getEditorText() && this.getEditorText().trim();

    // a single hash is a doc with just an empty title
    if ((editorText === "" || editorText === "#" || editorText === "\\") && this.state.title.trim() == "") {
      return Promise.resolve();
    }
    if ((editorText == (this.state.prevEditorText ?? '').trim()) && (this.state.title == this.state.prevTitle)) {
      return Promise.resolve();
    }

    this.setState({ isSaving: true });
    try {
      if (this.props.typeNotes == TYPES_NOTES.INFO_NOTES) {        
        const response = await reqUpdatePersonalNotes({
          personalNoteId: this.state.notesId,
          title: this.state.title,
          content: text,
        });
        response.status === 200 ? ((this.props.onGetList) && this.props.onGetList(response.data.data.id)) : message.error('Lưu notes thất bại!');
      }
      else if (this.props.typeNotes == TYPES_NOTES.INFO_COLLECTION) {
        const response = await reqUpdatePersonalNotesCollection({
          id: this.props.collectionPersonalNoteId,
          title: this.state.title,
          content: text,
        });
        response.status === 200 ? ((this.props.onGetList) && this.props.onGetList(response.data.data.id)) : message.error('Lưu notes thất bại!');
      }
      else if (this.props.typeNotes == TYPES_NOTES.CREATE_NOTES) {
        let data: any = {};
        if (this.props.collectionPersonalNoteId) {
          if (this.props.parentPersonalNoteId) {
            data = {
              ...data,
              title: this.state.title,
              content: text,
              collectionPersonalNoteId: this.props.collectionPersonalNoteId,
              parentPersonalNoteId: this.props.parentPersonalNoteId,
            }
          }
          data = {
            ...data,
            title: this.state.title,
            content: text,
            collectionPersonalNoteId: this.props.collectionPersonalNoteId,
          }
        }
        const response=  await reqCreatePersonalNotes(data);
        if (response.status==200) {
          if (this.props.onGetList) {
            this.props.onGetList(response.data.data.id);
          }
          this.setState({
            notesId: response.data.data.id,
          });
        }
        else {
          message.error('Tạo notes thất bại!');
        }
      }
      else if (this.props.typeNotes == TYPES_NOTES.CREATE_COLLECTION) {
        const response=  await reqCreatePersonalNotesCollection({
          title: this.state.title,
          content: text,
        });
        if (response.status==200) {
          if (this.props.onGetList) {
            this.props.onGetList(response.data.data.id);
          }
          this.setState({
            notesId: response.data.data.id,
          });
        }
        else {
          message.error('Tạo notes thất bại!');
        }
      }
      this.setState({
        isSaving: false,
        isEditorDirty: false,
        prevEditorText: text,
        prevTitle: this.state.title,
      });
      return Promise.resolve();
    } catch (e) {
      message.error('Lưu notes thất bại!');
      return Promise.reject(e);
    }
  };

  autosave = debounce(() => {
    this.onSave();
  }, 3000);

  // updateIsDirty = () => {
  //   console.log("updateIsDirty", this.state.editorTextP, 'editorText', this.getEditorText());

  //   const editorText = this.getEditorText() && this.getEditorText().trim();
  //   console.log('this.state.prevTitle', this.state.prevTitle, this.state.title);

  //   this.setState({
  //     isEditorDirty: (editorText !== (this.state.prevEditorText ?? '').trim()) || (this.state.title !== this.state.prevTitle)
  //   })
  //   // a single hash is a doc with just an empty title
  //   this.setState({
  //     isEmpty:
  //       (!editorText || editorText === "#" || editorText === "\\") && !this.state.title
  //   })
  // };

  // updateIsDirtyDebounced = debounce(this.updateIsDirty, 500);

  onFileUploadStart = () => {
    this.setState({ isUploading: true });

  };

  onFileUploadStop = () => {
    this.setState({ isUploading: false });
  };

  onChange = (getEditorText: () => string) => {
    this.getEditorText = getEditorText;
    // this.updateIsDirty();
    this.autosave();
  };

  onHeadingsChange = (headings: Heading[]) => {
    this.setState({ headings });
  };

  onChangeTitle = (value: string) => {
    this.setState({ title: value });
    // this.updateIsDirty();
    this.autosave();
  };

  render() {
    return (
      <div>
        <Theme>
          <MaxWidth
            archived={false}
            showContents={true}
            isEditing={true}
            isFullWidth={true}
            column
            auto
          >

            <Flex auto={true} reverse>
              <Editor
                // key={embedsDisabled ? "disabled" : "enabled"}
                key={"enabled"}
                value={this.state.editorTextP}
                ref={this.editor}
                title={this.state.title}
                // template={document.isTemplate}
                // value={readOnly ? document.text : undefined}
                defaultValue={this.state.editorTextP}
                embedsDisabled={false}
                // onSynced={this.onSynced}
                onFileUploadStart={this.onFileUploadStart}
                onFileUploadStop={this.onFileUploadStop}
                onCreateLink={this.props.onCreateLink}
                onChangeTitle={this.onChangeTitle}
                onChange={this.onChange}
                onHeadingsChange={this.onHeadingsChange}
                onSave={this.onSave}
                // onCancel={this.goBack}
                readOnly={false}
                readOnlyWriteCheckboxes={false}
              >
              </Editor>
              {/* <Contents
            headings={this.state.headings}
            isFullWidth={true}
          /> */}
            </Flex>
          </MaxWidth>
        </Theme>
      </div>
    );
  }
}

const Background = styled(Container)`
  background: ${(props) => props.theme.background};
  transition: ${(props) => props.theme.backgroundTransition};
`;

const ReferencesWrapper = styled.div<{ isOnlyTitle?: boolean }>`
  margin-top: ${(props) => (props.isOnlyTitle ? -45 : 16)}px;

  @media print {
    display: none;
  }
`;

type MaxWidthProps = {
  isEditing?: boolean;
  isFullWidth?: boolean;
  archived?: boolean;
  showContents?: boolean;
};

const MaxWidth = styled(Flex) <MaxWidthProps>`
  // Adds space to the gutter to make room for heading annotations
  padding: 0 32px;
  transition: padding 100ms;
  max-width: 100vw;
  width: 100%;

  padding-bottom: 16px;

  ${breakpoint("tablet")`
    margin: 0px auto 12px;
    max-width: ${(props: MaxWidthProps) =>
      props.isFullWidth
        ? "100vw"
        : `calc(64px + 46em + ${props.showContents ? "256px" : "0px"});`}
  `};

  ${breakpoint("desktopLarge")`
    max-width: ${(props: MaxWidthProps) =>
      props.isFullWidth ? "100vw" : `calc(64px + 52em);`}
  `};
`;

export default NotesPersonal;
