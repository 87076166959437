import { AxiosResponse } from "axios";
import { axiosAuth, axiosCatch } from "src/utils/AxiosHelper";
import { INoteCreate, INoteDelete, INoteInfo, INoteMove, INotePersonalDelete, INoteUpdate, IPersonalNoteCreate, IPersonalNoteUpdate, IResponseCreateNote, IResponseNoteDelete, IResponsePersonalNote } from "../types/User";

export const reqDetaillNotes =  async (params: any = {}): Promise<AxiosResponse<INoteInfo>> => {
    return await axiosAuth().get('/student/note/info', {params}).catch(axiosCatch);
 };
 export const reqCreateNotes =  async (params: INoteCreate): Promise<AxiosResponse<IResponseCreateNote>> => {
    return await axiosAuth().post('/student/note/create', params).catch(axiosCatch);
 };
 export const reqUpdateNotes =  async (params: INoteUpdate): Promise<AxiosResponse<INoteInfo>> => {
    return await axiosAuth().post('/student/note/update', params).catch(axiosCatch);
 };

 export const reqDeleteNotes =  async (params: INoteDelete): Promise<AxiosResponse<IResponseNoteDelete>> => {
    return await axiosAuth().post('/student/note/delete', params).catch(axiosCatch);
 };

 export const reqMoveNotes =  async (params: INoteMove): Promise<AxiosResponse<{}>> => {
    return await axiosAuth().post('/student/note/move', params).catch(axiosCatch);
 };

 export const reqCreatePersonalNotes =  async (params: IPersonalNoteCreate,  type?: string): Promise<AxiosResponse<IResponseCreateNote>> => {
      return await axiosAuth().post('/student/personal-note/create', params).catch(axiosCatch);
 };

 export const reqDetaillPersonalNotes =  async (params: any = {}): Promise<AxiosResponse<INoteInfo>> => {
   return await axiosAuth().get('/student/personal-note/info', {params}).catch(axiosCatch);
};

export const reqUpdatePersonalNotes =  async (params: IPersonalNoteUpdate): Promise<AxiosResponse<INoteInfo>> => {
   return await axiosAuth().post('/student/personal-note/update', params).catch(axiosCatch);
};

export const reqDeletePersonalNotes =  async (params: INotePersonalDelete): Promise<AxiosResponse<IResponseNoteDelete>> => {
   return await axiosAuth().post('/student/personal-note/delete', params).catch(axiosCatch);
};

export const reqGetPersonalNotes = async (): Promise<AxiosResponse<IResponsePersonalNote>> => {   
   return await axiosAuth().get('/student/personal-note/list').catch(axiosCatch);
 };