import { TSchedule } from '../types/User';
import { SCHEDULE_GET_LIST, SCHEDULE_STATE, SCHEDULE_UPDATE } from '../actions/types';

export interface ISchedule {
  schedules: TSchedule[];
  loading?: boolean;
  acLoad?: boolean;
}

const INITIAL_STATE: ISchedule = {
  schedules: [],
  loading: false,
  acLoad: false
};
const scheduleReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SCHEDULE_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default scheduleReducer;
