import { IconName, IconName as icons } from '../../pages/v2/components/Icon';

export interface IMenuRoute {
  title: string,
  path: string,
  key: string,
  icon?: string,
  items?: IMenuRoute[],
  fullWith?: boolean,
  hide?: boolean,
  id?: string,
  group?: string[] // key of parent
}

export const MentorRoute: IMenuRoute[] = [
  {
    title: 'Dashboard',
    key: 'mentor.dashboard',
    path: '/',
    icon: IconName.dashboard
  },
  {
    title: 'Khóa học',
    key: 'mentor.course',
    path: '/course',
    icon: IconName.dashboard
  },
  {
    title: 'Học viên',
    key: 'mentor.student',
    path: '/student',
    icon: IconName.users
  },
  {
    title: 'Quiz',
    key: 'mentor.quiz',
    path: '/quiz',
    icon: IconName.dashboard
  },

  {
    title: 'Lịch',
    key: 'mentor.schedule',
    path: '/schedule',
    icon: IconName.calendar
  }

];
export const AdminRoute: IMenuRoute[] = [
  {
    title: 'Dashboard',
    key: 'admin.dashboard',
    path: '/',
    icon: IconName.dashboard
  },
  {
    title: 'Khóa học',
    key: 'admin.course',
    path: '/course',
    icon: IconName.course
  },
  {
    title: 'Lộ trình học',
    key: 'mentor.career',
    path: '/career-path',
    icon: IconName.share
  },
  {
    title: 'Admin',
    icon: IconName.users,
    path: '/accounts',
    key: 'admin.account',
    items: [
      {
        title: 'Học viên',
        key: 'admin.student',
        path: '/students',
        icon: IconName.users
      },
      {
        title: 'Mentors',
        key: 'admin.mentor',
        path: '/mentors',
        icon: IconName.users
      },
      {
        title: 'Account',
        key: 'admin.account',
        path: '/accounts',
        icon: IconName.users
      }
    ]
  },
  {
    title: 'Sinh viên',
    icon: IconName.users,
    path: '/students',
    key: 'admin.student',
  },
  {
    title: 'Mentors',
    key: 'admin.mentor',
    path: '/mentors',
    icon: IconName.users
  },
    
  {
    title: 'Quiz',
    key: 'admin.quiz',
    path: '/quiz',
    icon: IconName.users
  }
];
export const StudentRoute: IMenuRoute[] = [
  {
    title: 'Dashboard',
    key: 'student-overview',
    path: '/',
    icon: icons.dashboard
  },
  {
    title: 'Lộ trình học',
    key: 'student-course',
    path: '/course',
    icon: icons.course
  },
  {
    title: 'Ghi chú bài học',
    key: 'course-notes',
    path: '/course-notes',
    icon: icons.courseNotes
  },
  {
    title: 'Ghi chú cá nhân',
    key: 'personal-course',
    path: '/personal-notes',
    icon: icons.notes
  },
  {
    title: 'Lịch',
    key: 'student-schedule',
    path: '/schedule',
    icon: icons.calendar
  }

];
