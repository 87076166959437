import {
  BlockQuoteIcon,
  BulletedListIcon,
  CodeIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  HorizontalRuleIcon,
  OrderedListIcon,
  PageBreakIcon,
  TableIcon,
  TodoListIcon,
  ImageIcon,
  StarredIcon,
  WarningIcon,
  InfoIcon,
  LinkIcon,
  AttachmentIcon,
  ClockIcon,
  CalendarIcon,
  MathIcon,
} from "outline-icons";
import * as React from "react";
import styled from "styled-components";
import Image from "../../../shared/editor/components/Image";

import { MenuItem } from "../../../shared/editor/types";
import { metaDisplay } from "../../utils/keyboard";
import { globalIntl } from "src/utils/hepler";

const Img = styled(Image)`
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 0 0 1px #fff;
  margin: 4px;
  width: 18px;
  height: 18px;
`;

  export default function blockMenuItems(): MenuItem[] {

  return [
    {
      name: "heading",
      title: globalIntl("editor.h1"),
      keywords: "h1 heading1 title",
      icon: <Heading1Icon />,
      shortcut: "^ ⇧ 1",
      attrs: { level: 1 },
    },
    {
      name: "heading",
      title:  globalIntl("editor.h2"),
      keywords: "h2 heading2",
      icon: <Heading2Icon />,
      shortcut: "^ ⇧ 2",
      attrs: { level: 2 },
    },
    {
      name: "heading",
      title:  globalIntl("editor.h3"),
      keywords: "h3 heading3",
      icon: <Heading3Icon />,
      shortcut: "^ ⇧ 3",
      attrs: { level: 3 },
    },
    {
      name: "separator",
    },
    {
      name: "checkbox_list",
      title: globalIntl("editor.checkboxList"),
      icon: <TodoListIcon />,
      keywords: "checklist checkbox task",
      shortcut: "^ ⇧ 7",
    },
    {
      name: "bullet_list",
      title: globalIntl("editor.bulletList"),
      icon: <BulletedListIcon />,
      shortcut: "^ ⇧ 8",
    },
    {
      name: "ordered_list",
      title: globalIntl("editor.orderedList"),
      icon: <OrderedListIcon />,
      shortcut: "^ ⇧ 9",
    },
    {
      name: "separator",
    },
    {
      name: "image",
      title: globalIntl("editor.image"),
      icon: <ImageIcon />,
      keywords: "picture photo",
    },
    {
      name: "attachment",
      title: globalIntl("editor.file"),
      icon: <AttachmentIcon />,
      keywords: "file upload attach",
    },
    {
      name: "table",
      title: globalIntl("editor.table"),
      icon: <TableIcon />,
      attrs: { rowsCount: 3, colsCount: 3 },
    },
    {
      name: "blockquote",
      title: globalIntl("editor.quote"),
      icon: <BlockQuoteIcon />,
      shortcut: `${metaDisplay} ]`,
    },
    {
      name: "code_block",
      title: globalIntl("editor.codeBlock"),
      icon: <CodeIcon />,
      shortcut: "^ ⇧ \\",
      keywords: "script",
    },
    {
      name: "math_block",
      title: globalIntl("editor.mathBlock"),
      icon: <MathIcon />,
      keywords: "math katex latex",
    },
    {
      name: "hr",
      title: globalIntl("editor.hr"),
      icon: <HorizontalRuleIcon />,
      shortcut: `${metaDisplay} _`,
      keywords: "horizontal rule break line",
    },
    {
      name: "hr",
      title: globalIntl("editor.pageBreak"),
      icon: <PageBreakIcon />,
      keywords: "page print break line",
      attrs: { markup: "***" },
    },
    {
      name: "date",
      title: globalIntl("editor.insertDate"),
      keywords: "clock today",
      icon: <CalendarIcon />,
    },
    {
      name: "time",
      title: globalIntl("editor.insertTime"),
      keywords: "clock now",
      icon: <ClockIcon />,
    },
    {
      name: "datetime",
      title: globalIntl("editor.insertDateTime"),
      keywords: "clock today date",
      icon: <CalendarIcon />,
    },
    {
      name: "separator",
    },
    {
      name: "container_notice",
      title: globalIntl("editor.infoNotice"),
      icon: <InfoIcon />,
      keywords: "notice card information",
      attrs: { style: "info" },
    },
    {
      name: "container_notice",
      title: globalIntl("editor.warningNotice"),
      icon: <WarningIcon />,
      keywords: "notice card error",
      attrs: { style: "warning" },
    },
    {
      name: "container_notice",
      title: globalIntl("editor.tipNotice"),
      icon: <StarredIcon />,
      keywords: "notice card suggestion",
      attrs: { style: "tip" },
    },
    {
      name: "separator",
    },
    {
      name: "code_block",
      title: "Mermaid Diagram",
      icon: <Img src="/images/mermaidjs.png" alt="Mermaid Diagram" />,
      keywords: "diagram flowchart",
      attrs: { language: "mermaidjs" },
    },
  ];
}
