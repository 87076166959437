import {
  BoldIcon,
  CodeIcon,
  Heading1Icon,
  Heading2Icon,
  BlockQuoteIcon,
  LinkIcon,
  StrikethroughIcon,
  OrderedListIcon,
  BulletedListIcon,
  TodoListIcon,
  InputIcon,
  HighlightIcon,
  ItalicIcon,
} from "outline-icons";
import { EditorState } from "prosemirror-state";
import { isInTable } from "prosemirror-tables";
import * as React from "react";
import isInCode from "../../../shared/editor/queries/isInCode";
import isInList from "../../../shared/editor/queries/isInList";
import isMarkActive from "../../../shared/editor/queries/isMarkActive";
import isNodeActive from "../../../shared/editor/queries/isNodeActive";
import { MenuItem } from "../../../shared/editor/types";
import { globalIntl } from "src/utils/hepler";

export default function formattingMenuItems(
  state: EditorState,
  isTemplate: boolean,
): MenuItem[] {
  const { schema } = state;
  const isTable = isInTable(state);
  const isList = isInList(state);
  const isCode = isInCode(state);
  const allowBlocks = !isTable && !isList;

  return [
    {
      name: "placeholder",
      tooltip: globalIntl("editor.placeholder"),
      icon: <InputIcon />,
      active: isMarkActive(schema.marks.placeholder),
      visible: isTemplate,
    },
    {
      name: "separator",
      visible: isTemplate,
    },
    {
      name: "strong",
      tooltip: globalIntl("editor.strong"),
      icon: <BoldIcon />,
      active: isMarkActive(schema.marks.strong),
      visible: !isCode,
    },
    {
      name: "em",
      tooltip: globalIntl("editor.em"),
      icon: <ItalicIcon />,
      active: isMarkActive(schema.marks.em),
      visible: !isCode,
    },
    {
      name: "strikethrough",
      tooltip: globalIntl("editor.strikethrough"),
      icon: <StrikethroughIcon />,
      active: isMarkActive(schema.marks.strikethrough),
      visible: !isCode,
    },
    {
      name: "highlight",
      tooltip: globalIntl("editor.mark"),
      icon: <HighlightIcon />,
      active: isMarkActive(schema.marks.highlight),
      visible: !isTemplate && !isCode,
    },
    {
      name: "code_inline",
      tooltip: globalIntl("editor.codeInline"),
      icon: <CodeIcon />,
      active: isMarkActive(schema.marks.code_inline),
    },
    {
      name: "separator",
      visible: allowBlocks && !isCode,
    },
    {
      name: "heading",
      tooltip: globalIntl("editor.heading"),
      icon: <Heading1Icon />,
      active: isNodeActive(schema.nodes.heading, { level: 1 }),
      attrs: { level: 1 },
      visible: allowBlocks && !isCode,
    },
    {
      name: "heading",
      tooltip: globalIntl("editor.subheading"),
      icon: <Heading2Icon />,
      active: isNodeActive(schema.nodes.heading, { level: 2 }),
      attrs: { level: 2 },
      visible: allowBlocks && !isCode,
    },
    {
      name: "blockquote",
      tooltip: globalIntl("editor.quote"),
      icon: <BlockQuoteIcon />,
      active: isNodeActive(schema.nodes.blockquote),
      attrs: { level: 2 },
      visible: allowBlocks && !isCode,
    },
    {
      name: "separator",
      visible: (allowBlocks || isList) && !isCode,
    },
    {
      name: "checkbox_list",
      tooltip: globalIntl("editor.checkboxList"),
      icon: <TodoListIcon />,
      keywords: "checklist checkbox task",
      active: isNodeActive(schema.nodes.checkbox_list),
      visible: (allowBlocks || isList) && !isCode,
    },
    {
      name: "bullet_list",
      tooltip: globalIntl("editor.bulletList"),
      icon: <BulletedListIcon />,
      active: isNodeActive(schema.nodes.bullet_list),
      visible: (allowBlocks || isList) && !isCode,
    },
    {
      name: "ordered_list",
      tooltip: globalIntl("editor.orderedList"),
      icon: <OrderedListIcon />,
      active: isNodeActive(schema.nodes.ordered_list),
      visible: (allowBlocks || isList) && !isCode,
    },
    {
      name: "separator",
      visible: !isCode,
    },
  ];
}
