// import { formatDistanceToNow } from "date-fns";
// import { deburr, sortBy } from "lodash";
import { DOMParser as ProsemirrorDOMParser } from "prosemirror-model";
import { TextSelection } from "prosemirror-state";
import * as React from "react";
import { mergeRefs } from "react-merge-refs";
import { Optional } from "utility-types";
import insertFiles from "../../shared/editor/commands/insertFiles";
import { Heading } from "../../shared/editor/lib/getHeadings";
// import { AttachmentPreset } from "../../types";
import { getDataTransferFiles } from "../../shared/utils/files";
// import parseDocumentSlug from "../../utils/parseDocumentSlug";
// import { isInternalUrl } from "../../utils/urls";
import { AttachmentValidation } from "../../shared/validations";
// import Document from "../../app/models/Document";
import ClickablePadding from "./ClickablePadding";
// import ErrorBoundary from "../../app/components/ErrorBoundary";
// import HoverPreview from "../../app/components/HoverPreview";
import type { Props as EditorProps, Editor as SharedEditor } from "../editor";
// import useDictionary from "../../app/hooks/useDictionary";
import useEmbeds from "../hooks/useEmbeds";
// import useStores from "../../app/hooks/useStores";
// import useToasts from "../../app/hooks/useToasts";
// import { NotFoundError } from "../../app/utils/errors";
// import { uploadFile } from "../../app/utils/files";
// import history from "../../app/utils/history";
// import { isModKey } from "../../app/utils/keyboard";
// import { sharedDocumentPath } from "../../app/utils/routeHelpers";
import { isHash } from "../utils/urls";

// import DocumentBreadcrumb from "./DocumentBreadcrumb";
// const LazyLoadedEditor = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "preload-shared-editor" */
//       "../editor"
//     )
// );

import LazyLoadedEditor from '../editor/index'
import { ApiResponse, upload } from "src/utils/AxiosHelper";
export type Props = Optional<
  EditorProps,
  | "placeholder"
  | "defaultValue"
  | "onClickLink"
  | "embeds"
  // | "dictionary"
  // | "onShowToast"
  | "extensions"
> & {
  // shareId?: string | undefined;
  embedsDisabled?: boolean;
  onHeadingsChange?: (headings: Heading[]) => void;
  // onSynced?: () => Promise<void>;
  bottomPadding?: string;
};

function Editor(props: Props, ref: React.RefObject<SharedEditor> | null) {
  const {
    //  id, 
    //   shareId, 
    onChange,
    onHeadingsChange
  } = props;
  // const { documents } = useStores();
  // const { showToast } = useToasts();
  // const dictionary = useDictionary();
  const embeds = useEmbeds(true);
  const localRef = React.useRef<SharedEditor>();
  const previousHeadings = React.useRef<Heading[] | null>(null);
  const [
    activeLinkElement,
    setActiveLink,
  ] = React.useState<HTMLAnchorElement | null>(null);

  const handleLinkActive = React.useCallback((element: HTMLAnchorElement) => {
    setActiveLink(element);
    return false;
  }, []);

  // const handleLinkInactive = React.useCallback(() => {
  //   setActiveLink(null);
  // }, []);
  const onUploadFile = React.useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('imageFile', file);
      const result: Promise<string> = (await upload(formData)).data.path[0];
      console.log(result);

      return result

      // const result = await uploadFile(file, {
      //   documentId: id,
      //   preset: AttachmentPreset.DocumentAttachment,
      // });
      // return result.url;
      // return "https://www.google.com"
    },
    ['id']

    // [id]

  );

  const onClickLink = React.useCallback(
    (href: string, event: MouseEvent) => {
      // on page hash
      if (isHash(href)) {
        window.location.href = href;
        return;
      }

      // if (isInternalUrl(href) && !isModKey(event) && !event.shiftKey) {
      //   // relative
      //   let navigateTo = href;

      //   // probably absolute
      //   if (href[0] !== "/") {
      //     try {
      //       const url = new URL(href);
      //       navigateTo = url.pathname + url.hash;
      //     } catch (err) {
      //       navigateTo = href;
      //     }
      //   }

      //   // Link to our own API should be opened in a new tab, not in the app
      //   if (navigateTo.startsWith("/api/")) {
      //     window.open(href, "_blank");
      //     return;
      //   }

      //   // If we're navigating to an internal document link then prepend the
      //   // share route to the URL so that the document is loaded in context
      //   if (shareId && navigateTo.includes("/doc/")) {
      //     navigateTo = sharedDocumentPath(shareId, navigateTo);
      //   }

      //   history.push(navigateTo);
      // } else 
      if (href) {
        window.open(href, "_blank");
      }
    },
    // [shareId]
    []

  );

  const focusAtEnd = React.useCallback(() => {
    localRef?.current?.focusAtEnd();
  }, [localRef]);

  const handleDrop = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const files = getDataTransferFiles(event);

      const view = localRef?.current?.view;
      if (!view) {
        return;
      }

      // Find a valid position at the end of the document to insert our content
      const pos = TextSelection.near(
        view.state.doc.resolve(view.state.doc.nodeSize - 2)
      ).from;

      // If there are no files in the drop event attempt to parse the html
      // as a fragment and insert it at the end of the document
      if (files.length === 0) {
        const text =
          event.dataTransfer.getData("text/html") ||
          event.dataTransfer.getData("text/plain");

        const dom = new DOMParser().parseFromString(text, "text/html");

        view.dispatch(
          view.state.tr.insert(
            pos,
            ProsemirrorDOMParser.fromSchema(view.state.schema).parse(dom)
          )
        );

        return;
      }

      // Insert all files as attachments if any of the files are not images.
      const isAttachment = files.some(
        (file) => !AttachmentValidation.imageContentTypes.includes(file.type)
      );
      console.log('onFileUploadStart phong',
        onUploadFile
      );

      insertFiles(view, event, pos, files, {
        uploadFile: onUploadFile,
        onFileUploadStart: props.onFileUploadStart,
        onFileUploadStop: props.onFileUploadStop,
        // onShowToast: showToast,
        // dictionary,
        isAttachment,
      });
    },
    [
      localRef,
      props.onFileUploadStart,
      props.onFileUploadStop,
      // dictionary,
      onUploadFile,
      // showToast,
    ]
  );

  // see: https://stackoverflow.com/a/50233827/192065
  const handleDragOver = React.useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.stopPropagation();
      event.preventDefault();
    },
    []
  );

  // Calculate if headings have changed and trigger callback if so
  const updateHeadings = React.useCallback(() => {
    if (onHeadingsChange) {
      const headings = localRef?.current?.getHeadings();
      if (
        headings &&
        headings.map((h) => h.level + h.title).join("") !==
        previousHeadings.current?.map((h) => h.level + h.title).join("")
      ) {
        previousHeadings.current = headings;
        onHeadingsChange(headings);
      }
    }
  }, [localRef, onHeadingsChange]);

  const handleChange = React.useCallback(
    (event) => {
      onChange?.(event);
      updateHeadings();
    },
    [onChange, updateHeadings]
  );

  const handleRefChanged = React.useCallback(
    (node: SharedEditor | null) => {
      if (node) {
        updateHeadings();
      }
    },
    [updateHeadings]
  );

  return (
    // <ErrorBoundary reloadOnChunkMissing>
    //   <>
    <LazyLoadedEditor
      ref={mergeRefs([ref, localRef, handleRefChanged])}
      // ref={mergeRefs([ref, localRef])}

      uploadFile={onUploadFile}
      // onShowToast={showToast}
      embeds={embeds}
      // dictionary={dictionary}
      {...props}
      onHoverLink={handleLinkActive}
      onClickLink={onClickLink}
      onChange={handleChange}
      placeholder={props.placeholder || ""}
      defaultValue={props.defaultValue || ""}
    />
    // {props.bottomPadding && !props.readOnly && (
    //   <ClickablePadding
    //     onClick={focusAtEnd}
    //     onDrop={handleDrop}
    //     onDragOver={handleDragOver}
    //     minHeight={props.bottomPadding}
    //   />
    // )}
    //  {activeLinkElement && !shareId && (
    //   <HoverPreview
    //     element={activeLinkElement}
    //     onClose={handleLinkInactive}
    //   />
    // )} 
    //   </>
    // </ErrorBoundary>
  );
}

export default React.forwardRef(Editor);
