import { FC } from 'react';
import styles from './modules/checkbox.module.scss';

interface TProps {
  label?: string;
  checked?: boolean;
  color: string;
}

const Checkbox: FC<TProps> = ({ label, checked, color, ...props }) => {
  const checkStyle = {
    '--color': color
  };
  console.log('chec', checkStyle);
  // @ts-ignore
  return <div style={checkStyle} className={styles.checkbox}>
    <input checked={checked} {...props} className={styles.input} />
    <span className={styles.mask} />
  </div>;
};
export default Checkbox;
