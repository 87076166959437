import React, { Suspense, memo, useEffect, FC } from 'react';
import { Redirect, RouteProps, useHistory, useLocation, useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { AppState } from '../../redux/reducers';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';

import './../../theme-version2.scss';
import LoadingHint from '../components/LoadingHint';
import Login from './auth/Login';
import Layout from './Layout';
import AppLocale from '../../lngProvider';
import { IntlProvider } from 'react-intl';

interface RestrictedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  token: string | null;
  useRole: string | null;
}

const ThemeDesign = () => {
  const token = localStorage.getItem('auth');
  const typeLogin = localStorage.getItem('typeLogin');

  const { initURL } = useSelector((app: AppState) => app.auth);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const currentAppLocale = AppLocale['vi'];

  useEffect(() => {
    if (location.pathname === '/') {
      if (token === null) {
        history.push('/login');
      } else {

        if (initURL === '' || initURL === '/' || initURL === '/login') {
          history.push('/');
        } else {
          history.push(initURL);
        }
      }
    }
  }, [history, initURL, location.pathname]);

  const RestrictedRoute: FC<RestrictedRouteProps> = ({ component: Component, location, token, ...rest }) => {
    return <Route
      {...rest}
      render={props =>
        token
          ? <Component userRole={rest.useRole} {...props} />
          : <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />}
    />;
  };

  return <div className='main-layout max-h-full h-full xl:p-7 p-0 overflow-x-hidden'>
    <ConfigProvider
      locale={currentAppLocale.antd}
      theme={{
        components: {
          Button: {
            colorPrimaryBg: '#2E61D6'
          },
          Slider: {
            handleActiveColor: '#F24E1E',
            handleColor: '#F24E1E'
          },
          Menu: {
            fontSize: 16,
            activeBarBorderWidth: 0,
            controlItemBgActive: `transparent`,
            itemHoverBg: `transparent`,
            itemHoverColor: '#1362DE',
            itemSelectedColor: '#1362DE',
            itemColor: '#9295A3'
          }
        }
      }}>
      <IntlProvider
        locale={currentAppLocale.antd.locale}
        messages={currentAppLocale.messages}>
        <Suspense fallback={<LoadingHint />}>
          <div className='2xl:container mx-auto'>
            <Switch>
              <Route exact path='/login' component={Login} />
              <Route exact path='/login-mentor' component={Login} />
              <Route exact path='/login-admin' component={Login} />

              <RestrictedRoute
                useRole={typeLogin}
                path={`${match.url}`}
                token={token}
                location={location}
                component={Layout} />

            </Switch>
          </div>
        </Suspense>
      </IntlProvider>
    </ConfigProvider>

  </div>;
};
export default memo(ThemeDesign);
