import { all } from 'redux-saga/effects';
import commonSagas from './common';
import authSagas from './auth';
import mentorSagas from './mentor';
import courseSagas from './v2/course';
import careerPathSagas from './v2/career-path';
import adminSagas from './admin';
import studentSagas from './student';
import quizSagas from './quiz';
import scheduleSagas from './schedule';

export default function* rootSaga() {
  yield all([
    commonSagas(),
    authSagas(),
    mentorSagas(),
    courseSagas(),
    adminSagas(),
    studentSagas(),
    quizSagas(),
    scheduleSagas(),
    careerPathSagas()
  ]);
}
