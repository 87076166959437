import axios, { AxiosError, AxiosResponse } from 'axios';
import { history } from 'src/redux/store';

export interface ApiResponse<T> {
  data: T,
  message?: string | number
  code?: string | number,
  status?: string | number
}

export interface RefreshTokenResponse {
  data: {
    accessToken: string,
    refreshToken: string
  };
}

const {
  REACT_APP_API_URL,
  REACT_APP_API_V2_URL,
  REACT_APP_API_ARTICLE,
  REACT_APP_API_ARTICLE_TOKEN,
  REACT_APP_OPEN_AI_KEY
} = process.env;

export const openApi = async (body: any) => {
  return await fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_OPEN_AI_KEY}`
    },
    body: JSON.stringify({
      model: 'gpt-3.5-turbo',
      messages: body,
      temperature: 0,
      stream: true
    })
  });
};

const instance = axios.create({
  baseURL: REACT_APP_API_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});
const instanceV2 = axios.create({
  baseURL: REACT_APP_API_V2_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const docApi = axios.create({
  baseURL: REACT_APP_API_ARTICLE,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${REACT_APP_API_ARTICLE_TOKEN}`
  }
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instanceV2.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const commonResponse = async (error: any) => {
  const originalRequest = error.config;
  const status = error.response.status;

  if (status === 503 || status === 500) {
    // window.location.replace('/service-unavailable');
    return axiosCatch(error);
  }
  const typeLogin = localStorage.getItem('typeLogin');
  const path = typeLogin == 'admin' ? '/login-admin' : typeLogin === 'mentor' ? '/login-mentor' : '/login';
  let count = 1;
  if (status === 403 || status === 401) {
    const refreshToken = localStorage.getItem('refreshToken');
    const authToken = localStorage.getItem('auth');
    try {
      if (count === 5) {
        window.location.replace(path);
        return;
      }
      const response = await axios.post(`${REACT_APP_API_URL}/auth/refresh-token`, { refreshToken }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });
      localStorage.setItem('auth', response.data.data.accessToken);
      localStorage.setItem('refreshToken', response.data.data.refreshToken);
      instance.defaults.headers.common.Authorization = `Bearer ${response.data.data.accessToken}`;
      count++;
      return instance(originalRequest);
    } catch (error) {
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('refreshToken');
      if (!history.location.pathname.match(/login/i)) {
        window.location.replace(path);
      }
    }
  }
  return Promise.reject(error);
};
instance.interceptors.response.use(
  (response) => {
    return response;
  }, commonResponse);

instanceV2.interceptors.response.use((response) => {
  return response;
}, commonResponse);

export const axiosAuth = (v2?: boolean) => v2 ? instanceV2 : instance;

export const axiosCatch = (error: any) => {
  if (error.response) {
    throw error.response.data;
  } else if (error.request) {
    throw {
      message: error.message
    };
  } else {
    throw error;
  }
};

export const UPLOAD_HEADER = {
  'Content-Type': 'multipart/form-data'
};

// get data


//service
export const upload = async (formData: FormData) => axiosAuth().post('api/uploads', formData, {
  headers: { ...UPLOAD_HEADER }
});

// call api
