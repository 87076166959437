import Header from '../../components/Header';
import Calendar from '../../components/Calendar';
import ScheduleCalendar from '../../components/ScheduleCalendar';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/reducers';
import { useEffect } from 'react';
import { SCHEDULE } from '../../../../redux/actions/actionTypes';
import { TSchedule } from '../../../../redux/types/User';
import Checkbox from '../../components/Checkbox';

const Schedule = () => {

  const dispatch = useDispatch();
  const { schedules } = useSelector((app: AppState) => app.schedule);

  useEffect(() => {
    dispatch(SCHEDULE.LIST({}));
  }, []);

  return <div>
    <Header className='mb-2 z-all' title={<h3 className='text-[32px] font-bold'>Schedule</h3>} />
    <div className='md:h-sidebar md:overflow-hidden rounded-2xl'>
      <div className='grid md:gap-4 lg:grid-cols-12 grid-cols-1 overflow-hidden'>
        <div className='col-span-8'>
          <div className='bg-white rounded-2xl max-h-box overflow-y-auto'>
            <ScheduleCalendar schedules={schedules} />
          </div>
        </div>
        <div className='md:col-span-4 md:my-0 my-5'>
          <div className='bg-white p-3 h-full rounded-2xl max-h-box overflow-y-auto'>
            <Calendar showBtnAdd />

            <div className='mt-8'>
              <div className='flex justify-between'>
                <div className='flex flex-col items-center justify-center'>
                <span
                  className='flex items-center justify-center w-14 h-14 rounded-full bg-green bg-opacity-10 text-green text-2xl font-bold'>08</span>
                  <span>Course in progress</span>
                </div>
                <div className='flex flex-col items-center justify-center'>
                <span
                  className='flex items-center justify-center w-14 h-14 rounded-full bg-primary bg-opacity-10 text-primary text-2xl font-bold'>23</span>
                  <span>Course Completed</span>
                </div>
              </div>
            </div>
            <div className='mt-8'>
              <h3 className='text-dark text-xl font-medium mb-2'>Lịch của tôi</h3>
              {schedules && schedules.map((item: TSchedule) => {
                const color = item.event_color || '#52c51a';

                return <div className='flex items-center space-x-3 py-2'>
                  <Checkbox color={color} checked />
                  <div className='flex flex-col'>
                    <h1 className='text-sm font-medium leading-none'>{item.title}</h1>
                  </div>
                </div>;
              })}

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};
export default Schedule;
