import { ApiResponse, axiosAuth, axiosCatch } from '../../utils/AxiosHelper';
import { AxiosResponse } from 'axios';

export const reqQuizInfo = async (quizId: string) => {
  return await axiosAuth().get(`/api/quiz/info`, { params: { quizId } }).catch(axiosCatch);
};

export const reqGetCollectionInfo = async (collectionQuizId: string) => {
  return await axiosAuth().get(`/api/collection-quiz/info`, { params: { collectionQuizId } }).catch(axiosCatch);
};

export const reqGetCollectionList = async (): Promise<ApiResponse<any>> => {
  return await axiosAuth().get(`/api/collection-quiz/list`).catch(axiosCatch);
};
