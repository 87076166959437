import { Modal, message, Drawer } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON, STUDENT } from 'src/redux/actions/actionTypes';
import { ListPersonalCollectionNotes } from 'src/redux/types/User';
import NotesPersonal from './component/NotesPersonal';
import { PlusOutlined } from '@ant-design/icons';
import { renderSwitcherIcon } from '../../components/SwitcherIcon';
import { AppState } from 'src/redux/reducers';
import { EventDataNode } from 'antd/es/tree';
import { reqDeletePersonalNotes, reqDeletePersonalNotesCollection } from 'src/redux/services/student';
import { findParentId } from './component/NotesPersonalHelper';
import { CourseNotesProps, TYPES_NOTES } from 'src/redux/actions/types';
import TreeList, { DataNode } from './component/TreeList';
import Header from '../../components/Header';
import Icon, { IconName } from '../../components/Icon';

const PersonalNotes = () => {
  const {
    collectionPersonalNotes
  }: { collectionPersonalNotes: ListPersonalCollectionNotes, loading: boolean } = useSelector((app: AppState) => app.student);
  const dispatch = useDispatch();
  const [notesInfo, setNotesInfo] = useState<CourseNotesProps>();
  const [dataNodes, setDataNodes] = useState<DataNode[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showContent, setShowContent] = useState<boolean>(false);

  useEffect(() => {
    if (collectionPersonalNotes) {
      const data: DataNode[] = collectionPersonalNotes && convertCollectionToData(collectionPersonalNotes);
      setDataNodes(data);
    }

  }, [collectionPersonalNotes]);
  useEffect(() => {
    dispatch(STUDENT.GET_NOTES_PERSONAL_COLLECTION());
  }, []);

  const convertCollectionToData = (
    collectionNotes: ListPersonalCollectionNotes
  ): DataNode[] => {
    const parentNoteIds = collectionPersonalNotes.map((item) => item.id);
    return collectionNotes.map((note, index) => {
      const isParentNote = parentNoteIds.includes(note.id);
      const dataNode: DataNode = {
        key: note.id,
        title: note.title,
        children: convertCollectionToData(note.children),
        collectionPersonalNoteId: isParentNote ? '' : findParentId(collectionPersonalNotes, note.id),
        parentPersonalNoteId: isParentNote ? '' : note.id,
        type: isParentNote ? 'parent' : 'children'
      };
      return dataNode;
    });
  };

  const onGetList = (notesId: string) => {
    setSelectedKeys([notesId]);
    if (notesInfo?.typeNotes == TYPES_NOTES.CREATE_NOTES) {
      setNotesInfo({ typeNotes: TYPES_NOTES.INFO_NOTES, id: notesId });
    }
    if (
      notesInfo?.typeNotes == TYPES_NOTES.CREATE_COLLECTION) {
      setNotesInfo({ typeNotes: TYPES_NOTES.INFO_COLLECTION, id: notesId });
    }
    dispatch(STUDENT.GET_NOTES_PERSONAL_COLLECTION());
  };

  const renderNotesPersonal = useCallback(() => {
    if (!notesInfo) {
      if (dataNodes && dataNodes.length && dataNodes.length > 0) {
        setNotesInfo({ id: dataNodes[0].key, typeNotes: TYPES_NOTES.INFO_COLLECTION });
        setSelectedKeys([dataNodes[0].key]);
      }
    } else if (notesInfo.typeNotes == TYPES_NOTES.INFO_COLLECTION) {
      return <NotesPersonal
        collectionPersonalNoteId={notesInfo.id}
        typeNotes={notesInfo.typeNotes}
        key={notesInfo.id!}
        onGetList={onGetList}
      />;
    } else if (notesInfo.typeNotes == TYPES_NOTES.INFO_NOTES) {
      return <NotesPersonal
        id={notesInfo.id}
        typeNotes={notesInfo.typeNotes}
        key={notesInfo.id!}
        onGetList={onGetList}
      />;
    } else if (notesInfo.typeNotes == TYPES_NOTES.CREATE_NOTES) {
      return <NotesPersonal
        collectionPersonalNoteId={notesInfo.collectionPersonalNoteId}
        parentPersonalNoteId={notesInfo.parentPersonalNoteId}
        typeNotes={notesInfo.typeNotes}
        key={notesInfo.collectionPersonalNoteId!}
        onGetList={onGetList}
      />;
    } else if (notesInfo.typeNotes == TYPES_NOTES.CREATE_COLLECTION) {
      return <NotesPersonal
        typeNotes={notesInfo.typeNotes}
        key={notesInfo.typeNotes}
        onGetList={onGetList}
      />;
    }
    return <div />;
  }, [notesInfo, dataNodes]);

  const onDelete = (id: string, type: string) => {
    setDropdownOpen(false);
    Modal.confirm({
      title: `Xác nhận xoá ghi chú`,
      content: `Ghi chú sẽ không khôi phục được.Bạn có chắc chắn muốn xoá ghi chú này?`,
      onOk: () => {
        type == 'children' ?
          reqDeletePersonalNotes({ id: id }).then(() => {
            message.success('Xóa thành công');
            setNotesInfo(undefined);
            dispatch(STUDENT.GET_NOTES_PERSONAL_COLLECTION());
          }
          ) :
          reqDeletePersonalNotesCollection({ id: id }).then(() => {
            message.success('Xóa thành công');
            setNotesInfo(undefined);
            dispatch(STUDENT.GET_NOTES_PERSONAL_COLLECTION());
          }
          );
      }
    });
  };
  const onSelect = (selectedKeys: React.Key[], info: {
    event: 'select';
    selected: boolean;
    node: EventDataNode<DataNode>;
    selectedNodes: DataNode[];
    nativeEvent: MouseEvent;
  }) => {
    setSelectedKeys(selectedKeys);
    if (dropdownOpen) {
      return;
    }
    if (selectedKeys.length > 0) {
      if (info.node.type == 'children') {
        setNotesInfo({
          id: selectedKeys[0].toString(),
          typeNotes: TYPES_NOTES.INFO_NOTES
        });
      } else {
        setNotesInfo({
          id: selectedKeys[0].toString(),
          typeNotes: TYPES_NOTES.INFO_COLLECTION
        });
      }
    }
  };

  return <>
    <Header
      className='mb-2 z-all'
      title={<div className='flex justify-between'>
        <h3 className='font-bold mt-0 text-[32px]'>Ghi chú cá nhân</h3>
      </div>}
    />
    <div
    className='max-h-box overflow-hidden grid w-full grid-cols-5 gap-4'>
      <div
        className='max-h-box sticky md:block hidden col-span-2 bg-white rounded-lg shadow-card '>
        <PlusOutlined
          className='z-all shadow-card bg-primary absolute right-6 bottom-6 rounded-full w-12 h-12 flex justify-center items-center text-lg text-white'
          onClick={(e) => {
            e.stopPropagation();
            setNotesInfo({ typeNotes: TYPES_NOTES.CREATE_COLLECTION });
          }} />

        <div className='overflow-y-auto max-h-box lg:p-5'
        >
          {dataNodes && dataNodes.length && dataNodes.length > 0 ?
            <TreeList
              style={{}}
              setDropdownOpen={setDropdownOpen}
              setNotesInfo={setNotesInfo}
              onDelete={onDelete}
              dataNodes={dataNodes}
              onSelect={onSelect}
              selectedKeys={selectedKeys}
           
            /> : <div />
          }
        </div>
      </div>

      <div className='max-h-box overflow-y-auto md:col-span-3 rounded-lg col-span-5'>
        <div className='md:hidden flex justify-between items-center'>
          <h3 className='text-xl font-medium'>Ghi chú cá nhân</h3>
          <button onClick={() => setShowContent(!showContent)}
            className='lg:hidden bg-primary ms-2 h-8 w-8 rounded flex justify-between items-center'>
            <svg className='m-auto' width='12' height='12' viewBox='0 0 12 12' fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path d='M7 0H5V5H0V7H5V12H7V7H12V5H7V0Z' fill='white' />
            </svg>
          </button>
        </div>
        <div className='min-h-sidebar bg-white rounded-lg shadow-card'>
          {renderNotesPersonal()}
        </div>
      </div>

      <Drawer
        onClose={() => setShowContent(!showContent)}
        open={showContent}
        rootStyle={{ top: 80 }}
        headerStyle={{ display: 'none' }}
        bodyStyle={{ padding: 14 }}
        contentWrapperStyle={{ boxShadow: '-7px 4px 71px 0 rgba(108, 126, 147, 0.15)' }}
      >
        <div className='flex justify-between items-center'>
          <h3 className='text-3xl lg:font-bold'>Ghi chú cá nhân</h3>
          <button onClick={() => setShowContent(!showContent)}
            className='ms-2 h-8 w-8 rounded flex justify-between items-center'>
            <svg width='24' height='24' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M5.06035 4.00006L7.36035 1.70006C7.65035 1.41006 7.65035 0.930065 7.36035 0.640065C7.21921 0.500585 7.02878 0.422363 6.83035 0.422363C6.63192 0.422363 6.44149 0.500585 6.30035 0.640065L4.00035 2.94006L1.70035 0.640065C1.55921 0.500585 1.36878 0.422363 1.17035 0.422363C0.971921 0.422363 0.781489 0.500585 0.640352 0.640065C0.350352 0.930065 0.350352 1.41006 0.640352 1.70006L2.94035 4.00006L0.640352 6.30007C0.350352 6.59007 0.350352 7.07006 0.640352 7.36006C0.790352 7.51006 0.980351 7.58006 1.17035 7.58006C1.36035 7.58006 1.55035 7.51006 1.70035 7.36006L4.00035 5.06007L6.30035 7.36006C6.45035 7.51006 6.64035 7.58006 6.83035 7.58006C7.02035 7.58006 7.21035 7.51006 7.36035 7.36006C7.65035 7.07006 7.65035 6.59007 7.36035 6.30007L5.06035 4.00006Z'
                fill='#2E61D6' />
            </svg>
          </button>
        </div>
        <TreeList
          setDropdownOpen={setDropdownOpen}
          setNotesInfo={setNotesInfo}
          onDelete={onDelete}
          dataNodes={dataNodes}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          switcherIcon={({ expanded, ...props }: any) => {
            return <Icon
              {...props}
              classIcon='mt-1.5'
              name={expanded ? IconName.switcherDown : IconName.switcherRight}
              width={expanded ? 16 : 9} height={expanded ? 16 : 9}
              showMask={false} />;
          }}
        />
      </Drawer>
    </div>
  </>;
};

export default PersonalNotes;
