import { Image } from 'antd';

const LoadingHint = () => {
  return <div className='loading-hint'>
    <Image src='/assets/img/Logo.png' width={300} preview={false} />
    <div className='loading'>
      <div className='loading__letter'>L</div>
      <div className='loading__letter'>o</div>
      <div className='loading__letter'>a</div>
      <div className='loading__letter'>d</div>
      <div className='loading__letter'>i</div>
      <div className='loading__letter'>n</div>
      <div className='loading__letter'>g</div>
      <div className='loading__letter'>.</div>
      <div className='loading__letter'>.</div>
      <div className='loading__letter'>.</div>
    </div>
  </div>;
};
export default LoadingHint;
