import { Drawer } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON, STUDENT } from 'src/redux/actions/actionTypes';
import { CourseNote } from 'src/redux/types/User';
import NoteCourse from './component/NoteCourse';
import Icon, { IconName } from '../../components/Icon';
import TreeMenu from '../../components/TreeMenu';
import { AppState } from 'src/redux/reducers';
import { EventDataNode } from 'antd/es/tree';
import { reqGetStdCourseInfo } from 'src/redux/services/student';
import { useHistory } from 'react-router';
import { renderSwitcherIcon } from '../../components/SwitcherIcon';
import Header from '../../components/Header';

interface CourseNotesProps {
  notesId?: string;
  typeNotes?: string;
  course_id?: string;
  subject_id?: string;
  lesson_group_id?: string;
}

interface DataNode {
  key: string;
  title: string;
  children?: DataNode[];
  type?: string;
  course_id?: string;
  subject_id?: string;
  lesson_group_id?: string;
}

const convertCourseNotes = (listCourseNotes: CourseNote[]): DataNode[] => {
  const convertNode = (node: CourseNote, parentCourseId?: string, parentSubjectId?: string): DataNode => {
    const convertedNode: DataNode = {
      key: node.id,
      title: node.title,
      children: [],
      type: node.children.length > 0 ? 'children' : 'child',
      course_id: parentCourseId || node.course_id,
      subject_id: parentSubjectId || node.subject_id,
      lesson_group_id: node.lesson_group_id
    };

    for (const child of node.children) {
      convertedNode.children!.push(convertNode(child, convertedNode.course_id, convertedNode.subject_id));
    }

    return convertedNode;
  };

  return listCourseNotes.map((courseNote) => ({
    ...convertNode(courseNote),
    type: 'parent'
  }));
};


const CourseNotes = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const { listCourseNotes }: { listCourseNotes: CourseNote[] } = useSelector((app: AppState) => app.student);
  const [notesInfo, setNotesInfo] = useState<CourseNotesProps>();
  const [showContent, setShowContent] = useState<boolean>(false);
  const dataNodes: DataNode[] = convertCourseNotes(listCourseNotes);


  useEffect(() => {
    dispatch(STUDENT.GET_LIST_COURSE_NOTES());
  }, []);

  const showNoteContent = useMemo(() => {
    if (!notesInfo && dataNodes.length > 0) {
      setNotesInfo({ notesId: dataNodes[0].key, typeNotes: dataNodes[0].type });
      return <NoteCourse
        id={dataNodes[0].key}
        key={dataNodes[0].key}
      />;
    }
    return <div>
      {notesInfo?.typeNotes === 'child' &&
        <div className='absolute cursor-pointer ml-8 mt-4'
             onClick={() => handleNavigateCourse(notesInfo.course_id, notesInfo.subject_id, notesInfo.lesson_group_id)}>
          <Icon name={IconName.backsquare} />
        </div>
      }
      <NoteCourse
        id={notesInfo?.notesId}
        typeNotes={notesInfo?.typeNotes}
        key={notesInfo?.notesId} />
    </div>;
  }, [notesInfo, dataNodes]);

  const handleNavigateCourse = async (course_id?: string, subject_id?: string, lesson_group_id?: string) => {
    const course = await reqGetStdCourseInfo({ courseId: course_id });
    const firstLessonId = course.data.listSubject
      .find((subject: any) => subject.id === subject_id)
      .listLessonGrp.find((lessonGrp: any) => lessonGrp.id === lesson_group_id)
      .listLessons[0].id;
    history.push(`course/${course_id}/${subject_id}/${lesson_group_id}/${firstLessonId}`);
  };

  const onSelect = (selectedKeys: React.Key[], info: {
    event: 'select';
    selected: boolean;
    node: EventDataNode<DataNode>;
    selectedNodes: DataNode[];
    nativeEvent: MouseEvent;
  }) => {
    if (selectedKeys.length > 0) {
      if (info.node.type !== 'child') {
        setNotesInfo({ notesId: selectedKeys[0].toString(), typeNotes: info.node.type });
      } else {
        setNotesInfo({
          notesId: selectedKeys[0].toString(), typeNotes: info.node.type,
          course_id: info.node.course_id, subject_id: info.node.subject_id, lesson_group_id: info.node.lesson_group_id
        });
      }
    }
  };
  const [focusKey, setFocusKey] = React.useState<string | number>();

  return <>
    <Header
      className='mb-2 z-all'
      title={<div className='flex justify-between'>
        <h3 className='font-bold mt-0 text-[32px]'>Ghi chú bài học</h3>
      </div>}
    />
    <div className='max-h-box overflow-hidden grid w-full grid-cols-5 gap-4'>
      <div className='max-h-box overflow-y-auto md:block hidden col-span-2 bg-white rounded-lg shadow-card md:p-5'>
      {dataNodes && dataNodes.length && dataNodes.length > 0 ?
      <TreeMenu
          titleRender={(node: DataNode) => {
            const fontSize = node.type === 'parent' ? 'text-[22px]' : 'text-[15px]';
            const fonWeight = node.type === 'parent' ? 'font-medium' : '';
            const fontColor = node.type === 'parent' ? 'text-primary' : 'text-black';
            const marginBottom = node.type === 'child' ? 'mb-1' : '';
            return <div
              className={`w-full justify-between items-center ${marginBottom}`}>
              <div className='flex items-top'>
                <span className={`${fontSize} ${fonWeight} ${fontColor}`}>{node.title}</span>
              </div>
            </div>;
          }}
          defaultSelectedKeys={[dataNodes[0].key]}
          switcherIcon={({ expanded, ...props }: any) => {
            return props.data.key == focusKey ? <Icon
              {...props}
              name={expanded ? IconName.switcherDown : IconName.switcherRight}
              width={expanded ? 16 : 9} height={expanded ? 16 : 9}
              showMask={false} /> : <div />;
          }} 
          onMouseEnter={(e) => setFocusKey(e.node.key)}

                    onSelect={onSelect}
          onClick={(e) => e.preventDefault()}
          treeData={dataNodes} 
          autoExpandParent={true}
          defaultExpandAll={true}
          /> : <div/>}
      </div>
      <div className='max-h-box overflow-y-auto rounded-lg md:col-span-3 col-span-5'>
        <div className='md:hidden flex justify-between items-center'>
          <h3 className='text-xl font-medium'>Ghi chú bài học</h3>
          <button onClick={() => setShowContent(!showContent)}
                  className='lg:hidden bg-primary ms-2 h-8 w-8 rounded flex justify-between items-center'>
            <svg className='m-auto' width='12' height='12' viewBox='0 0 12 12' fill='none'
                 xmlns='http://www.w3.org/2000/svg'>
              <path d='M7 0H5V5H0V7H5V12H7V7H12V5H7V0Z' fill='white' />
            </svg>
          </button>
        </div>
        <div className='min-h-sidebar bg-white rounded-lg shadow-card'>
          {showNoteContent}
        </div>
      </div>


      <Drawer
        onClose={() => setShowContent(!showContent)}
        open={showContent}
        rootStyle={{ top: 80 }}
        headerStyle={{ display: 'none' }}
        bodyStyle={{ padding: 14 }}
        contentWrapperStyle={{ boxShadow: '-7px 4px 71px 0 rgba(108, 126, 147, 0.15)' }}
      >
        <div className='flex justify-between items-center'>
          <h3 className='text-3xl lg:font-bold'>Ghi chú bài học</h3>
          <button onClick={() => setShowContent(!showContent)}
                  className='ms-2 h-8 w-8 rounded flex justify-between items-center'>
            <svg width='24' height='24' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M5.06035 4.00006L7.36035 1.70006C7.65035 1.41006 7.65035 0.930065 7.36035 0.640065C7.21921 0.500585 7.02878 0.422363 6.83035 0.422363C6.63192 0.422363 6.44149 0.500585 6.30035 0.640065L4.00035 2.94006L1.70035 0.640065C1.55921 0.500585 1.36878 0.422363 1.17035 0.422363C0.971921 0.422363 0.781489 0.500585 0.640352 0.640065C0.350352 0.930065 0.350352 1.41006 0.640352 1.70006L2.94035 4.00006L0.640352 6.30007C0.350352 6.59007 0.350352 7.07006 0.640352 7.36006C0.790352 7.51006 0.980351 7.58006 1.17035 7.58006C1.36035 7.58006 1.55035 7.51006 1.70035 7.36006L4.00035 5.06007L6.30035 7.36006C6.45035 7.51006 6.64035 7.58006 6.83035 7.58006C7.02035 7.58006 7.21035 7.51006 7.36035 7.36006C7.65035 7.07006 7.65035 6.59007 7.36035 6.30007L5.06035 4.00006Z'
                fill='#2E61D6' />
            </svg>
          </button>
        </div>
        {dataNodes && dataNodes.length && dataNodes.length > 0 ?
        <TreeMenu
          titleRender={(node: DataNode) => {
            const fontSize = node.type === 'parent' ? 'text-[22px]' : 'text-[15px]';
            const fonWeight = node.type === 'parent' ? 'font-medium' : '';
            const fontColor = node.type === 'parent' ? 'text-primary' : 'text-black';
            const marginBottom = node.type === 'child' ? 'mb-1' : '';
            return <div
              className={`w-full justify-between items-center ${marginBottom}`}>
              <div className='flex items-top'>
                <span className={`ms-3 ${fontSize} ${fonWeight} ${fontColor}`}>{node.title}</span>
              </div>
            </div>;
          }}
          defaultSelectedKeys={[dataNodes[0].key]}
          switcherIcon={renderSwitcherIcon}
          onSelect={onSelect}
          onClick={(e) => e.preventDefault()}
          treeData={dataNodes}
          autoExpandParent={true}
          defaultExpandAll={true}
          />: <div/>}
      </Drawer>
    </div>
  </>;
};

export default CourseNotes;
