import { ActionType } from '../actions/actionTypes';
import { DETAIL_MENTOR_SUCCESS, GET_LIST_MENTOR_SUCCESS, MENTOR_STATE } from '../actions/types';
import { IResponseDetailMentor } from '../types/Admin';

export interface IManageMentorState {
  success: boolean,
  listMentor: any,
  detailMentor: IResponseDetailMentor,
  loading: boolean,
}

const initialState: IManageMentorState = {
  success: true,
  listMentor: [],
  loading: false,
  detailMentor: {} as IResponseDetailMentor,

};
const manageMentorReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case GET_LIST_MENTOR_SUCCESS:
      return { ...state, listMentor: action.payload.data };
      case DETAIL_MENTOR_SUCCESS:
        return { ...state, detailMentor: action.payload.data };
    case MENTOR_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
export default manageMentorReducer;
