import { lazy } from 'react';
import PersonalNotes from 'src/pages/v2/student/personal_notes';
import CourseNotes from '../student/course_notes';
import Schedule from '../student/schedule';

const Dashboard = lazy(() => import('../student/dashboard/index'));
const Course = lazy(() => import('../student/course/index'));
const Lesson = lazy(() => import('../student/course/Lesson'));

const student = [
  {
    path: '/',
    component: Dashboard,
    key: 'student.dashboard',
    exact: true
  }
];
export const publicStudent = [
  {
    path: '/course',
    component: Course,
    key: 'student-course',
    exact: true
  },
  {
    path: '/personal-notes',
    component: PersonalNotes,
    key: 'personal-course',
    exact: true
  },
  {
    path: '/course-notes',
    component: CourseNotes,
    key: 'course-notes',
    exact: true
  },
  {
    path: '/course/:courseId',
    component: Lesson,
    key: 'student-detail',
    group: ['student-course'],
    exact: true
  },
  {
    path: '/course/:pathId/:courseId/:lessonId',
    component: Lesson,
    key: 'student-lesson',
    group: ['student-course']
  },
  {
    path: '/schedule',
    component: Schedule,
    key: 'student-schedule',
    group: ['student-schedule']
  }

];
export default student;
