export enum EChatStt {
  pending,
  processing,
  completed,
  rejected
}

export enum ETestSchedule {
  pending,
  processing,
  completed,
}

export interface TQuestion {
  questionId: number | string;
  chose: string;
  correct: boolean
}

export interface TMetaData {
  answerQuiz: TQuestion[];
}

export interface TProcessData {
  lessonId: string;
  status: number;
  metaData?: TMetaData;
}

export const ECourseType = {
  necessary: 'compulsory',
  basic: 'normal'
};
export const ESubjectType = {
  learning: 'learning',
  test: 'test'
};
export const ELessonType = {
  video: 'video',
  article: 'article',
  quiz: 'quiz'
};


export interface ChatRequestItem {
  id: number | string,
  name: string,
  status: EChatStt
}

export interface IProgressInfo {
  progress: number;
  state: number;
  subject_current_id?: string;
}

export interface ICourseItem {
  id: string | number;
  title: string;
  typeCourse: any;
  type_course: string;
  description: string;
  listSubject?: ISubjectItem[],
  compulsory_course?: any,
  numberOfSubject?: number,
  enrolled?: boolean,
  IProgressInfo?: IProgressInfo,
  detail?: any
}


export interface TestScheduleItem {
  id: number | string,
  name: string,
  source: string,
  status: ETestSchedule
  startAt: string
}

export interface ChatListResponse {
  data: ChatRequestItem[],
  pageSize: number,
  current: number,
  total: number
}

export interface TestScheduleResponse {
  data: TestScheduleItem[],
  pageSize: number,
  current: number,
  total: number
}

export interface ICourseDetailResponse {
  data: ICourseItem;
}

export interface ISubjectResponse extends ICourseDetailResponse {
}

export interface ILessonGroupResponse extends ICourseDetailResponse {
}

export interface ICourseResponse {
  listCourse: ICourseItem[],
  pageSize?: number,
  current?: number,
  numberOfCourse: number,
}

export interface ILessonItem {
  title: string;
  typeLesson: string,
  url: string;
  description: string;
  offsetDayLearn: number;
  lessonId: string,
}

export interface ILessonGroupItem {
  description: string;
  id: string;
  list: ILessonItem[];
  numberOfLesson: number;
  title: string;
}

export interface ISubjectItem {
  courseId: string | number,
  id: number | string,
  content?: string,
  title: string,
  subjectType: any,
  description?: string | number,
  subjectDepend?: string,
  numberOfLessonGrp?: number,
  list?: any,
  progressSubjectInfo?: IProgressInfo
}
