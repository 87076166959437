import { Badge, DatePicker, Form, Input, Modal, Select, Space, TimePicker } from 'antd';
import React, { FC, SetStateAction, useEffect } from 'react';
import { colors } from 'src/redux/contants';
import { TSchedule, TScheduleAdd } from '../../../redux/types/User';
import { SCHEDULE } from '../../../redux/actions/actionTypes';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

interface TProps {
  activeDay: any,
  setOpen: React.Dispatch<SetStateAction<boolean>>,
  acLoad: boolean,
  open: boolean,
  defaultValue?: TSchedule,
  action?: string
}

const CalendarForm: FC<TProps> = ({ action, defaultValue, setOpen, open, acLoad, activeDay }) => {
  const dispatch = useDispatch();

  const [form] = Form.useForm<TSchedule>();

  const handleSchedule = (data: TScheduleAdd) => {

    const { day_in_week, begin_time, end_time, ...schedule } = data;
    const submitData = {
      day_in_week: dayjs(day_in_week).unix(),
      begin_time: dayjs(begin_time).unix(),
      end_time: dayjs(end_time).unix(),
      ...schedule
    };
    if (action !== 'update') {
      dispatch(SCHEDULE.ADD(submitData));
    } else {
      dispatch(SCHEDULE.UPDATE(submitData));
    }
    setOpen(false);
  };

  useEffect(() => {
    if (action !== 'add' && defaultValue) {
      form.setFieldsValue({
        ...defaultValue,
        begin_time: dayjs.unix(Number(defaultValue.begin_time)),
        end_time: dayjs.unix(Number(defaultValue.end_time)),
        day_in_week: dayjs.unix(Number(defaultValue.day_in_week))
      });
    }
  }, [defaultValue]);

  return <>
    <Modal
      onCancel={() => setOpen(!open)}
      footer={<div className='flex justify-end items-end'>
        <button onClick={() => setOpen(!open)} className='rounded-xl px-4 py-1 border border-slate-500'>Hủy</button>
        <button
          disabled={acLoad}
          form='calendar'
          className='hover:bg-blue-500 rounded-xl px-4 py-1 bg-primary text-white border border-primary ms-3'>Lưu
        </button>
      </div>}
      title='Thêm nhiệm vụ mới'
      open={open}>
      <Form id='calendar' onFinish={handleSchedule}
            className='mt-5' labelCol={{ sm: 8 }}
            labelAlign='left' form={form}>
        <Form.Item hidden name='id'>
          <Input />
        </Form.Item>
        <Form.Item hidden name='is_repeat' initialValue={false}>
          <Input />
        </Form.Item>
        <Form.Item hidden name='is_publish' initialValue={false}>
          <Input />
        </Form.Item>
        <Form.Item hidden name='event_notification_timer' initialValue={1}>
          <Input />
        </Form.Item>

        <Form.Item
          rules={[{ required: true, message: 'Tiêu đề bắt buộc' }]}
          name='title' label='Tiêu đề'>
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Nội dung bắt buộc' }]}
          name='description' label='Nội dung'>
          <Input.TextArea rows={5} />
        </Form.Item>
        <Form.Item
          initialValue={'#52c51a'}
          name='event_color' label='Color'>
          <Select placeholder='Chọn màu sắc'>
            {colors.map((item: any) => {
              return <Select.Option key={item.code} value={item.code}>
                <Badge color={item.code} text={item.name} />
              </Select.Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Ngày bắt buộc' }]}
          name='day_in_week' label='Ngày'>
          <DatePicker defaultValue={activeDay} />
        </Form.Item>
        <Form.Item label='Thời gian'>
          <Space>
            <Form.Item
              rules={[{ required: true, message: 'Chọn giờ bắt đầu' }]}
              name='begin_time' noStyle>
              <TimePicker style={{ width: '100%' }} />
            </Form.Item>
            <span> To </span>
            <Form.Item
              rules={[{ required: true, message: 'Chọn giờ kết thúc' }]}
              name='end_time' noStyle>
              <TimePicker style={{ width: '100%' }} />
            </Form.Item>
          </Space>
        </Form.Item>
      </Form>

    </Modal>
  </>;
};
export default CalendarForm;
