import { axiosAuth, axiosCatch } from '../../../utils/AxiosHelper';

export const reqCareerPathInfo = async (params: any = {}) => {
  return await axiosAuth().get(`/api/career-path/info`, { params }).catch(axiosCatch);
};
export const reqCareerPathList = async (params: any = {}) => {
  return await axiosAuth().get(`/api/career-path/list`, { params }).catch(axiosCatch);
};

export const reqCareerPathListAssign = async (params: any = {}) => {
  return await axiosAuth().get(`/api/career-path/list-assign`, { params }).catch(axiosCatch);
};

export const reqCareerPathCreate = async (data: any) => {
  return await axiosAuth().post(`/api/career-path/create`, data).catch(axiosCatch);
};

export const reqCareerPathAssignStd = async (data: any) => {
  return await axiosAuth().post(`/api/career-path/assign-student`, data).catch(axiosCatch);
};
export const reqCareerPathAddCourse = async (data: any) => {
  return await axiosAuth().post(`/api/career-path/add-course`, data).catch(axiosCatch);
};
