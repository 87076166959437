import { ActionType, COMMON } from '../actions/actionTypes';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { COMMON_GET_DOCUMENT, COMMON_GET_MENU } from '../actions/types';
import { reqGetMenu } from '../services/common';
import { IMenuRoute } from '../contants/routeRole';
import { message } from 'antd';
import { publicRoutes } from '../../pages/v2/routes/routers';

function* getAuthMenu({ payload }: ActionType) {
  try {
    yield put(COMMON.LOADING(true));
    const response: IMenuRoute[] = yield call(reqGetMenu, payload.typeLogin);
    const newResponse = response.filter((itemResponse: any) => {
      return payload.userPermission.find((itemPayload: any) => itemPayload === itemResponse.key);
    });
    // const publicRoute = publicRoutes[payload.typeLogin] as any;
    // newResponse.push(...publicRoute);
    yield put(COMMON.SET_MENU(newResponse));
  } catch (e) {
    message.error(e.message);
  } finally {
    yield put(COMMON.LOADING(false));
  }
}

function* getDocument({ payload }: any) {
  let pageTitle = payload.title;
  if (!pageTitle && typeof document !== 'undefined') {
    pageTitle = document.title;
  }
  yield put(COMMON.SET_STATE({ pageTitle }));

}

function* takeGetDocument() {
  takeEvery(COMMON_GET_DOCUMENT, getDocument);
}

export default function* rootSaga() {
  yield all([
    takeLatest(COMMON_GET_MENU, getAuthMenu),
    fork(takeGetDocument)
  ]);
}
