import { TrashIcon } from "outline-icons";
import * as React from "react";
import { MenuItem } from "../../../shared/editor/types";
import { globalIntl } from "src/utils/hepler";

// export default function tableMenuItems(dictionary: Dictionary): MenuItem[] {
  export default function tableMenuItems(): MenuItem[] {

  return [
    {
      name: "deleteTable",
      tooltip: globalIntl("editor.deleteTable"),
      icon: <TrashIcon />,
      active: () => false,
    },
  ];
}
